import React from 'react';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import {
    toggleIsSidebarOpen,
    setSidebarView,
    selectSidebarIsOpen,
    SidebarViewEnum,
} from '../../../slices/sidebar-slice';
import { useAppSelector } from '../../../hooks';
import Classnames from 'classnames';

import './sidebar-toggle.scss';

interface SidebarToggleProps {
    onClick?: () => void;
    onKeyDown?: () => void;
}

export const SidebarToggle: React.FC<SidebarToggleProps> = ({ onClick, onKeyDown }): React.ReactElement => {
    const isSidebarOpen = useAppSelector(selectSidebarIsOpen),
        classes: string = Classnames(`sidebar-toggle`, { 'sidebar-toggle--opened': isSidebarOpen }),
        dispatch = useDispatch<AppDispatch>(),
        ariaControls = 'sidebar-navigation',
        ariaLabel = 'Sidebar navigation';

    const escapeCloseSidebar = (event: React.KeyboardEvent<HTMLElement>) => {
        if (event.key === 'Escape' && isSidebarOpen) {
            toggleSidebar();
        }
    };

    const toggleSidebar = () => {
        dispatch(toggleIsSidebarOpen());

        if (isSidebarOpen) {
            dispatch(setSidebarView(SidebarViewEnum.DEFAULT));
        }
    };

    return (
        <button
            aria-expanded={isSidebarOpen}
            aria-controls={ariaControls}
            aria-label={ariaLabel}
            className={classes}
            onClick={onClick || (() => toggleSidebar())}
            onKeyDown={onKeyDown || ((event) => escapeCloseSidebar(event))}
            data-testid="sidebar-toggle"
            id="sidebar-toggle"
            type="button"
        >
            <i className="fa-regular fa-ellipsis"></i>
            <span className="sr-only">{ariaLabel}</span>
        </button>
    );
};
