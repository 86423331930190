import React from 'react';
import { useAppSelector } from '../../../../hooks';
import { OTPModalView, selectOTPModalView } from '../../../../slices/otp-slice';
import { EnterVerificationCode } from './enter-verification-code';
import { SelectVerificationMethod } from './select-verification-method';
import { GenericModalWithViews } from '../generic-modal-with-views';

interface OTPModalProps {
    isOpen: boolean;
    onClose: () => void;
    confirmOnClick?: () => void;
}

export const OTPModal: React.FC<OTPModalProps> = ({ isOpen, onClose, confirmOnClick }): React.ReactElement => {
    const view = useAppSelector(selectOTPModalView);

    return (
        <GenericModalWithViews
            ariaDescribedById={`${view}-modal-description`}
            ariaLabelledById={`${view}-modal-headline`}
            ariaLabel="One time password modal"
            isOpen={isOpen}
            onClose={() => onClose()}
            portalClassName="otp-modal"
            testId="otp-modal"
        >
            {
                {
                    [OTPModalView.SELECT_VERIFICATION_METHOD]: <SelectVerificationMethod onClose={onClose} />,
                    [OTPModalView.ENTER_VERIFICATION_CODE]: (
                        <EnterVerificationCode confirmOnClick={confirmOnClick} onClose={onClose} />
                    ),
                }[view]
            }
        </GenericModalWithViews>
    );
};
