import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { useAppSelector } from '../../../../hooks/use-app-selector';
import {
    getOTPOptions,
    OTPModalView,
    OTPOption,
    OTPVerificationOptionType,
    requestOTP,
    selectActiveDeliveryPreference,
    selectOTPDeliveryOptions,
    setActiveDeliveryPreference,
    setActiveOtpRequestId,
    setCurrentOTPFlowView,
} from '../../../../slices/otp-slice';
import { selectPartnerSessionOtpRequestId } from '../../../../slices/partner-config-slice';
import { AppDispatch } from '../../../../store';
import { DividerRow, DividerRowEdgeType } from '../../ui-widgets';
import { GenericModalLayout } from '../common/generic-modal-layout';
import { INITIAL_LOADING_STATE, LoadingStateActionNames, loadingStateReducer } from '../../../../reducers';
import { toast } from 'react-toastify';
import { OtpTrackingTypes, TrackingService } from '../../../../utils/services/tracking';
import { RadioButtonListItem, RadioButtonListLayout } from '../../radio-button';

import './select-verification-method.scss';

interface SelectVerificationMethodProps {
    onClose?: () => void;
}

export const SelectVerificationMethod: React.FC<SelectVerificationMethodProps> = ({ onClose }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const otpRequestId = useAppSelector(selectPartnerSessionOtpRequestId);
    const deliveryOptions = useAppSelector(selectOTPDeliveryOptions);
    const [activeDeliveryPreferenceId, setActiveDeliveryPreferenceId] = useState('');
    const [loadingState, loadingStateDispatch] = useReducer(loadingStateReducer, INITIAL_LOADING_STATE);
    const [buttonText, setButtonText] = useState('Send message');
    const [subheadlineText, setSubheadlineText] = useState('');
    const trackingService = TrackingService.getTrackingService();
    const activeDeliveryPreference = useAppSelector(selectActiveDeliveryPreference);

    useEffect(() => {
        loadingStateDispatch({ type: LoadingStateActionNames.LOADING_ENABLED });
        (async () => {
            try {
                const results = await dispatch(getOTPOptions({ otpRequestId })).unwrap();
                dispatch(setActiveDeliveryPreference(results.deliveryOptions[0]));
                setActiveDeliveryPreferenceId(results.deliveryOptions[0].deliveryPreferenceId);
                loadingStateDispatch({ type: LoadingStateActionNames.LOADING_DISABLED });
            } catch (e) {
                const errorMessage = 'An error has occurred loading your delivery options. Please try again';
                toast.error(errorMessage);
                loadingStateDispatch({
                    errorMessage,
                    type: LoadingStateActionNames.SET_ERROR,
                });
                onClose();
            }
        })();
    }, []);

    useEffect(() => {
        if (activeDeliveryPreferenceId) {
            deliveryOptions.find((option) => {
                if (option.deliveryPreferenceId === activeDeliveryPreferenceId) {
                    setButtonText(`Send ${option.type === OTPVerificationOptionType.PHONE ? 'text message' : 'email'}`);

                    if (option.type === OTPVerificationOptionType.PHONE) {
                        return setSubheadlineText(
                            'We will text you a code to verify your identity. Message & data rates may apply.',
                        );
                    }

                    setSubheadlineText('We will email you a code to verify your identity.');
                    return;
                }
            });
        }
    }, [activeDeliveryPreferenceId]);

    const setVerificationMethod = (deliveryPreferenceId: string) => {
        setActiveDeliveryPreferenceId(deliveryPreferenceId);
        dispatch(
            setActiveDeliveryPreference(
                deliveryOptions.find((option) => option.deliveryPreferenceId === deliveryPreferenceId),
            ),
        );
    };

    const renderRadioContent = ({ value }: OTPOption) => {
        return <p>{value}</p>;
    };

    const progressOTPModalView = async () => {
        loadingStateDispatch({ type: LoadingStateActionNames.LOADING_ENABLED });
        dispatch(setActiveOtpRequestId(otpRequestId));

        await trackingService.trackOtpClick(OtpTrackingTypes.OTP_SEND_REQUEST, {
            method: activeDeliveryPreference.type,
        });

        await dispatch(
            requestOTP({
                deliveryPreferenceId: activeDeliveryPreferenceId,
                otpRequestId,
            }),
        );
        loadingStateDispatch({ type: LoadingStateActionNames.LOADING_DISABLED });
        dispatch(setCurrentOTPFlowView(OTPModalView.ENTER_VERIFICATION_CODE));
    };

    const filteredByPhone = deliveryOptions?.filter((option) => {
        return option.type === OTPVerificationOptionType.PHONE;
    });

    const filteredByEmail = deliveryOptions?.filter((option) => {
        return option.type === OTPVerificationOptionType.EMAIL;
    });

    const renderListItem = (opt: OTPOption) => {
        return (
            <li key={opt.deliveryPreferenceId}>
                <RadioButtonListItem
                    isChecked={activeDeliveryPreferenceId === opt.deliveryPreferenceId}
                    onChange={(event) => setVerificationMethod(event.target.value)}
                    name="delivery-preference-selector"
                    value={opt.deliveryPreferenceId}
                    ichildren={renderRadioContent(opt)}
                    title={opt.value}
                    id={`testid-${opt.deliveryPreferenceId}`}
                />
            </li>
        );
    };

    return (
        <div className="select-verification-method">
            <GenericModalLayout
                ariaLabelledById="select-verification-method-headline"
                hasExitButton={true}
                headline="Please verify your identity to continue"
                subheadline={subheadlineText}
                onClose={onClose}
                primaryButtonText={buttonText}
                primaryOnClick={progressOTPModalView}
                primaryIsLoading={loadingState.isLoading}
                primaryTestId="otp-verification-method-selector-done"
            >
                {!loadingState.isError && (
                    <>
                        {!loadingState.isLoading && deliveryOptions && (
                            <>
                                {filteredByPhone.length > 0 && (
                                    <RadioButtonListLayout caption="Text">
                                        {filteredByPhone.map((opt) => renderListItem(opt))}
                                    </RadioButtonListLayout>
                                )}

                                {filteredByEmail.length > 0 && (
                                    <RadioButtonListLayout caption="Email">
                                        {filteredByEmail.map((opt) => renderListItem(opt))}
                                    </RadioButtonListLayout>
                                )}
                            </>
                        )}

                        {loadingState.isLoading && (
                            <ul className="select-verification-method--loading-list">
                                {[...Array(5)].map((_, index) => (
                                    <li key={index}>
                                        <Skeleton
                                            borderRadius={`var(--border-radius-primary)`}
                                            count={1}
                                            width="100%"
                                        />
                                        <DividerRow shouldExtendEdges edgeType={DividerRowEdgeType.SPACE_PRIMARY} />
                                    </li>
                                ))}
                            </ul>
                        )}
                    </>
                )}
            </GenericModalLayout>
        </div>
    );
};
