import React, { useEffect, useReducer } from 'react';
import { GenericModal } from '../generic-modal';
import { useAppSelector } from '../../../../hooks';
import {
    selectCheckoutButtonConfirmCta,
    selectCurrentBankAccount,
    selectPartnerName,
} from '../../../../slices/partner-config-slice';
import { Calculation } from '../../../checkout-panel/checkout/calculation';
import { PrizeoutOffer, PrizeoutOfferValueOptions } from '../../../../slices/offers-slice';
import { INITIAL_LOADING_STATE, LoadingStateActionNames, loadingStateReducer } from '../../../../reducers';
import { StatusIconType, StatusIconWithHeading } from '../../ui-widgets/status-icon-with-heading';

interface RegEModalProps {
    activeOffer: PrizeoutOffer;
    activeGiftCardOption: PrizeoutOfferValueOptions;
    cancelOnClick: () => void;
    confirmOnClick: () => void;
    isRegEModalOpen: boolean;
}

export const RegEModal: React.FC<RegEModalProps> = ({
    activeOffer,
    activeGiftCardOption,
    cancelOnClick,
    confirmOnClick,
    isRegEModalOpen,
}): React.ReactElement => {
    const partnerName = useAppSelector(selectPartnerName);
    const currentBankAccount = useAppSelector(selectCurrentBankAccount);
    const checkoutButtonConfirmCta = useAppSelector(selectCheckoutButtonConfirmCta);
    const [loadingState, loadingStateDispatch] = useReducer(loadingStateReducer, INITIAL_LOADING_STATE);

    useEffect(() => {
        if (isRegEModalOpen) {
            loadingStateDispatch({ type: LoadingStateActionNames.LOADING_DISABLED });
        }
    }, [isRegEModalOpen]);

    const confirm = async () => {
        try {
            loadingStateDispatch({ type: LoadingStateActionNames.LOADING_ENABLED });
            await confirmOnClick();
        } catch (e) {
            const errorMessage = 'An error has occurred loading confirming reg e compliance. Please try again';
            loadingStateDispatch({
                errorMessage,
                type: LoadingStateActionNames.SET_ERROR,
            });
        }
    };

    return (
        <>
            <GenericModal
                ariaLabelledById="reg-e-confirmation-modal"
                ariaLabel="Reg-e confirmation modal"
                hasExitButton={true}
                headline={`From ${currentBankAccount?.name} ***${currentBankAccount?.last4}`}
                isOpen={isRegEModalOpen}
                onClose={cancelOnClick}
                portalClassName="reg-e-modal"
                primaryButtonText={checkoutButtonConfirmCta}
                primaryIsLoading={loadingState.isLoading}
                primaryOnClick={() => confirm()}
                primaryTestId="reg-e-confirmation-modal-confirm"
                secondaryButtonText="Cancel"
                secondaryOnClick={() => cancelOnClick()}
                secondaryTestId="reg-e-confirmation-modal-cancel"
                testId="reg-e-modal"
            >
                {loadingState.isError && (
                    <StatusIconWithHeading status={StatusIconType.ERROR} text={loadingState.errorMessage} />
                )}
                <Calculation
                    activeGiftCardOption={activeGiftCardOption}
                    offerType={activeOffer.display_offer_type}
                    calculationCurrency={activeOffer.currency_code}
                    className="mb-m"
                />
                <p>
                    By clicking &quot;Confirm&quot;, I authorize {partnerName} to debit the listed account for a
                    one-time electronic funds transfer (EFT) payment for the amount shown above, and correct any errors
                    or process any refunds, if necessary.
                </p>
            </GenericModal>
        </>
    );
};
