import React, { useState } from 'react';
import Classnames from 'classnames';
import { ClearImageOverlay } from './clear-image-overlay';
import { constants } from '../../../utils/constants';
import { RoundImageSizes } from '../../../slices/common-slice';
import { setImageErrorFallback } from '../../../utils';

import './round-image.scss';

interface RoundImageProps {
    className?: string;
    hasBorder?: boolean;
    hasBoxShadow?: boolean;
    imageAltText?: string;
    imageUrl?: string;
    size: RoundImageSizes;
    customSize?: string;
    testId?: string;
}

export const RoundImage: React.FC<RoundImageProps> = ({
    className,
    hasBorder,
    hasBoxShadow,
    imageAltText,
    imageUrl,
    size,
    testId,
}): React.ReactElement => {
    const [loaded, setLoaded] = useState(false);

    const classes: string = Classnames(
        'round-image',
        { [`round-image--size-${size}`]: size },
        { 'round-image--border': hasBorder },
        { 'round-image--shadow': hasBoxShadow },
        { 'round-image--loaded': loaded },
        className,
    );

    const onLoad = () => {
        setLoaded(true);
    };

    return (
        <div data-testid={'round-image-wrapper'} className={classes}>
            <img
                data-testid={testId || 'round-image'}
                src={imageUrl || constants.defaultRoundImageUrl}
                alt={imageAltText || ''}
                onError={(event) => setImageErrorFallback(event, constants.defaultRoundImageUrl)}
                onLoad={onLoad}
            />
            <ClearImageOverlay />
        </div>
    );
};
