import React from 'react';
import { selectNavBarOptions } from '../../../slices/partner-config-slice';
import { useAppSelector, useStringSequence } from '../../../hooks';
import { TruncatedCashbackBalancePill } from './truncated-cashback-balance-pill';
import { MainNavBarButton } from '../buttons/main-nav-bar-button';

import './nav-bar.scss';

export const NavBar: React.FC = (): React.ReactElement => {
    const navBarOptions = useAppSelector(selectNavBarOptions);
    const navBarButtonKeys = useStringSequence('nav-bar-button');

    const displayNavBar = () => {
        return (
            <>
                {navBarOptions.map((item) => {
                    const child: React.ReactNode = item.label === 'Cashback' ? <TruncatedCashbackBalancePill /> : null;

                    return (
                        <MainNavBarButton
                            child={child}
                            icon={item.icon}
                            id={item?.metadata?.category_id}
                            key={navBarButtonKeys()}
                            label={item.label}
                            route={item.route}
                        />
                    );
                })}
            </>
        );
    };

    return (
        <ul data-testid="nav-bar" className={'nav-bar'}>
            {displayNavBar()}
        </ul>
    );
};
