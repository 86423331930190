import React from 'react';
import { TypographicSizes } from '../../../slices/common-slice';
import { ToolTip, ToolTipDirectionTypes, ToolTipTriggerTypes } from './tool-tip';
import { ParagraphSecondary } from '../typography';

import './secondary-text-with-tool-tip.scss';

export interface SecondaryTextWithToolTipProps {
    paragraphSize?: TypographicSizes;
    paragraphText: string;
    toolTipDirection: ToolTipDirectionTypes;
    toolTipText: string;
    toolTipTrigger: ToolTipTriggerTypes;
}

export const SecondaryTextWithToolTip: React.FC<SecondaryTextWithToolTipProps> = ({
    paragraphSize = TypographicSizes.ONE,
    paragraphText,
    toolTipDirection,
    toolTipText,
    toolTipTrigger,
}): React.ReactElement => {
    return (
        <div data-testid="secondary-text-with-tool-tip" className="secondary-text-with-tool-tip">
            <ParagraphSecondary size={paragraphSize} text={paragraphText} />
            <ToolTip content={toolTipText} tipTrigger={toolTipTrigger} tipDirection={toolTipDirection} />
        </div>
    );
};
