import React, { useEffect, useState } from 'react';

/*
 Make using IntersectionObserver a little easier.

 https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API

 Usage:


    const MyComponent: React.FC = (): React.ReactElement => {
        const handleObserverChange: IntersectionObserverCallback = (entries: IntersectionObserverEntry[]): void => {
            if (!entries) {
                return;
            }

            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    // Element just came into view
                } else {
                    // Element just left view
                }
            });
        };

        const topRef = useRef(null);
    `   const summaryRef = useRef(null);
        const [summaryElement, setSummaryElement] = useState<Element>(null);
`       const [observer, setTopElement] = useIntersectionObserver(topRef, handleObserverChange, <options>);

        // This needs to run on every render
        useEffect(() => {
            setTopElement(topRef.current);
            setSummaryElement(summaryRef.current); // This part may vary.
        });

        // This is an example of how to handle this.  Details of how this works will vary.
        useEffect(() => {
            if (observer && summaryElement) {
                observer.observe(summaryElement);
            }
        }, [observer, summaryElement]);

        return <topElement ref={topRef} />;
    };
*/
export function useIntersectionObserver(
    topRef: React.MutableRefObject<Element>,
    callback: IntersectionObserverCallback,
    options = {},
): [IntersectionObserver, (e: any) => void] {
    const [observer, setObserver] = useState<IntersectionObserver>(null);
    const [topElement, setTopElement] = useState<Element>(topRef.current);

    useEffect(() => {
        if (!topElement) {
            if (observer) {
                observer.disconnect();
            }

            setObserver(null);
            return;
        }

        const localObserver = new IntersectionObserver(callback, {
            ...options,
            root: topRef.current.parentElement,
        });

        setObserver(localObserver);

        return () => {
            if (observer) {
                observer.disconnect();
            }

            setObserver(null);
        };
    }, [topElement]);

    return [observer, setTopElement];
}

export default useIntersectionObserver;
