import { createSelector } from '@reduxjs/toolkit';
import { cashbackApiSlice } from './cashback-api-slice';

const selectCashbackWalletSummaryResult = cashbackApiSlice.endpoints.getCashbackWalletSummary.select();
const selectWalletSummaryResult = cashbackApiSlice.endpoints.getWalletSummary.select();

const selectCashbackWalletSummary = createSelector(selectCashbackWalletSummaryResult, ({ data }) => data);

const selectWalletSummary = createSelector(selectWalletSummaryResult, ({ data }) => data);

export const selectCashbackBalance = createSelector(selectCashbackWalletSummary, (data) => data?.balance);

export const selectCashbackCurrencyCode = createSelector(selectCashbackWalletSummary, (data) => data?.currencyCode);

export const selectLifetimeCashbackEarnings = createSelector(
    selectCashbackWalletSummary,
    (data) => data?.lifetimeCashbackEarnings,
);

export const selectNumActiveGiftCards = createSelector(selectWalletSummary, (data) => data?.numActiveCards);
