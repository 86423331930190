import React, { useState } from 'react';
import Classnames from 'classnames';
import { useHotkeys } from 'react-hotkeys-hook';
import * as DOMPurify from 'dompurify';
import { selectIsMobilePortrait } from '../../../slices/common-slice';
import { useAppSelector } from '../../../hooks';

import './tool-tip.scss';

export enum ToolTipTriggerTypes {
    CLICK = 'click',
    HOVER = 'hover',
}

export enum ToolTipDirectionTypes {
    UP = 'up',
    DOWN = 'down',
    TOP_RIGHT = 'top-right',
    TOP_LEFT = 'top-left',
}

interface ToolTipProps {
    content: string;
    id?: string;
    title?: string;
    tipTrigger?: ToolTipTriggerTypes;
    tipDirection?: ToolTipDirectionTypes;
}

export const ToolTip: React.FC<ToolTipProps> = ({
    content,
    id,
    title = `<i data-testid="tool-tip-icon-trigger" class="fak fa-prizeout-info"></i>`,
    tipTrigger = ToolTipTriggerTypes.HOVER,
    tipDirection = ToolTipDirectionTypes.UP,
}): React.ReactElement => {
    const isMobilePortraitView = useAppSelector(selectIsMobilePortrait);
    const [isShowing, setIsShowing] = useState(false),
        showToolTip = (): void => setIsShowing(true),
        hideToolTip = (): void => setIsShowing(false),
        toggleTooltip = (): void => {
            setIsShowing(!isShowing);
        },
        classes: string = Classnames('tool-tip', {
            'tool-tip--is-showing': isShowing,
            [`tool-tip--${tipDirection}`]: tipDirection,
        }),
        elmId = id || `tt-id`,
        contentId = `${elmId}-content`,
        sanitizedTitle = DOMPurify.sanitize(title);

    useHotkeys('esc', () => {
        if (document.activeElement === document.getElementById(elmId)) {
            hideToolTip();
        }
    });

    useHotkeys('enter', () => {
        if (document.activeElement === document.getElementById(elmId)) {
            showToolTip();
        }
    });

    const cancelClick = (e: React.MouseEvent<HTMLDivElement>): void => {
        e.stopPropagation();
        e.preventDefault();
        if (isMobilePortraitView || tipTrigger === ToolTipTriggerTypes.CLICK) {
            toggleTooltip();
        }
    };

    return (
        <div
            aria-hidden={!isShowing ? 'true' : 'false'}
            className={classes}
            data-testid="tool-tip"
            id={elmId}
            tabIndex={isShowing ? -1 : 0}
            onBlur={() => hideToolTip()}
            role="tooltip"
            onClickCapture={cancelClick}
        >
            {!isMobilePortraitView && tipTrigger === ToolTipTriggerTypes.HOVER && (
                <span
                    aria-describedby={contentId}
                    className="tool-tip__title"
                    onMouseEnter={showToolTip}
                    onMouseLeave={hideToolTip}
                    dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
                />
            )}
            {(isMobilePortraitView || tipTrigger === ToolTipTriggerTypes.CLICK) && (
                <span
                    aria-describedby={contentId}
                    className="tool-tip__title"
                    dangerouslySetInnerHTML={{ __html: sanitizedTitle }}
                />
            )}
            <p id={contentId} className="tool-tip__content">
                {content}
            </p>
        </div>
    );
};
