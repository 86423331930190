export enum InfiniteScrollStateActionNames {
    SET_IS_EMPTY = 'set-is-empty',
    SET_IS_ERROR = 'set-is-error',
    SET_LAST_ELEMENT = 'set-last-element',
    SET_LOADING = 'set-loading',
    SET_NEEDS_PAGE = 'set-needs-page',
    UPDATE_DATALIST = 'update-dataList',
}

export interface InfiniteScrollState<GenericObject> {
    dataList: GenericObject[];
    isLoading: boolean;
    atEnd: boolean;
    lastElement: Element;
    needsPage: boolean;
    isEmpty: boolean;
    isError: boolean;
    isFirstPage: boolean;
}

export interface InfiniteScrollStateAction<GenericObject> {
    type: InfiniteScrollStateActionNames;
    newEntries?: GenericObject[];
    lastElement?: Element;
}

export const INITIAL_INFINITE_SCROLL_STATE: InfiniteScrollState<any> = {
    dataList: [],
    isLoading: false,
    atEnd: false,
    lastElement: null,
    needsPage: true,
    isEmpty: false,
    isError: false,
    isFirstPage: true,
};

export function infiniteScrollStateReducer<GenericObject>(
    state: InfiniteScrollState<GenericObject>,
    action: InfiniteScrollStateAction<GenericObject>,
) {
    switch (action.type) {
        case InfiniteScrollStateActionNames.SET_IS_EMPTY:
            return {
                ...INITIAL_INFINITE_SCROLL_STATE,
                isEmpty: true,
                atEnd: true,
            };

        case InfiniteScrollStateActionNames.SET_IS_ERROR:
            return {
                ...state,
                isError: true,
                atEnd: true,
                isLoading: false,
            };

        case InfiniteScrollStateActionNames.SET_LAST_ELEMENT:
            if (state.lastElement && state.lastElement.isSameNode(action.lastElement)) {
                return state;
            }

            return {
                ...state,
                lastElement: action.lastElement,
            };

        case InfiniteScrollStateActionNames.SET_LOADING:
            return {
                ...state,
                isLoading: true,
            };

        case InfiniteScrollStateActionNames.SET_NEEDS_PAGE:
            return {
                ...state,
                needsPage: true,
            };

        case InfiniteScrollStateActionNames.UPDATE_DATALIST:
            if (action.newEntries && action.newEntries.length > 0) {
                return {
                    ...state,
                    dataList: [...state.dataList, ...action.newEntries],
                    isLoading: false,
                    needsPage: false,
                    isFirstPage: false,
                };
            } else {
                return {
                    ...state,
                    dataList: [...state.dataList, ...action.newEntries],
                    atEnd: true,
                    isLoading: false,
                    needsPage: false,
                    isFirstPage: false,
                    lastElement: null as Element,
                };
            }

        default:
            console.error(`Unknown infinite scroll state reducer action: ${action}`);
            return state;
    }
}
