import { routes } from '../../utils';

export enum CRMTagGroups {
    CRM = 'crm',
}

export enum CRMContext {
    HOMEPAGE = 'homepage',
    MINI_WIDGET = 'mini_widget',
    CASHBACK = 'cashback',
    WALLET = 'wallet',
    EXPLORE = 'explore',
    OFFERS = 'offers',
    UNIVERSAL = 'universal',
    REWARDS = 'rewards',
}

export type CRMRecord = {
    [K in CRMContext]: CRMContextData;
};

export type CRMListType = {
    [key: string]: boolean;
};

export enum CRMZone {
    TOP = 'top',
    MIDDLE = 'middle',
    BOTTOM = 'bottom',
    FULL = 'full',
    MODAL = 'modal',
}

export type CRMContextData = {
    [K in CRMZone]: ZoneData;
};

export type NoDynamicValues = {
    null: null;
    additional_fetch?: FetchDataPoints[];
};

export enum CRMContentType {
    BANNER = 'banner',
    MINI_WIDGET = 'mini_widget',
    MODAL = 'modal',
}

export interface ZoneData {
    content_type: CRMContentType;
    data: ZoneTemplateData; //this will likely become an array in the future
}

export enum CRMTemplateLayout {
    /*
        Banner content type templates
        Current - templates V1 ...deprecate post-deploy
    */

    TOTAL_MEMBERS_CASHBACK = 'total_members_cashback',
    WELCOME_INSTRUCTIONS_BANNER = 'welcome_instructions_banner',

    /*
        Banner content type templates
        New data structures - templates V2
    */

    CLASSIC_TEXT_ONLY_ALERT = 'classic_text_only_alert', // same as WELCOME_INSTRUCTIONS_BANNER
    CLASSIC_TEXT_RIGHT_IMAGE = 'classic_text_right_image', // same as TOTAL_MEMBERS_CASHBACK

    /*
        Mini widget content type templates
        Current - templates V1 ...deprecate post-deploy
    */

    // Classic layout wrapper
    BILLBOARD = 'billboard',
    OFFERS = 'offers',

    // Cashback layout wrapper
    GIFT_CARD_BONUS_BALANCES_AND_OFFERS_V1 = 'gift_card_bonus_balances_and_offers_v1',
    GIFT_CARD_BONUS_BALANCES_TEXT_LIST_V1 = 'gift_card_bonus_balances_text_list_v1',
    GIFT_CARD_CASHBACK_BALANCES_AND_OFFERS_V1 = 'gift_card_cashback_balances_and_offers_v1',
    GIFT_CARD_CASHBACK_BALANCES_TEXT_LIST_V1 = 'gift_card_cashback_balances_text_list_v1',
    TESTIMONIAL_TEXT_LIST_V1 = 'testimonial_text_list_v1',

    /*
        Mini widget content type templates
        New data structures - templates V2
    */

    // Classic layout wrapper
    CLASSIC_BILLBOARD = 'classic_billboard', // Same as BILLBOARD
    CLASSIC_OFFERS = 'classic_offers', // Same as OFFERS

    // Cashback layout wrapper
    BONUS_BANNER_BONUS_BALANCE_GIFT_CARDS_OFFER = 'bonus_banner_bonus_balance_gift_cards_offer',
    BONUS_BANNER_BONUS_BALANCE_GIFT_CARDS_BULLETS = 'bonus_banner_bonus_balance_gift_cards_bullets',
    CASHBACK_BANNER_CASHBACK_BALANCE_GIFT_CARDS_OFFER = 'cashback_banner_cashback_balance_gift_cards_offer',
    CASHBACK_BANNER_CASHBACK_BALANCE_GIFT_CARDS_BULLETS = 'cashback_banner_cashback_balance_gift_cards_bullets',
    CLASSIC_TESTIMONIAL = 'classic_testimonial',

    // Lumin layout wrapper
    LUMIN_BILLBOARD = 'lumin_billboard',
    LUMIN_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'lumin_stacked_cashback_balance_background_image_and_text',
    LUMIN_STACKED_CASHBACK_BALANCE_BILLBOARD = 'lumin_stacked_cashback_balance_billboard',
    LUMIN_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'lumin_stacked_cashback_balance_earning_insight',
    LUMIN_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'lumin_stacked_cashback_balance_earning_tracker',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'lumin_stacked_cashback_balance_info_card_left_image_large_header_text',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'lumin_stacked_cashback_balance_info_card_left_image_small_header_text',
    LUMIN_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'lumin_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    LUMIN_STACKED_CASHBACK_BALANCE_OFFER = 'lumin_stacked_cashback_balance_offer',
    LUMIN_STACKED_CASHBACK_BALANCE_REWARD = 'lumin_stacked_cashback_balance_reward',
    LUMIN_STACKED_FALLBACK = 'lumin_stacked_fallback',

    // Q2 Classic
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'q2_classic_stacked_cashback_balance_earning_insight',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'q2_classic_stacked_cashback_balance_background_image_and_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'q2_classic_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'q2_classic_stacked_cashback_balance_info_card_left_image_small_header_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'q2_classic_stacked_cashback_balance_info_card_left_image_large_header_text',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_BILLBOARD = 'q2_classic_stacked_cashback_balance_billboard',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_REWARD = 'q2_classic_stacked_cashback_balance_reward',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_OFFER = 'q2_classic_stacked_cashback_balance_offer',
    Q2_CLASSIC_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'q2_classic_stacked_cashback_balance_earning_tracker',
    Q2_CLASSIC_STACKED_REWARD_FALLBACK = 'q2_classic_stacked_reward_fallback',
    Q2_CLASSIC_STACKED_OFFER_FALLBACK = 'q2_classic_stacked_offer_fallback',
    Q2_CLASSIC_BILLBOARD = 'q2_classic_billboard',

    // Q2 Composable
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_BACKGROUND_IMAGE_AND_TEXT = 'q2_composable_stacked_cashback_balance_background_image_and_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_LARGE_HEADER_TEXT = 'q2_composable_stacked_cashback_balance_info_card_left_image_large_header_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_RIGHT_IMAGE_WRAPPED_LARGE_NUMBER = 'q2_composable_stacked_cashback_balance_info_card_right_image_wrapped_large_number',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_INFO_CARD_LEFT_IMAGE_SMALL_HEADER_TEXT = 'q2_composable_stacked_cashback_balance_info_card_left_image_small_header_text',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_EARNING_INSIGHT = 'q2_composable_stacked_cashback_balance_earning_insight',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_OFFER = 'q2_composable_stacked_cashback_balance_offer',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_REWARD = 'q2_composable_stacked_cashback_balance_reward',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_BILLBOARD = 'q2_composable_stacked_cashback_balance_billboard',
    Q2_COMPOSABLE_STACKED_CASHBACK_BALANCE_EARNING_TRACKER = 'q2_composable_stacked_cashback_balance_earning_tracker',
    Q2_COMPOSABLE_STACKED_OFFERS_FALLBACK = 'q2_composable_stacked_offers_fallback',
    Q2_COMPOSABLE_STACKED_REWARDS_FALLBACK = 'q2_composable_stacked_rewards_fallback',
    Q2_COMPOSABLE_STACKED_FALLBACK = 'q2_composable_stacked_fallback',
    Q2_COMPOSABLE_BILLBOARD = 'q2_composable_billboard',

    // Modals
    CLASSIC_MODAL_ICON_BULLETS = 'classic_modal_icon_bullets',
    TESTIMONIAL_MODAL_ICON_BULLETS = 'testimonial_modal_icon_bullets',
    CLASSIC_MODAL_CHECKLIST_IMAGE = 'classic_modal_checklist_image',
    CLASSIC_MODAL_PARAGRAPH_TEXT = 'classic_modal_paragraph_text',
    LEGAL_MODAL_PARAGRAPH_TEXT_DATA_SHARE_V1 = 'legal_modal_paragraph_text_data_share_v1',
    LEGAL_MODAL_CHECKLIST_IMAGE = 'legal_modal_checklist_image',
    LEGAL_MODAL_PARAGRAPH_TEXT = 'legal_modal_paragraph_text',
}

export enum LegalTermType {
    DATA_SHARE = 'data_share',
    REWARDS = 'rewards',
}

interface BaseCRMTemplateData<T> {
    marketing_campaign_id: string;
    marketing_campaign_group: string;
    content_id: string;
    layout: CRMTemplateLayout;
    content: T;
}

interface BaseCRMData {
    headline?: string;
    image?: CRMImage;
    cta_list?: CRMCTA[];
    additional_fetch?: FetchDataPoints[];
}

export interface WelcomeInstructionBanner extends ClassicCRMMarketingUnit {
    text: CRMTextBlock;
}

export interface GiftCardBonusBalanceTextListV1 extends ClassicCRMMarketingUnit {
    cta: CRMCTA;
}

export interface GiftCardCashBackBalancesTextListV1 extends ClassicCRMMarketingUnit {
    cta: CRMCTA;
}

export interface CRMStackedCashbackBalanceMarketingUnit extends BaseCRMData {
    title?: string;
    header_size?: HeaderSize;
    image_url?: string;
    background_image?: CRMBackgroundImage;
    text?: CRMTextBlock;
}

export interface ClassicCRMMarketingUnit extends BaseCRMData {
    image_url?: string;
    testimonial?: CRMTestimonial;
    cta?: CRMCTA;
    text_list?: CRMTextBlock;
}

export type BalanceGiftCardsBullets = BaseCRMData;

export interface StackedBillboard {
    image_url: string;
    cta_list: CRMCTA[];
    additional_fetch?: FetchDataPoints[];
}

export interface StackedFallback {
    headline: string;
    header_size: HeaderSize;
    text: CRMTextBlock;
    image?: CRMImage;
    cta_list: CRMCTA[];
    additional_fetch?: FetchDataPoints[];
}

export interface ClassicTextOnlyAlert {
    headline: string;
    text: CRMTextBlock;
    cta?: CRMCTA;
    additional_fetch?: FetchDataPoints[];
}

export interface ClassicTextRightImage {
    headline: string;
    text: CRMTextBlock;
    image: CRMImage;
    cta: CRMCTA;
    additional_fetch?: FetchDataPoints[];
}

export interface TestimonialTextListV1 {
    headline: string;
    text_list?: CRMTextBlock;
    testimonial: CRMTestimonial;
    cta: CRMCTA;
    additional_fetch?: FetchDataPoints[];
}

export interface CRMTestimonial {
    quote?: string;
    nameAndTitle?: string;
    image?: string;
    additional_fetch?: FetchDataPoints[];
}

export interface TotalMembersCashback {
    headline?: string;
    text: CRMTextBlock;
    image: CRMImage;
    cta?: CRMCTA;
    additional_fetch?: FetchDataPoints[];
}

export interface ClassicTestimonialWidget {
    headline?: string;
    testimonial: CRMTestimonial;
    cta_list: CRMCTA[];
    additional_fetch?: FetchDataPoints[];
}

export interface CRMImage {
    image_url: string;
    image_location: CRMImagePositions;
}

export interface Billboard {
    image_url: string;
    cta?: CRMCTA;
    additional_fetch?: FetchDataPoints[];
}
export interface CRMModalTextList {
    style: CRMTextStyles;
    text: CRMModalText[] | string[];
    icons?: string[];
}

export interface CRMModalText {
    title?: string;
    description?: string;
}

export interface BaseCRMModalData extends BaseCRMData {
    legal_term?: CRMModalLegalTerm;
    sentence?: string;
    subheadline?: string;
    testimonial?: CRMTestimonial;
    text_list?: CRMModalTextList;
}

export interface CRMModalLegalTerm {
    legal_term_type: LegalTermType;
    partner_term_url: string;
}

export enum CRMImagePositions {
    LEFT = 'left',
    RIGHT = 'right',
    TOP = 'top',
}

export enum CRMTextStyles {
    SENTENCE = 'sentence',
    ORDERED_BULLETS = 'ordered_bullets',
    WRAPPED_BIG_NUMBER = 'wrapped_big_number',
    PARAGRAPH_TEXT_LIST = 'paragraph_text_list',
    TITLE_CHECKLIST = 'title_checklist',
    ICON_BULLETS = 'icon_bullets',
}

export interface CRMTextBlock {
    style: CRMTextStyles;
    text: string | string[];
}

export interface CRMCTA {
    cta_label?: string;
    cta_url?: routes;
    cta_type?: CTAType;
    cta_submit_value?: boolean;
    prizeout_user_opt_in_id?: string;
}

export enum CTAType {
    PRIMARY = 'primary',
    SECONDARY = 'secondary',
    CLICKABLE_AREA = 'clickable_area',
}

export enum FetchDataPoints {
    EARNING_INSIGHTS = 'earning_insights',
    EARNING_TRACKER = 'earning_tracker',
    OFFERS = 'offers',
    REWARDS = 'rewards',
    CASHBACK = 'cashback',
    WALLET = 'wallet',
}

export enum HeaderSize {
    SMALL = 'small',
    LARGE = 'large',
}

export interface CRMBackgroundImage {
    image_url: string;
}

// TODO Deprecate
export type WelcomeInstructionBannerTemplate = BaseCRMTemplateData<WelcomeInstructionBanner>;
export type GiftCardCashBackBalancesTextListV1Template = BaseCRMTemplateData<GiftCardCashBackBalancesTextListV1>;
export type TotalMembersCashbackTemplate = BaseCRMTemplateData<TotalMembersCashback>;
export type TestimonialTextListV1Template = BaseCRMTemplateData<TestimonialTextListV1>;
export type GiftCardCashbackBalancesAndOffersV1Template = BaseCRMTemplateData<NoDynamicValues>;
export type BillboardTemplate = BaseCRMTemplateData<Billboard>;
export type OffersTemplate = BaseCRMTemplateData<NoDynamicValues>;
export type GiftCardBonusBalanceTextListV1Template = BaseCRMTemplateData<GiftCardBonusBalanceTextListV1>;
export type GiftCardBonusBalancesAndOffersV1Template = BaseCRMTemplateData<NoDynamicValues>;

// New templates
// BalanceGiftCardsBullets
export type CashbackBannerCashbackBalanceGiftCardsOrderedBulletsTemplate = BaseCRMTemplateData<BalanceGiftCardsBullets>;
export type BonusBannerBonusBalanceGiftCardsOrderedBulletsTemplate = BaseCRMTemplateData<BalanceGiftCardsBullets>;

// BalanceGiftcardsOffer
export type BonusBannerBonusBalanceGiftCardsOfferTemplate = BaseCRMTemplateData<ClassicCRMMarketingUnit>;
export type CashbackBannerCashbackBalanceGiftCardsOfferTemplate = BaseCRMTemplateData<ClassicCRMMarketingUnit>;

// Lumin Stacked Balance Marketing
export type LuminStackedCashbackBalanceBackgroundImageAndTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceInfoCardLeftImageSmallHeaderTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceInfoCardRightImageWrappedLargeNumberTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;

// Lumin Stacked Balance Product Card
export type LuminStackedCashbackBalanceEarningInsightTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceEarningTrackerTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceOfferTemplate = BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type LuminStackedCashbackBalanceRewardTemplate = BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;

// Lumin Billboards and Fallback
export type LuminStackedCashbackBalanceBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;
export type LuminBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;
export type LuminStackedCashbackBillboard = BaseCRMTemplateData<StackedBillboard>;
export type LuminStackedFallbackTemplate = BaseCRMTemplateData<StackedFallback>;

// Classic
export type ClassicOfferTemplate = BaseCRMTemplateData<ClassicCRMMarketingUnit>;
export type ClassicBillboardTemplate = BaseCRMTemplateData<ClassicCRMMarketingUnit>;
export type ClassicTestimonialWidgetTemplate = BaseCRMTemplateData<ClassicCRMMarketingUnit>;

// Banners
export type ClassicTextOnlyAlertTemplate = BaseCRMTemplateData<ClassicTextOnlyAlert>;
export type ClassicTextRightImageTemplate = BaseCRMTemplateData<ClassicTextRightImage>;

// Q2 Classic Templates
export type Q2ClassicStackedCashbackBalanceEarningInsightTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ClassicStackedCashbackBalanceBackgroundImageAndTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2StackedCashbackBalanceInfoCardRightImageTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ClassicStackedCashbackBalanceRewardTemplate = BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ClassicStackedCashbackBalanceOfferTemplate = BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ClassicStackedCashbackBalanceEarningTrackerTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ClassicStackedFallbackTemplate = BaseCRMTemplateData<StackedFallback>;
export type Q2ComposableStackedCashbackBalanceInfoCardLeftImageSmallHeaderTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2StackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;

// Q2 Composable Templates
export type Q2ComposableStackedCashbackBalanceBackgroundImageAndTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceInfoCardRightImageWrappedLargeNumberTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceEarningInsightTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceOfferTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceRewardTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedCashbackBalanceEarningTrackerTemplate =
    BaseCRMTemplateData<CRMStackedCashbackBalanceMarketingUnit>;
export type Q2ComposableStackedFallbackTemplate = BaseCRMTemplateData<StackedFallback>;
export type Q2ComposableBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;
export type Q2ComposableStackedCashbackBalanceBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;

// Q2 Billboards and Fallback
export type Q2StackedFallbackTemplate = BaseCRMTemplateData<StackedFallback>;
export type Q2ClassicStackedRewardFallbackTemplate = BaseCRMTemplateData<StackedFallback>;
export type Q2ClassicStackedOfferFallbackTemplate = BaseCRMTemplateData<StackedFallback>;
export type Q2ClassicBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;
export type Q2ClassicStackedCashbackBalanceBillboardTemplate = BaseCRMTemplateData<StackedBillboard>;

export type BaseCRMTemplate = BaseCRMTemplateData<BaseCRMModalData>;

export type ZoneTemplateData =
    | WelcomeInstructionBannerTemplate
    | GiftCardCashBackBalancesTextListV1Template
    | TotalMembersCashbackTemplate
    | TestimonialTextListV1Template
    | BillboardTemplate
    | OffersTemplate
    | GiftCardBonusBalanceTextListV1Template
    | GiftCardBonusBalancesAndOffersV1Template
    | CashbackBannerCashbackBalanceGiftCardsOrderedBulletsTemplate
    | BonusBannerBonusBalanceGiftCardsOrderedBulletsTemplate
    | BonusBannerBonusBalanceGiftCardsOfferTemplate
    | CashbackBannerCashbackBalanceGiftCardsOfferTemplate
    | LuminStackedCashbackBalanceBillboardTemplate
    | LuminBillboardTemplate
    | LuminStackedCashbackBillboard
    | LuminStackedCashbackBalanceBackgroundImageAndTextTemplate
    | LuminStackedCashbackBalanceEarningInsightTemplate
    | LuminStackedCashbackBalanceEarningTrackerTemplate
    | LuminStackedCashbackBalanceInfoCardLeftImageSmallHeaderTextTemplate
    | LuminStackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate
    | LuminStackedCashbackBalanceInfoCardRightImageWrappedLargeNumberTemplate
    | LuminStackedCashbackBalanceOfferTemplate
    | LuminStackedCashbackBalanceRewardTemplate
    | LuminStackedFallbackTemplate
    | ClassicOfferTemplate
    | ClassicTextOnlyAlertTemplate
    | ClassicTextRightImageTemplate
    | ClassicBillboardTemplate
    | ClassicTestimonialWidgetTemplate
    | Q2StackedFallbackTemplate
    | Q2ClassicStackedRewardFallbackTemplate
    | Q2ClassicStackedOfferFallbackTemplate
    | Q2ClassicBillboardTemplate
    | Q2ClassicStackedCashbackBalanceBillboardTemplate
    | Q2ClassicStackedCashbackBalanceBackgroundImageAndTextTemplate
    | Q2ClassicStackedCashbackBalanceEarningInsightTemplate
    | Q2ClassicStackedCashbackBalanceEarningTrackerTemplate
    | Q2StackedCashbackBalanceInfoCardRightImageTemplate
    | Q2ClassicStackedCashbackBalanceOfferTemplate
    | Q2ClassicStackedCashbackBalanceRewardTemplate
    | Q2ClassicStackedFallbackTemplate
    | Q2StackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate
    | Q2ComposableStackedCashbackBalanceBackgroundImageAndTextTemplate
    | Q2ComposableStackedCashbackBalanceEarningInsightTemplate
    | Q2ComposableStackedCashbackBalanceEarningTrackerTemplate
    | Q2ComposableStackedCashbackBalanceInfoCardLeftImageSmallHeaderTextTemplate
    | Q2ComposableStackedCashbackBalanceInfoCardLeftImageLargeHeaderTextTemplate
    | Q2ComposableStackedCashbackBalanceInfoCardRightImageWrappedLargeNumberTemplate
    | Q2ComposableStackedCashbackBalanceOfferTemplate
    | Q2ComposableStackedCashbackBalanceRewardTemplate
    | Q2ComposableStackedFallbackTemplate
    | Q2ComposableBillboardTemplate
    | BaseCRMTemplate;
