import React, { useEffect, useRef, useState } from 'react';
import { InfiniteScrollItemProps } from './infinite-scroll-item-wrapper';
import { useNumberSequence, useStringSequence, useUpdateEffect } from '../../../hooks';
import useIntersectionObserver from '../../../hooks/useIntersectionObserver';
import ClassNames from 'classnames';

import Skeleton from 'react-loading-skeleton';
import { InfiniteScrollStateActionNames } from './infinite-scroll-reducer';
import { toast } from 'react-toastify';
import { PaginatedData } from '../../../utils';
import { InfiniteScrollStateManager, useInfiniteScrollState } from './infinite-scroll-manager';
import { Button, ButtonColor, ButtonSize } from '../buttons';

import './infinite-scroll.scss';

interface InfiniteScrollProps<GenericObject = string[]> {
    getNextPage: () => Promise<PaginatedData<GenericObject[]>>;
    ListItemComponent: React.ComponentType<InfiniteScrollItemProps<GenericObject>>;
    atEnd?: () => void;
    CustomLoaderComponent?: React.ComponentType;
    dense?: boolean;
    EmptyListComponent?: React.ComponentType;
    ErrorComponent?: React.ComponentType;
    filterList?: (sourceList: GenericObject[]) => GenericObject[];
    onEmptyFilteredList?: () => boolean;
    hasNextPage?: () => boolean;
    idBase?: string;
    localStateManager?: InfiniteScrollStateManager<GenericObject>;
    onError?: (err: Error) => void;
    onFirstPage?: (err?: Error) => boolean;
    pageLength?: number;
    shouldShowLogo?: boolean;
}

export enum InfiniteScrollState {
    EMPTY = 'empty',
    ERROR = 'error',
    LOADING = 'loading',
    RESOLVED = 'resolved',
}

export function InfiniteScroll<GenericObject>({
    getNextPage,
    ListItemComponent,
    atEnd,
    CustomLoaderComponent,
    dense = false,
    EmptyListComponent,
    ErrorComponent,
    filterList = (sourceList) => sourceList,
    onEmptyFilteredList,
    hasNextPage = () => true,
    idBase = 'infiniteScroll',
    localStateManager,
    onError,
    onFirstPage,
    pageLength = 50,
    shouldShowLogo,
}: InfiniteScrollProps<GenericObject>): React.ReactElement {
    const idSeq = useStringSequence(idBase);
    const [localState, dispatchLocalState] = localStateManager || useInfiniteScrollState();
    const [refetchFirstPage, setRefetchFirstPage] = useState(false);
    const [showEmptyFilterResults, setShowEmptyFilterResults] = useState(false);
    const loadingKeys = useNumberSequence();

    const handleObserverChange: IntersectionObserverCallback = (
        entries: IntersectionObserverEntry[],
        observer,
    ): void => {
        if (!entries) {
            return;
        }

        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                observer.unobserve(entry.target);

                if (hasNextPage()) {
                    dispatchLocalState({ type: InfiniteScrollStateActionNames.SET_NEEDS_PAGE });
                }
            }
        });
    };

    const topRef = useRef(null);
    const [observer, setTopElement] = useIntersectionObserver(topRef, handleObserverChange, {
        threshold: [0.05, 1.0],
    });

    useUpdateEffect(() => {
        setTopElement(topRef.current);
    });

    useEffect(() => {
        if (refetchFirstPage) {
            if (hasNextPage()) {
                dispatchLocalState({ type: InfiniteScrollStateActionNames.SET_NEEDS_PAGE });
            }
            setRefetchFirstPage(false);
        }
    }, [refetchFirstPage]);

    useEffect(() => {
        if (localState.atEnd) {
            atEnd?.call(null);
        }
    }, [localState.atEnd]);

    useEffect(() => {
        if (localState.isError) {
            onError?.call(null);
        }
    }, [localState.isError]);

    useEffect(() => {
        if (localState.dataList.length > 0 && filterList(localState.dataList).length === 0) {
            setShowEmptyFilterResults(true);
            return;
        }

        setShowEmptyFilterResults(false);

        if (localState.isEmpty || localState.isError) {
            return;
        }

        const lastItem = topRef.current?.querySelector('.infinite-scroll-item:last-child');

        if (lastItem) {
            dispatchLocalState({
                type: InfiniteScrollStateActionNames.SET_LAST_ELEMENT,
                lastElement: lastItem,
            });
        }
    }, [localState.dataList]);

    useEffect(() => {
        if (!filterList) {
            return;
        }

        setShowEmptyFilterResults(localState.dataList.length > 0 && filterList(localState.dataList).length === 0);
    }, [filterList]);

    useEffect(() => {
        if (localState.needsPage) {
            getMoreData();
            return;
        }

        if (localState.dataList.length === 0) {
            dispatchLocalState({ type: InfiniteScrollStateActionNames.SET_IS_EMPTY });
        }
    }, [localState.needsPage]);

    useEffect(() => {
        if (observer && localState.lastElement) {
            observer.observe(localState.lastElement);
        }
    }, [observer, localState.lastElement]);

    const getMoreData = async () => {
        if (localState.atEnd || localState.isLoading) {
            return;
        }

        dispatchLocalState({ type: InfiniteScrollStateActionNames.SET_LOADING });

        try {
            const pageResult = await getNextPage();
            const newList = pageResult?.data;

            await dispatchLocalState({
                type: InfiniteScrollStateActionNames.UPDATE_DATALIST,
                newEntries: newList || [],
            });

            if (localState.isFirstPage) {
                onFirstPage?.call(null, null);
            }
        } catch (e) {
            let shouldToast = true;
            console.error('Get next page error', e);

            if (localState.isFirstPage && onFirstPage) {
                shouldToast = onFirstPage(e);
            }

            if (shouldToast) {
                toast.error('There was an error loading your items');
            }

            await dispatchLocalState({
                type: InfiniteScrollStateActionNames.SET_IS_ERROR,
            });
        }
    };

    const renderData = () => {
        if (localState.dataList.length > 0 && filterList(localState.dataList).length === 0) {
            if (!showEmptyFilterResults) {
                setShowEmptyFilterResults(true);
            }

            return null;
        }

        return filterList(localState.dataList).map((dataItem: GenericObject) => {
            const id = idSeq();
            return <ListItemComponent key={id} id={id} data={dataItem} shouldShowLogo={shouldShowLogo} dense={dense} />;
        });
    };

    const renderLoading = () => {
        if (!localState.isLoading) {
            return null;
        }

        if (localState.dataList.length === 0) {
            return (
                <ul className="infinite-scroll__loading">
                    {new Array(pageLength).fill('').map(() => {
                        return (
                            <li key={loadingKeys()}>
                                {CustomLoaderComponent ? (
                                    <CustomLoaderComponent />
                                ) : (
                                    <Skeleton containerTestId="infinite-scroll-skeleton" />
                                )}
                            </li>
                        );
                    })}
                </ul>
            );
        }

        if (CustomLoaderComponent) {
            return <CustomLoaderComponent />;
        }

        return <Skeleton containerTestId="infinite-scroll-skeleton" />;
    };

    const renderErrorComponent = () => {
        if (!localState.isError) {
            return null;
        }

        if (ErrorComponent) {
            return <ErrorComponent />;
        }
    };

    if (localState.isEmpty) {
        if (EmptyListComponent) {
            return <EmptyListComponent />;
        } else {
            return null;
        }
    }

    const handleClearFilterClick = () => {
        setShowEmptyFilterResults(false);

        if (onEmptyFilteredList?.call(null)) {
            setRefetchFirstPage(true);
            return;
        }

        dispatchLocalState({ type: InfiniteScrollStateActionNames.SET_IS_EMPTY });
    };

    if (showEmptyFilterResults) {
        if (!refetchFirstPage) {
            setRefetchFirstPage(true);
        }

        return (
            <div className="infinite-scroll">
                <div className="infinite-scroll__empty-filtered-list" data-testid="empty-filtered-list">
                    <h2>No results out of {localState.dataList.length} match your filter</h2>
                    <Button
                        text="Show unfiltered list"
                        size={ButtonSize.MEDIUM}
                        color={ButtonColor.CONFIRM}
                        onClick={handleClearFilterClick}
                        testId="empty-filtered-list-clear-filter"
                    />
                </div>
            </div>
        );
    }

    const listClassnames = ClassNames('infinite-scroll__list', {
        'infinite-scroll__list--dense': dense,
    });

    return (
        <div className="infinite-scroll">
            <ul className={listClassnames} ref={topRef}>
                {renderData()}
            </ul>
            {renderLoading()}
            {renderErrorComponent()}
        </div>
    );
}
