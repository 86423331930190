import { Dispatch, useReducer } from 'react';
import { InfiniteScrollState, InfiniteScrollStateAction, infiniteScrollStateReducer } from './infinite-scroll-reducer';

export type InfiniteScrollStateManager<GenericObject> = [
    InfiniteScrollState<GenericObject>,
    Dispatch<InfiniteScrollStateAction<GenericObject>>,
];

export function useInfiniteScrollState<GenericObject>(): InfiniteScrollStateManager<GenericObject> {
    const [localState, dispatchLocalState] = useReducer(infiniteScrollStateReducer, {
        dataList: [],
        isLoading: false,
        atEnd: false,
        lastElement: null,
        needsPage: true,
        isEmpty: false,
        isError: false,
        isFirstPage: true,
    } as InfiniteScrollState<GenericObject>);

    return [localState, dispatchLocalState];
}
