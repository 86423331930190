export const getObjectCSS = () => {
    return `
        <style>
            body {
                --po-blue: #2264FA;
                --po-blue-900: #3874FB;
                --po-blue-700: #6492FB;
                --po-blue-400: #A7C1FD;
                --po-blue-200: #D3E0FE;
                --po-blue-100: #E9EFFF;
                --po-black: #021431;
                --po-black-900: #1B2C46;
                --po-black-700: #4E5A6F;
                --po-black-600: #8F959F;
                --po-black-400: #B3B9C1;
                --po-black-200: #DFE2E5;
                --po-black-100: #F5F5F7;
                --po-red: #D41D0D;
                --po-red-700: #DF564A;
                --po-red-100: #FBE8E7;
                --po-green: #2BC86E;
                --po-green-700: #26B668;
                --po-green-400: #C0F1D1;
                --po-green-100: #EAFAF0;
                --po-yellow: #F3AE00;
                --po-yellow-400: #F0C700;
                --po-yellow-200: #FAEA9E;
                --po-yellow-100: #FEF7E5;
                --po-electric-blue: #00EAE1;
                --po-bright-purple: #DE6FFF;
                --po-white: #FFFFFF;
                --error-color: var(--po-red-700);
                --success-color: var(--po-green);
                --primary-text-color: var(--po-black);
                --secondary-text-color: var(--po-black-700);
                --font-family-primary: 'Manrope', Arial, sans-serif;
                --font-family-fa: 'Font Awesome 6 Pro';
                --font-family-fa-kit: 'Font Awesome Kit';
                --font-family-code: Menlo, Courier, monospace;
                --font-size-xxxs: .5rem;
                --font-size-xxs: .625rem;
                --font-size-xs: .75rem;
                --font-size-s: .875rem;
                --font-size-m: 1rem;
                --font-size-l: 1.125rem;
                --font-size-xl: 1.5rem;
                --font-size-xxl: 1.875rem;
                --font-size-xxxl: 2.625rem;
                --font-size-primary: var(--font-size-m);
                --font-size-secondary: 1.25rem;
                --line-height-body: 1.5;
                --line-height-heading: 1.2;
                --unit: .25rem;
                --space: var(--unit);
                --space-xs: #{'calc(var(--space) * 2)'};
                --space-s: #{'calc(var(--space) * 3)'};
                --space-m: #{'calc(var(--space) * 4)'};
                --space-l: #{'calc(var(--space) * 6)'};
                --space-xl: #{'calc(var(--space) * 8)'};
                --space-xxl: #{'calc(var(--space) * 12)'};
                --space-xxxl: #{'calc(var(--space) * 15)'};
                --space-primary: var(--space-m);
                --space-secondary: var(--space-xl);
                --space-tertiary: #{'calc(var(--space) * 5)'};
                @media #{$mobile-portrait-down} {
                    --space-secondary: var(--space-m);
                }
                --border-primary: #{'1px solid rgba(0, 0, 0, 0.1)'};
                --border-primary-color: var(--po-black-200);
                --primary-color: var(--po-blue);
                --primary-color-900: var(--po-blue-900);
                --primary-color-700: var(--po-blue-700);
                --primary-color-400: var(--po-blue-400);
                --primary-color-200: var(--po-blue-200);
                --primary-color-100: var(--po-blue-100);
                --secondary-color: var(--po-black);
                --secondary-color-900: var(--po-black-900);
                --secondary-color-700: var(--po-black-700);
                --secondary-color-400: var(--po-black-400);
                --secondary-color-200: var(--po-black-200);
                --secondary-color-100: var(--po-black-100);
                --brand-primary-accent-color: var(--primary-color);
                --brand-primary-contrast-color: var(--po-white);
                --brand-secondary-accent-color: var(--primary-color);
                --brand-secondary-contrast-color: var(--primary-color-100);
                --brand-secondary-alternate-contrast-color: var(--primary-color-200);
                --brand-banner-accent-color: var(--secondary-color);
                --brand-banner-accent-hover-color: var(--secondary-color-700);
                --brand-banner-contrast-color: var(--po-white);
                --brand-banner-contrast-hover-color: var(--secondary-color-400);
                --tile-header-mini-widget-background-color: var(--brand-primary-accent-color);
                --tile-wrapper-border-color: var(--brand-primary-accent-color);
                --tile-how-it-works-icon-number-color: var(--brand-primary-accent-color);
                --tile-weekly-deal-icon-color: var(--brand-primary-accent-color);
                --tile-testimonial-intro-background-color: var(--brand-primary-accent-color);
                --button-primary-background-color: var(--brand-primary-accent-color);
                --checkout-modal-icon-color: var(--brand-primary-accent-color);
                --my-cards-button-counter-background-color: var(--brand-primary-accent-color);
                --tile-header-mini-widget-text-color: var(--brand-primary-contrast-color);
                --tile-how-it-works-icon-color: var(--brand-primary-contrast-color);
                --tile-testimonial-intro-text-color: var(--brand-primary-contrast-color);
                --stat-summary-layout-divider: var(--brand-primary-contrast-color);
                --stat-summary-text-color: var(--brand-primary-contrast-color);
                --truncated-cashback-balance-pill-color: var(--brand-primary-contrast-color);
                --cashback-onboarding-intro-text-color: var(--brand-primary-contrast-color);
                --cashback-onboarding-close-button-color: var(--brand-primary-contrast-color);
                --cashback-onboarding-close-button-background-color-hover: var(--brand-primary-contrast-color);
                --button-primary-text-color: var(--brand-primary-contrast-color);
                --my-cards-button-counter-text-color: var(--brand-primary-contrast-color);
                --cashback-callout-value-text-color: var(--brand-secondary-accent-color);
                --cashback-amount-summary-heading: var(--brand-secondary-accent-color);
                --cashback-amount-you-earned: var(--brand-secondary-accent-color);
                --cashback-toggle-icon-color: var(--brand-secondary-accent-color);
                --pill-selector-active-border-color: var(--brand-secondary-accent-color);
                --pill-selector-active-icon-color: var(--brand-secondary-accent-color);
                --offers-offer-bonus-tag-color: var(--brand-secondary-accent-color);
                --bonus-tag-icon-color: var(--brand-secondary-accent-color);
                --loader-icon-color: var(--brand-secondary-accent-color);
                --truncated-cashback-balance-pill-background-color: var(--brand-secondary-accent-color);
                --svg-rewards-inner-circle-background-color: var(--brand-secondary-accent-color);
                --pill-selector-hover-background-color: var(--brand-secondary-contrast-color);
                --pill-selector-active-background-color: var(--brand-secondary-contrast-color);
                --bonus-tag-solid-bg-color: var(--brand-secondary-contrast-color);
                --svg-rewards-outer-circle-background-color: var(--brand-secondary-alternate-contrast-color);
                --stat-summary-layout-background-color: var(--brand-banner-accent-color);
                --cashback-onboarding-intro-background-color: var(--brand-banner-accent-color);
                --wallet-total-balance-bg-color: var(--brand-banner-accent-color);
                --faq-banner-background-color: var(--brand-banner-accent-color);
                --faq-banner-icon-color: var(--brand-banner-accent-color);
                --stat-summary-background-color-hover: var(--brand-banner-accent-hover-color);
                --wallet-total-balance-text-color: var(--brand-banner-contrast-color);
                --faq-banner-text-color: var(--brand-banner-contrast-color);
                --focus-outline-color: var(--primary-color-400);
                --crm-banner-dark-background-color: var(--po-black);
                --crm-banner-dark-background-contrast-text-color: var(--po-white);
                --crm-banner-light-background-color: var(--po-white);
                --crm-banner-light-background-contrast-text-color: var(--primary-text-color);
                --header-background-color: var(--po-white);
                --header-bottom-border: var(--border-primary);
                --header-height: 3.5rem;
                --header-cb-branding-color: var(--primary-text-color);
                --page-title-height: var(--header-height);
                --page-title-background-color: var(--po-white);
                --nav-bar-wrapper-background-color: var(--po-white);
                --nav-bar-background-color: var(--nav-bar-wrapper-background-color);
                --nav-bar-button-background-color: var(--nav-bar-wrapper-background-color);
                --nav-bar-button-background-color-hover: var(--po-black-200);
                --nav-bar-button-color: var(--primary-text-color);
                --nav-bar-button-color-hover: var(--primary-text-color);
                --border-radius-primary: #{'calc(var(--unit) * 3)'};
                --border-radius-secondary: #{'calc(var(--unit) * 5)'};
                --border-radius-tertiary: #{'calc(var(--unit) * 2)'};
                --border-radius-small: var(--unit);
                --border-radius-card: var(--border-radius-tertiary);
                --border-radius-banner: #{'calc(var(--unit) * 4)'};
                --border-radius-full: 100px;
                --panel-width: 25rem;
                --round-image-width: 3.25rem;
                --button-primary-hover-background-color: var(--primary-color-900);
                --button-primary-hover-text-color: var(--button-primary-text-color);
                --button-primary-active-background-color: var(--primary-color-700);
                --button-primary-active-text-color: var(--button-primary-text-color);
                --button-secondary-background-color: var(--po-white);
                --button-secondary-text-color: var(--primary-color);
                --button-secondary-border-color: transparent;
                --button-secondary-hover-background-color: var(--primary-color-100);
                --button-secondary-hover-border-color: transparent;
                --button-secondary-hover-text-color: var(--button-secondary-text-color);
                --button-secondary-active-background-color: var(--primary-color-200);
                --button-secondary-active-border-color: transparent;
                --button-secondary-active-text-color: var(--button-secondary-text-color);
                --button-tertiary-background-color: var(--po-black-100);
                --button-tertiary-text-color: var(--po-black);
                --button-tertiary-border-color: transparent;
                --button-tertiary-hover-background-color: var(--po-black-200);
                --button-tertiary-hover-border-color: transparent;
                --button-tertiary-hover-text-color: var(--po-black);
                --button-tertiary-active-background-color: var(--po-black-400);
                --button-tertiary-active-border-color: transparent;
                --button-tertiary-active-text-color: var(--po-black);
                --button-confirm-background-color: var(--button-primary-background-color);
                --button-confirm-text-color: var(--button-primary-text-color);
                --button-confirm-hover-background-color: var(--button-primary-hover-background-color);
                --button-confirm-hover-text-color: var(--button-primary-hover-text-color);
                --button-confirm-active-background-color: var(--button-primary-active-background-color);
                --button-confirm-active-text-color: var(--button-primary-active-text-color);
                --button-border-radius: var(--border-radius-primary);
                --button-touch-target-size: 2.75rem;
                --pill-selector-background-color: var(--po-black-100);
                --pill-selector-border-color: var(--pill-selector-background-color);
                --pill-selector-text-color: var(--primary-text-color);
                --pill-selector-icon-color: var(--primary-text-color);
                --pill-selector-active-text-color: var(--primary-text-color);
                --pill-selector-on-dashboard-background-color: var(--pill-selector-active-background-color);
                --pill-selector-on-dashboard-border-color: var(--pill-selector-active-border-color);
                --pill-selector-on-dashboard-text-color: var(--pill-selector-active-text-color);
                --pill-selector-on-dashboard-icon-color: var(--pill-selector-active-icon-color);
                --offer-sort-toggle-background-color: var(--pill-selector-background-color);
                --offer-sort-toggle-icon-color: var(--pill-selector-text-color);
                --offer-sort-toggle-hover-background-color: var(--po-black-200);
                --button-category-carousel-text-color: var(--secondary-text-color);
                --button-category-carousel-selected-text-color: var(--primary-color);
                --button-category-carousel-border-color: transparent;
                --button-category-carousel-hover-text-color: var(--primary-text-color);
                --button-category-carousel-hover-border-color: var(--po-black-200);
                --button-category-carousel-selected-border-color: var(--primary-color);
                --spend-selection-button-background-color: var(--pill-selector-background-color);
                --spend-selection-button-text-color: var(--pill-selector-text-color);
                --spend-selection-button-border-color: transparent;
                --spend-selection-hover-button-background-color: var(--pill-selector-hover-background-color);
                --spend-selection-active-button-background-color: var(--pill-selector-active-background-color);
                --spend-selection-active-button-text-color: var(--pill-selector-active-text-color);
                --spend-selection-active-button-border-color: var(--pill-selector-active-border-color);
                --spend-selection-button-height: 2rem;
                --spend-selection-button-height-large: 4.5rem;
                --custom-denom-button-background-color: var(--spend-selection-button-background-color);
                --custom-denom-button-text-color: var(--spend-selection-button-text-color);
                --custom-denom-button-hover-background-color: var(--spend-selection-hover-button-background-color);
                --custom-denom-button-active-background-color: var(--spend-selection-active-button-background-color);
                --custom-denom-button-border-color: var(--spend-selection-button-border-color);
                --custom-denom-button-active-border-color: var(--spend-selection-active-button-border-color);
                --custom-denom-button-active-button-text-color: var(--spend-selection-active-button-text-color);
                --custom-denom-button-height: var(--spend-selection-button-height-large);
                --wallet-sort-pill-background-color: var(--pill-selector-background-color);
                --wallet-sort-pill-border-color: var(--pill-selector-border-color);
                --wallet-sort-pill-text-color: var(--pill-selector-text-color);
                --wallet-sort-pill-icon-color: var(--pill-selector-icon-color);
                --wallet-sort-pill-hover-background-color: var(--pill-selector-hover-background-color);
                --wallet-sort-pill-active-background-color: var(--pill-selector-active-background-color);
                --wallet-sort-pill-active-border-color: var(--pill-selector-active-border-color);
                --wallet-sort-pill-active-text-color: var(--pill-selector-active-text-color);
                --wallet-sort-pill-active-icon-color: var(--pill-selector-active-icon-color);
                --mini-widget-iframe-width: 358px;
                --mini-widget-iframe-height: 285px;
                --mini-header-background-color: var(--po-white);
                --mini-header-text-color: var(--primary-text-color);
                --mini-widget-spacing: var(--space-xs);
                --mini-widget-iframe-padding: 0;
                --mini-widget-outer-padding: 8px;
                --mini-widget-border-radius: 0;
                --mini-widget-max-width: 25rem;
                --tile-header-mini-widget-border-radius: 0;
                --tile-header-mini-widget-padding: var(--space-xs) var(--space-s);
                --tile-wrapper-background-color: var(--po-white);
                --tile-wrapper-padding: var(--space-s);
                --tile-wrapper-border-radius: 0;
                --tile-border-radius: var(--border-radius-small);
                --tile-background-color: var(--po-black-100);
                --tile-hover-background-color: var(--po-black-200);
                --tile-border-color: transparent;
                --tile-contrast-background-color: var(--po-white);
                --tile-contrast-hover-background-color: var(--po-black-100);
                --tile-contrast-border-color: var(--po-black-200);
                --tile-arrow-text-icon-color: var(--primary-text-color);
                --tile-arrow-text-text-color: var(--primary-text-color);
                --tile-testimonial-intro-cta-text-color: var(--primary-color);
                --tile-testimonial-intro-border-radius: var(--tile-border-radius);
                --tile-media-asset-icon-color: var(--primary-text-color);
                --tile-media-asset-icon-font-size: var(--font-size-m);
                --onboarding-view-panel-width: 28rem; // 448px
                --onboarding-view-max-width: var(--onboarding-view-panel-width);
                --onboarding-view-slider-buttons-width: 2.625rem; // 42px
                --onboarding-view-swiper-pagination-color: var(--primary-text-color);
                --cashback-how-it-works-background-color: var(--po-black-100);
                --cashback-how-it-works-text-color: var(--primary-text-color);
                --cashback-list-item-tag-background-color: var(--primary-color);
                --cashback-list-item-tag-text-color: var(--po-white);
                --cashback-list-item-tag-font-size: var(--font-size-s);
                --cashback-history-list-item-name-text-color: var(--primary-text-color);
                --cashback-history-list-item-date-text-color: var(--po-black-700);
                --cashback-history-list-item-purchase-text-color: var(--primary-text-color);
                --cashback-history-list-item-debit-text-color: var(--po-red);
                --cashback-account-selector-icon-color: var(--primary-text-color);
                --cashback-account-selector-icon-hover-color: var(--primary-color);
                --cashback-alert-callout-icon-color: var(--primary-color);
                --cashback-alert-callout-background-color: var(--primary-color-100);
                --exit-button: var(--primary-text-color);
                --exit-button-hover: var(--primary-color);
                --stat-summary-font-size: 2rem;
                --stat-summary-background-color: var(--stat-summary-layout-background-color);
                --close-button-background-color: var(--po-white);
                --close-button-color: var(--po-black);
                --close-button-background-color-hover: var(--po-black-200);
                --close-button-color-hover: var(--po-black);
                --cashback-onboarding-close-button-background-color: transparent;
                --cashback-onboarding-close-button-color-hover: var(--cashback-onboarding-intro-background-color);
                --cashback-onboarding-border-color: var(--po-black-200);
                --cashback-onboarding-icon-color: var(--po-black-200);
                --cashback-onboarding-banner-background-color: var(--po-black-100);
                --action-card-background-color: var(--po-white);
                --action-card-text-color: var(--primary-text-color);
                --action-card-hover-background-color: var(--po-black-100);
                --action-card-hover-text-color: var(--action-card-text-color);
                --cashback-callout-background-color: var(--action-card-background-color);
                --cashback-callout-text-color: var(--action-card-text-color);
                --cashback-callout-hover-background-color: var(--action-card-hover-background-color);
                --cashback-callout-hover-text-color: var(--action-card-hover-text-color);
                --bank-account-selector-label: var(--primary-text-color);
                --bank-account-selector-value: var(--primary-text-color);
                --sidebar-toggle-color: var(--primary-text-color);
                --sidebar-toggle-color-hover: var(--po-black-700);
                --sidebar-toggle-color-opened: var(--po-black-700);
                --product-card-width: 22.375rem;
                --product-card-height: 9.75rem;
                --entry-card-gradient-start: var(--po-black);
                --entry-card-gradient-middle: var(--po-black);
                --entry-card-gradient-end: var(--po-blue);
                --entry-card-icon-color: var(--po-electric-blue);
                --entry-card-text-color: var(--po-white);
                --entry-card-cta-text-color: var(--button-secondary-text-color);
                --entry-card-cta-bg-color: var(--po-white);
                --entry-card-cta-icon-color: var(--button-secondary-text-color);
                --entry-card-cta-border-color: var(--button-secondary-border-color);
                --create-account-cta-bg-color: var(--po-black);
                --create-account-cta-heading-color: var(--po-white);
                --create-account-cta-text-color: var(--po-white);
                --points-cta-header-text-color: var(--primary-text-color);
                --points-cta-balance-text-color: var(--primary-text-color);
                --points-cta-content-text-color: var(--primary-text-color);
                --points-cta-pending-points-text-color: var(--primary-color-700);
                --points-content-divider-border-color: var(--po-black-200);
                --progress-bar-progress: var(--po-yellow-400);
                --progress-bar-pending: var(--po-yellow-200);
                --progress-bar-progress-incomplete: var(--primary-color);
                --progress-bar-pending-incomplete: var(--primary-color-200);
                --sidebar-nav-icon-color: var(--primary-color);
                --sidebar-nav-text-color: var(--primary-text-color);
                --sidebar-nav-text-color-hover: var(--primary-color);
                --sidebar-detail-header-text-color: var(--primary-text-color);
                --sidebar-detail-header-icon-color: var(--primary-text-color);
                --sidebar-sub-header-text-color: var(--primary-text-color);
                --sidebar-text-label-color: var(--primary-text-color);
                --sidebar-link-text-color: var(--primary-color);
                --sidebar-link-text-color-hover: var(--primary-color);
                --sidebar-divider-row-color: var(--po-black-200);
                --offers-filter-cat-name-text-color: var(--primary-text-color);
                --offers-button-cta-bg-color: var(--button-confirm-background-color);
                --offers-button-cta-text-color: var(--button-confirm-text-color);
                --offers-see-all-text-color: var(--button-secondary-text-color);
                --offers-see-all-text-color-hover: var(--button-secondary-text-color);
                --offers-row-header-text-color: var(--primary-text-color);
                --offers-row-subheader-text-color: var(--secondary-text-color);
                --offers-title-tag-bg-color: var(--po-black);
                --offers-title-tag-text-color: var(--po-white);
                --offers-offer-name-text-color: var(--primary-text-color);
                --offers-big-brand-desc-text-color: var(--secondary-text-color);
                --offers-vert-list-item-btm-border-color: var(--po-black-200);
                --offers-help-icon-color: var(--primary-text-color);
                --offers-help-icon-bg-color: var(--po-black-200);
                --offers-help-icon-bg-color-hover: var(--po-black-400);
                --external-link-color: var(--po-blue-700);
                --external-link-hover-color: var(--po-blue);
                --footer-link-color: var(--external-link-color);
                --footer-link-color-hover: var(--external-link-hover-color);
                --footer-copyright-text: var(--secondary-text-color);
                --wallet-header-text-color: var(--primary-text-color);
                --wallet-lifetime-bonus-gradient-start: #06142f;
                --wallet-lifetime-bonus-gradient-middle: var(--primary-color);
                --wallet-lifetime-bonus-gradient-end: var(--primary-color);
                --wallet-lifetime-bonus-text-color: var(--po-white);
                --wallet-lifetime-bonus-icon-color: var(--po-white);
                --wallet-brand-list-item-bg-color: var(--po-black-100);
                --wallet-brand-list-item-hover-bg-color: var(--primary-color-100);
                --wallet-brand-list-item-text-color: var(--primary-text-color);
                --wallet-brand-list-item-icon-color: var(--primary-text-color);
                --wallet-gc-item-bg-color: var(--wallet-brand-list-item-bg-color);
                --wallet-gc-item-bg-color-hover: var(--wallet-brand-list-item-hover-bg-color);
                --wallet-gc-item-bg-color-selected: var(--primary-color-200);
                --wallet-gc-item-text-color: var(--wallet-brand-list-item-text-color);
                --wallet-gc-item-text-color-pending: var(--wallet-brand-list-item-text-color);
                --wallet-gc-item-bg-color-pending: var(--po-white);
                --wallet-gc-item-border-color-pending: var(--po-black-200);
                --wallet-gc-item-icon-color-pending: var(--po-black-600);
                --wallet-gc-details-text-color: var(--wallet-brand-list-item-text-color);
                --wallet-gc-details-text-label-color: var(--wallet-brand-list-item-text-color);
                --wallet-gc-shop-now-bg-color: var(--button-primary-background-color);
                --wallet-gc-shop-now-text-color: var(--button-primary-text-color);
                --wallet-gc-shop-now-hover-bg-color: var(--button-primary-hover-background-color);
                --wallet-gc-shop-now-hover-text-color: var(--button-primary-hover-text-color);
                --wallet-gc-shop-now-active-bg-color: var(--button-primary-active-background-color);
                --wallet-gc-shop-now-active-text-color: var(--button-primary-active-text-color);
                --wallet-gc-archive-bg-color: var(--button-secondary-background-color);
                --wallet-gc-archive-text-color: var(--button-secondary-text-color);
                --wallet-gc-archive-hover-bg-color: var(--button-secondary-hover-background-color);
                --wallet-gc-archive-hover-text-color: var(--button-secondary-hover-text-color);
                --wallet-gc-archive-active-bg-color: var(--button-secondary-active-background-color);
                --wallet-gc-archive-active-text-color: var(--button-secondary-active-text-color);
                --payment-secure-tag-icon-color: var(--po-green);
                --payment-secure-tag-text-color: var(--secondary-text-color);
                --bank-transfer-confirmation-icon-color: var(--po-green);
                --enter-pin-header-color: var(--primary-text-color);
                --enter-pin-input-bg-color: var(--primary-color);
                --enter-pin-input-shadow-color: rgba(0, 167, 255, 0.4);
                --enter-pin-btn-color: var(--po-white);
                --enter-pin-btn-bg-color: transparent;
                --enter-pin-btn-bg-color-hover: var(--primary-color-700);
                --enter-pin-input-color: var(--po-white);
                --enter-pin-input-border-focus: var(--primary-color);
                --enter-pin-tooltip-color: var(--primary-color);
                --enter-pin-label-color: var(--primary-color);
                --gc-details-container-border-color: var(--po-black-200);
                --gc-details-h1-color: var(--primary-text-color);
                --gc-details-h2-color: var(--primary-text-color);
                --gc-details-value-color: var(--primary-text-color);
                --gc-details-button-bg-color: var(--button-primary-background-color);
                --gc-details-button-color: var(--button-primary-text-color);
                --gc-details-copy-label-color: var(--primary-text-color);
                --gc-details-copy-value-color: var(--primary-text-color);
                --gc-details-copy-value-border-color: var(--po-black-200);
                --gc-details-redemption-inst-header-color: var(--primary-text-color);
                --gc-details-wrapper-border-color: var(--po-black-200);
                --gc-details-redemption-inst-icon-color: var(--primary-color);
                --gc-details-redemption-inst-color: var(--primary-text-color);
                --gc-details-terms-header-text-color: var(--primary-text-color);
                --gc-details-terms-text-color: var(--primary-text-color);
                --gc-details-terms-icon-color: var(--primary-text-color);
                --bonus-tag-label-color: var(--primary-text-color);
                --panel-close-btn-bg-color: var(--close-button-background-color);
                --panel-close-btn-border-color-hover: var(--close-button-background-color-hover);
                --panel-close-btn-bg-color-hover: var(--close-button-background-color-hover);
                --panel-close-btn-border-color-active: var(--close-button-background-color-hover);
                --panel-close-btn-bg-color-active: var(--close-button-background-color-hover);
                --search-widget-input-background-color: var(--po-black-100);
                --search-widget-text-color: var(--po-black-700);
                --search-widget-icon-color: var(--po-black-700);
                --search-widget-border-color: var(--primary-color);
                --sticky-buttons-height: 4.75rem;
                --svg-rewards-icon-color: var(--po-white);
                --svg-rewards-rectangle-background-color: var(--po-white);
                --svg-rewards-rectangle-plus-symbols-color: var(--po-black-200);
                --forward-arrow-button-icon-color: var(--po-black);
                --forward-arrow-button-icon-transform: translateX(0);
                --forward-arrow-button-hover-icon-transform: translateX(4px);
                --mini-widget-tile-background-color: var(--tile-contrast-background-color);
                --mini-widget-tile-hover-background-color: var(--tile-contrast-hover-background-color);
                --mini-widget-image-width: 5rem;
                --q2-composable-widget-tile-background-color: var(--tile-contrast-hover-background-color);
                --q2-composable-widget-tile-hover-background-color: var(--tile-contrast-background-color);
                --tool-tip-icon-color: var(--primary-text-color);
            }
            *,
            *::before,
            *::after {
                box-sizing: border-box;
            }

            :focus,
            :focus-visible {
                outline-color: var(--focus-outline-color);
            }

            html {
                font-size: 16px;
                -moz-osx-font-smoothing: grayscale;
                -webkit-font-smoothing: antialiased;
                font-smoothing: antialiased;
                text-rendering: optimizeLegibility;
            }

            body {
                scroll-behavior: smooth;
                text-rendering: optimizeSpeed;
                background-color: var(--po-white);
                font-family: var(--font-family-primary);
                font-size: var(--font-size-s);
                line-height: var(--line-height-body);
                color: var(--primary-text-color);
            }

            body,
            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p,
            ul,
            ol,
            li,
            figure,
            figcaption,
            blockquote,
            dl,
            dd,
            img {
                margin: 0;
            }

            ol,
            ul {
                padding: 0;
                list-style-type: none;
            }

            code,
            pre,
            kbd,
            samp {
                font-family: var(--font-family-code);
            }

            small,
            img {
                display: inline-block;
            }

            h1,
            h2,
            h3,
            h4,
            h5,
            h6 {
                line-height: var(--line-height-heading);
            }

            h1 {
                font-size: var(--font-size-xxl);
            }

            h2 {
                font-size: var(--font-size-xl);
            }

            h3 {
                font-size: var(--font-size-l);
            }

            h4 {
                font-size: var(--font-size-m);
            }

            h5,
            p {
                font-size: var(--font-size-s);
            }

            small {
                font-size: var(--font-size-xs);
            }

            a {
                color: var(--external-link-color);
                text-decoration: none;
                font-weight: 700;
                cursor: pointer;

                &:hover {
                    --external-link-color: var(--external-link-hover-color);
                    text-decoration: underline;
                }
            }

            [role="button"] {
                cursor: pointer;
            }

            hr {
                border-right: 0;
                border-left: 0;
                border-top: 0;
                border-color: var(--po-black-200);
            }

            fieldset {
                padding: 0;
                border: none;
                margin: 0;
            }

            legend {
                font-size: var(--font-size-m);
                line-height: 1.25;
                display: block;
            }

            @media (prefers-reduced-motion: reduce) {
                * {
                    animation-duration: 0.01ms !important;
                    animation-iteration-count: 1 !important;
                    transition-duration: 0.01ms !important;
                    scroll-behavior: auto !important;
                }
            }
            body #root {
                height: 100%;
            }
            body #root .inner-content-wrapper {
                height: 100vh;
            }
        </style>
    `;
};
