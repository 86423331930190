import commonReducer, { commonInitialState } from '../../slices/common-slice';
import partnerConfigReducer, { partnerInitialState } from '../../slices/partner-config-slice';
import sdkReducer, { sdkInitialState } from '../../slices/sdk-slice';

export const commonSlices = {
    common: commonReducer,
    sdk: sdkReducer,
    partnerConfig: partnerConfigReducer,
};

export const commonInitState = {
    common: commonInitialState,
    sdk: sdkInitialState,
    partnerConfig: partnerInitialState,
};
