import React from 'react';
import Classnames from 'classnames';
import { TypographicSizes } from '../../../slices/common-slice';

import './heading-two.scss';

interface HeadingTwoProps {
    size?: TypographicSizes;
    text: string;
}

export const HeadingTwo: React.FC<HeadingTwoProps> = ({ size = TypographicSizes.TWO, text }): React.ReactElement => {
    const classes = Classnames('heading-two', {
        [`heading-two--size-${size}`]: size,
    });

    return <h2 className={classes}>{text}</h2>;
};
