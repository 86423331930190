import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { sharedAPI } from '../utils/services/api';

export interface EmailCaptureState {
    emailCaptureFlowState?: EmailCaptureFlowStateNames;
    capturedEmail?: string;
}

export enum EmailCaptureFlowStateNames {
    NO_CAPTURE_FLOW = 'no-capture-flow',
    START_CAPTURE_FLOW = 'start-capture-flow',
    CONFIRM_CAPTURE_FLOW = 'confirm-capture-flow',
    COMPLETED_CAPTURE_FLOW = 'completed-capture-flow',
}

export const emailCaptureInitialState: EmailCaptureState = {
    emailCaptureFlowState: EmailCaptureFlowStateNames.NO_CAPTURE_FLOW,
    capturedEmail: null,
};

type CaptureEmailRequest = {
    email: string;
};

export const postCapturedEmail = createAsyncThunk(
    'account/postCapturedEmail',
    async ({ email }: CaptureEmailRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: { email },
                endpoint: '/account/email/partnerAccount',
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const emailCaptureSlice = createSlice({
    initialState: emailCaptureInitialState,
    name: 'email-capture',
    reducers: {
        setEmailCaptureFlowState(state, action: PayloadAction<EmailCaptureFlowStateNames>) {
            state.emailCaptureFlowState = action.payload;
        },
        setCapturedEmail(state, action: PayloadAction<string>) {
            state.capturedEmail = action.payload;
        },
    },
});

export const { setCapturedEmail, setEmailCaptureFlowState } = emailCaptureSlice.actions;

const selectEmailCaptureState = ({ emailCapture }: RootState): EmailCaptureState => emailCapture;

export const selectCapturedEmail = createSelector(selectEmailCaptureState, ({ capturedEmail }) => capturedEmail);

export const selectEmailCaptureFlowState = createSelector(
    selectEmailCaptureState,
    ({ emailCaptureFlowState }) => emailCaptureFlowState,
);

export default emailCaptureSlice.reducer;
