import React from 'react';
import Classnames from 'classnames';
import { Clickable } from 'reakit/Clickable';
import { ClickableHTMLTypes } from '../../../slices/common-slice';
import { TrackingService } from '../../../utils/services/tracking';
import './clickable-card.scss';

interface ClickableCardProps {
    cardName?: string; // Used with tracking
    className?: string;
    htmlElement?: ClickableHTMLTypes | any;
    isDisabled?: boolean;
    onClick?: () => void;
    testId?: string;
    disableTracking?: boolean;
}

export const ClickableCard: React.FC<ClickableCardProps> = ({
    children,
    cardName,
    className,
    htmlElement = ClickableHTMLTypes.DIV,
    isDisabled,
    onClick,
    testId = 'clickable-card',
    disableTracking = false,
}): React.ReactElement => {
    const trackingService = disableTracking ? null : TrackingService.getTrackingService();
    const classes = Classnames('clickable-card', className);

    const clickHandler = (...args: any) => {
        if (trackingService) {
            trackingService.trackCardClick(cardName); // No need to wait for the promise to resolve here.
        }
        onClick?.call(null, ...args);
    };

    return (
        <Clickable
            as={htmlElement}
            className={classes}
            data-testid={testId}
            disabled={isDisabled}
            onClick={clickHandler}
        >
            {children}
        </Clickable>
    );
};
