import React from 'react';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { RoundImageOfferDisplay } from './logomark-offer-display';
import { RoundImageSizes } from '../../../../../../slices/common-slice';

import './cashback-offer-display.scss';

interface OfferDisplayProps {
    data: CurrentOfferDisplay;
}

export const CashbackOfferDisplay: React.FC<OfferDisplayProps> = ({ data }): React.ReactElement => {
    return <RoundImageOfferDisplay size={RoundImageSizes.EIGHT} data={data} hideBonusText />;
};
