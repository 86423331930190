import { useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { useState } from 'react';
import { setHasShownAutoOnboarding } from '../slices/common-slice';

export function useOnboardingStateManager(): {
    isOpen: boolean;
    closeOnboardingModal: () => void;
    openOnboardingModal: () => void;
} {
    const dispatch = useDispatch<AppDispatch>();

    const [isOpen, setIsOpen] = useState(false);

    const openOnboardingModal = () => {
        setIsOpen(true);
    };

    const closeOnboardingModal = () => {
        dispatch(setHasShownAutoOnboarding(true));
        setIsOpen(false);
    };

    return {
        isOpen,
        closeOnboardingModal,
        openOnboardingModal,
    };
}

export default useOnboardingStateManager;
