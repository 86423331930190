import React, { useCallback, useEffect, useMemo, useReducer, useRef } from 'react';
import { convertCentsToDollars, currencyDisplay } from '../../../../utils';
import { useAppSelector } from '../../../../hooks';
import {
    selectCurrentCustomDenomType,
    selectCurrentSelectedOffer,
    selectCustomDenomDisplayCost,
    selectCustomDenomDisplayValue,
    selectIsCostCustomDenom,
    selectIsCustomDenomDualInputs,
    selectIsDualCostOnlyCustomDenom,
    selectIsDualCostOrValueCustomDenom,
    selectIsDualValueOnlyCustomDenom,
    selectIsValueCustomDenom,
    setCurrentCustomDenomType,
    setCustomDenomDisplayAmountEarned,
    setCustomDenomDisplayCost,
    setCustomDenomDisplayValue,
    resetCustomDenoms,
} from '../../../../slices/checkout-slice';
import { PrizeoutOffer, PrizeoutOfferValueOptions } from '../../../../slices/offers-slice';
import { InputWrapper } from '../../ui-inputs/input-wrapper';
import { useForm } from 'react-hook-form';
import { MonetaryInput } from '../../ui-inputs/monetary-input';
import { INITIAL_ERROR_STATE, errorStateReducer } from '../../../../reducers/error-state-reducer';
import { getNumericValue } from '../../ui-inputs/input-helpers';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { normalizeCustomDenomValues } from '../../../../utils/helpers/custom-denom-normalization-helpers';
import Classnames from 'classnames';
import { customDenomsReducer, customDenomsInitialState, CustomDenomsActionNames } from './custom-denoms-state-manager';
import { selectWithdrawalTerm } from '../../../../slices/partner-config-slice';

import './custom-denoms-modal.scss';

enum CustomDenomsInputTypes {
    MONETARY_COST = 'monetaryCost',
    MONETARY_VALUE = 'monetaryValue',
}

interface CustomDenomsProps {
    data: PrizeoutOfferValueOptions;
    onPrimaryClick?: () => void;
    onError?: (hasError: boolean) => void; // Callback to reference in the checkout file
    defaultValue?: number;
}

export interface CustomDenomsInputs {
    monetaryCost: string;
    monetaryValue: string;
}

export const CustomDenomsInputs: React.FC<CustomDenomsProps> = ({
    data,
    onPrimaryClick,
    onError,
    defaultValue,
}): React.ReactElement => {
    const {
        formState: { errors },
        handleSubmit,
        register,
        setError,
        setValue,
        watch,
    } = useForm<CustomDenomsInputs>({ mode: 'onChange' });
    const dispatch = useDispatch<AppDispatch>();

    const activeOffer: PrizeoutOffer = useAppSelector(selectCurrentSelectedOffer);
    const customDenomDisplayValue = useAppSelector(selectCustomDenomDisplayValue);
    const customDenomDisplayCost = useAppSelector(selectCustomDenomDisplayCost);

    const isCustomDenomDualInputs = useAppSelector(selectIsCustomDenomDualInputs);
    const isCostCustomDenomInput = useAppSelector(selectIsCostCustomDenom);
    const isValueCustomDenomInput = useAppSelector(selectIsValueCustomDenom);
    const isCustomDenomDualCostOnly = useAppSelector(selectIsDualCostOnlyCustomDenom);
    const isCustomDenomDualValueOnly = useAppSelector(selectIsDualValueOnlyCustomDenom);
    const isCustomDenomDualCostOrValue = useAppSelector(selectIsDualCostOrValueCustomDenom);
    const currentCustomDenomType = useAppSelector(selectCurrentCustomDenomType);
    const withdrawalTerm = useAppSelector(selectWithdrawalTerm);

    useEffect(() => {
        // Set "current" denom type based on data passed through
        if (data?.custom_denom_type) {
            dispatch(setCurrentCustomDenomType(data.custom_denom_type));
        }

        return () => {
            dispatch(setCurrentCustomDenomType(null));
        };
    }, [data]);

    const formClasses = Classnames('custom-denoms-modal__form', {
        'custom-denoms-modal__form--two-columns': isCustomDenomDualInputs,
    });

    const valueInputRef = useRef(null);
    const costInputRef = useRef(null);

    const watchMonetaryCost = watch('monetaryCost');
    const watchMonetaryValue = watch('monetaryValue');

    const [errorState, dispatchErrorState] = useReducer(errorStateReducer, INITIAL_ERROR_STATE);
    const [customDenomsState, dispatchCustomDenoms] = useReducer(customDenomsReducer, customDenomsInitialState);

    const shouldShowErrorBelowCostInput = errorState.isError && errors?.monetaryCost && customDenomsState.isCostFocused;

    const shouldShowErrorBelowValueInput =
        errorState.isError && errors?.monetaryValue && customDenomsState.isValueFocused;

    const formattedAmount = useCallback(
        (amountInCents: number) => {
            return currencyDisplay(activeOffer.currency_code, parseFloat(convertCentsToDollars(amountInCents)), true);
        },
        [activeOffer, data],
    );

    // Get custom denom values based on which input user inputted
    const customDenomAmounts = useMemo(() => {
        if (!customDenomsState.currentDenom) {
            return;
        }

        if (customDenomsState.shouldGetCustomDenomValuesViaValue) {
            return normalizeCustomDenomValues(
                activeOffer.display_offer_type,
                data.display_monetary_bonus,
                data.display_bonus,
                {
                    value: customDenomsState.currentDenom
                        ? customDenomsState.currentDenom
                        : customDenomDisplayValue || data.min_value_in_cents,
                },
            );
        }

        return normalizeCustomDenomValues(
            activeOffer.display_offer_type,
            data.display_monetary_bonus,
            data.display_bonus,
            {
                cost: customDenomsState.currentDenom
                    ? customDenomsState.currentDenom
                    : customDenomDisplayCost || data.min_cost_in_cents,
            },
        );
    }, [customDenomsState.currentDenom]);

    const setCustomDenomDisplays = () => {
        dispatch(setCustomDenomDisplayValue(customDenomAmounts?.valueInCents));

        dispatch(setCustomDenomDisplayCost(customDenomAmounts?.costInCents));

        dispatch(setCustomDenomDisplayAmountEarned(customDenomAmounts?.offerInCents));

        if (onPrimaryClick) {
            onPrimaryClick();
        }
    };

    const handleInvalidAmount = (evt: React.InvalidEvent<HTMLInputElement>) => {
        const currentInputName =
            evt.target.name === 'monetaryCost'
                ? CustomDenomsInputTypes.MONETARY_COST
                : CustomDenomsInputTypes.MONETARY_VALUE;

        if (evt.target.validationMessage) {
            setError(currentInputName, { message: evt.target.validationMessage });
        }
    };

    const setFocusOnCostInput = () => {
        dispatchCustomDenoms({
            type: CustomDenomsActionNames.SET_FOCUSED_INPUT_COST,
        });
    };

    const setFocusOnValueInput = () => {
        dispatchCustomDenoms({
            type: CustomDenomsActionNames.SET_FOCUSED_INPUT_VALUE,
        });
    };

    const determineAutoFocus = () => {
        // Check which input we should move the focus to when the modal is first opened, based on the custom_denom_type returned from data
        if (isCostCustomDenomInput || isCustomDenomDualCostOnly || isCustomDenomDualCostOrValue) {
            setFocusOnCostInput();
        } else if (isValueCustomDenomInput || isCustomDenomDualValueOnly) {
            setFocusOnValueInput();
        }
    };

    const updateCurrentInputValues = () => {
        // If user inputted value, update 'cost' display
        if (customDenomsState.shouldUpdateCostDisplay) {
            setValue('monetaryCost', formattedAmount(customDenomAmounts.costInCents));
        } else if (customDenomsState.shouldUpdateValueDisplay) {
            // If user inputted cost, update 'value' display
            setValue('monetaryValue', formattedAmount(customDenomAmounts.valueInCents));
        }
    };

    const updateCurrentAmountEarned = () => {
        dispatchCustomDenoms({
            type: CustomDenomsActionNames.UPDATE_AMOUNT_EARNED,
            payload: {
                currentAmountEarned: formattedAmount(customDenomAmounts.offerInCents),
            },
        });
    };

    useEffect(() => {
        if (currentCustomDenomType) {
            determineAutoFocus();
        }
    }, [currentCustomDenomType]);

    useEffect(() => {
        if (!customDenomsState.currentDenom) {
            return;
        }

        updateCurrentInputValues();

        updateCurrentAmountEarned();

        // Set default value for denoms in store
        setCustomDenomDisplays();
    }, [customDenomsState.currentDenom]);

    useEffect(() => {
        // Dispatch each time user inputs a cost
        if (watchMonetaryCost && customDenomsState.isCostFocused) {
            dispatchCustomDenoms({
                type: CustomDenomsActionNames.USER_INPUTTED_COST,
                payload: {
                    currentDenom: getNumericValue(watchMonetaryCost),
                },
            });
        }
    }, [watchMonetaryCost, customDenomsState.isCostFocused]);

    useEffect(() => {
        // Dispatch each time user inputs a value
        if (watchMonetaryValue && customDenomsState.isValueFocused) {
            dispatchCustomDenoms({
                type: CustomDenomsActionNames.USER_INPUTTED_VALUE,
                payload: {
                    currentDenom: getNumericValue(watchMonetaryValue),
                },
            });
        }
    }, [watchMonetaryValue, customDenomsState.isValueFocused]);

    useEffect(() => {
        if (!defaultValue) {
            dispatch(resetCustomDenoms());
        }
    }, []);

    const renderAmountEarned = () => {
        if (errorState.isError) {
            return;
        }

        return (
            <span className="custom-denoms-modal__caption">
                {customDenomsState.currentAmountEarned} {activeOffer.display_offer_type}
            </span>
        );
    };

    useEffect(() => {
        onError(errorState.isError);
    }, [errorState.isError]);

    const renderCostInput = () => {
        return (
            <div>
                <InputWrapper
                    formErrors={errors}
                    InputComponent={MonetaryInput}
                    inputId="custom-denoms-modal-cost-input"
                    inputProps={{
                        ...register('monetaryCost'),
                        autoFocus: customDenomsState.isCostFocused,
                        currencyType: activeOffer.currency_code,
                        defaultValue: '',
                        readOnly: isCustomDenomDualValueOnly,
                        required: true,
                        errorStateManager: [errorState, dispatchErrorState],
                        onFocus: setFocusOnCostInput,
                        onInvalid: handleInvalidAmount,
                        minAmount: data.min_cost_in_cents,
                        maxAmount: data.max_cost_in_cents,
                        increment: data.increment_cents,
                        shouldShortenErrorMessage: isCustomDenomDualInputs,
                    }}
                    labelText={withdrawalTerm}
                    ref={costInputRef}
                    useErrorAsLabel={false}
                />
                {shouldShowErrorBelowCostInput && (
                    <span className="custom-denoms-modal__caption custom-denoms-modal__caption--error">
                        {errors.monetaryCost.message}
                    </span>
                )}
            </div>
        );
    };

    const renderValueInput = () => {
        return (
            <div>
                <InputWrapper
                    formErrors={errors}
                    InputComponent={MonetaryInput}
                    inputId="custom-denoms-modal-value-input"
                    inputProps={{
                        ...register('monetaryValue'),
                        autoFocus: customDenomsState.isValueFocused,
                        currencyType: activeOffer.currency_code,
                        defaultValue: defaultValue ? defaultValue : '',
                        readOnly: isCustomDenomDualCostOnly,
                        required: true,
                        errorStateManager: [errorState, dispatchErrorState],
                        onFocus: setFocusOnValueInput,
                        onInvalid: handleInvalidAmount,
                        minAmount: data.min_value_in_cents,
                        maxAmount: data.max_value_in_cents,
                        increment: data.increment_cents,
                        shouldShortenErrorMessage: isCustomDenomDualInputs,
                    }}
                    labelText={isCustomDenomDualInputs ? `Gift card amount` : `Enter an amount`}
                    ref={valueInputRef}
                    useErrorAsLabel={false}
                />
                {shouldShowErrorBelowValueInput && (
                    <span className="custom-denoms-modal__caption custom-denoms-modal__caption--error">
                        {errors.monetaryValue.message}
                    </span>
                )}
            </div>
        );
    };

    if (!data) {
        return null;
    }

    return (
        <>
            <form id="custom-denom-input-form" className={formClasses} onSubmit={handleSubmit(setCustomDenomDisplays)}>
                {isCostCustomDenomInput && renderCostInput()}

                {isValueCustomDenomInput && renderValueInput()}

                {isCustomDenomDualInputs && (
                    <>
                        {renderCostInput()}
                        {renderValueInput()}
                    </>
                )}

                {customDenomsState.currentAmountEarned && renderAmountEarned()}
            </form>
        </>
    );
};
