import React, { useState } from 'react';
import { Button } from '../../../../components/common';
import { FilterModal } from './filter-modal';
import { useAppSelector } from '../../../../hooks';
import { selectOfferCategories, selectActiveOfferFilters } from '../../../../slices/offers-slice';
import { TrackingService, SearchTrackingTypes } from '../../../../utils/services/tracking';

import './filter-button.scss';

export const FilterButton: React.FC = (): React.ReactElement => {
    const [isFilterModalShowing, setIsFilterModalShowing] = useState(false);
    const availableCategories = useAppSelector(selectOfferCategories);
    const activeFilterCategories = useAppSelector(selectActiveOfferFilters);
    const trackingService = TrackingService.getTrackingService();

    const onClickHandler = () => {
        setIsFilterModalShowing(true);
        trackingService.trackSearchEvent(SearchTrackingTypes.SEARCH_FILTER_CLICK);
    };

    const onModalCloseHandler = () => {
        setIsFilterModalShowing(false);
        trackingService.trackSearchEvent(SearchTrackingTypes.SEARCH_FILTER_CATEGORY_CONFIRM, {
            type: activeFilterCategories.map((cat) => cat.category).join(','),
        });
    };

    if (availableCategories.length === 0) {
        return <></>;
    }

    return (
        <>
            <div className="filter-button">
                <Button ariaLabel="Filter" isElement onClick={() => onClickHandler()} testId="filter-modal-trigger">
                    <i className="fak fa-prizeout-filter"></i>
                    <strong>Filter</strong>
                </Button>
            </div>

            <FilterModal isOpen={isFilterModalShowing} onClose={() => onModalCloseHandler()} />
        </>
    );
};
