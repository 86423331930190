import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { sharedAPI } from '../../utils/services/api';
import { RootState } from '../../store';
import { PaginatedData } from '../../utils';
import { selectCurrentBankAccount } from '../partner-config-slice';

export enum CashbackTxnSubTypes {
    FUNDS_TRANSFER = 'funds_transfer',
    GIFT_CARD = 'giftcard',
    ADJUSTMENT = 'adjustment',
    PROMOTION = 'promotion',
}

export enum CashbackTransactionSpendTypes {
    CREDIT = 'credit',
    DEBIT = 'debit',
}

export enum CashbackTransactionStatusTypes {
    FAILED = 'failed',
    PENDING = 'pending',
    SUCCESS = 'success',
}

export enum CashbackTransactionTypes {
    PURCHASE = 'purchase',
    REDEMPTION = 'redemption',
    ADJUSTMENT = 'adjustment',
}

export enum CashbackLayoutTypes {
    HOW_TO_USE_BANNER_GENERIC = 'how_to_use_banner_generic', // TODO, placeholder - this will be updated once it's finalized on BE
}

export enum CashbackTransactionsListType {
    PENDING_TRANSACTIONS = 'pending',
    TRANSACTIONS = 'transactions',
}

export enum CashbackBankTransferStatusTypes {
    FAIL = 'fail',
    PROCESSING = 'processing',
    SUCCESS = 'success',
    STARTED = 'started',
}

export interface CashbackState {
    cashbackTransactions: PaginatedData<CashbackTransaction[]>;
    cashbackWallet: CashbackWalletResponse;
    cashbackBankTransfer?: CashbackBankTransferResponse;
}

export const cashbackInitialState: CashbackState = {
    cashbackTransactions: {
        data: [],
        latestDate: null as string,
        pagination: {
            currentPage: 0,
            perPage: 0,
            total: 0,
        },
    },
    cashbackWallet: {
        balance: 0,
        cashbackBanner: {
            layoutType: CashbackLayoutTypes.HOW_TO_USE_BANNER_GENERIC,
        },
        currencyCode: 'USD',
        isBackToStoreEnabled: false,
        isTransferToBankEnabled: false,
        minBankTransferLimitCents: 0,
    },
};

export interface CashbackBanner {
    bullets?: string[];
    header?: string;
    imageUrl?: string;
    layoutType?: CashbackLayoutTypes;
    subHeader?: string;
}

export interface CashbackTransactionMetadata {
    title?: string;
    accountName?: string; // Last 4 numbers of account number
    currencyCode: string;
    logomarkUrl?: string;
    merchantName?: string;
    redemptionType?: CashbackTxnSubTypes;
    spendType: CashbackTransactionSpendTypes;
    status: CashbackTransactionStatusTypes;
    transactionCreateDate: string;
    value: number;
}
export interface CashbackTransaction {
    transactionId: string;
    transactionMetadata: CashbackTransactionMetadata;
    type: CashbackTransactionTypes;
}

export interface CashbackWalletSummaryResponse {
    balance: number;
    currencyCode: string;
    lifetimeCashbackEarnings?: number;
}

export interface CashbackWalletResponse extends CashbackWalletSummaryResponse {
    cashbackBanner: CashbackBanner;
    isBackToStoreEnabled: boolean;
    isTransferToBankEnabled: boolean;
    minBankTransferLimitCents: number;
}

export interface CashbackTransactionPageRequest {
    currentPage: number;
    perPage: number;
}

export interface CashbackBankTransferResponse {
    amountInCents: number;
    currencyCode: string;
    error?: boolean;
    message?: string;
    requestId: string;
    status: CashbackBankTransferStatusTypes;
}

export interface CashbackBankTransferRequest {
    amount: number;
    currencyCode: string;
    destinationPaymentId: string;
    requestId: string;
}

export const getCashbackWallet = createAsyncThunk('cashback/wallet', async ({}, { rejectWithValue, signal }) => {
    try {
        const results = await sharedAPI.request({
            data: {},
            endpoint: '/cashback/wallet',
            method: 'GET',
            signal: signal,
        });

        if (results?.data.error) {
            return rejectWithValue(results.data.message || 'Prizeout failed to load');
        }

        return {
            ...results?.data,
        };
    } catch (e) {
        return rejectWithValue(e);
    }
});

export const getCashbackTransactions = createAsyncThunk(
    'cashback/transactions',
    async ({ currentPage, perPage }: CashbackTransactionPageRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    currentPage,
                    perPage,
                },
                endpoint: '/cashback/transactions',
                method: 'GET',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.message || 'Prizeout failed to load');
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getCashbackBankTransfer = createAsyncThunk(
    'cashback/transfer',
    async (
        { amount, currencyCode, destinationPaymentId, requestId }: CashbackBankTransferRequest,
        { rejectWithValue, signal },
    ) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    amount,
                    currencyCode,
                    destinationPaymentId,
                    requestId,
                },
                endpoint: '/cashback/transfer',
                method: 'POST',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.message);
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const cashbackSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getCashbackWallet.fulfilled, (state, action: PayloadAction<CashbackWalletResponse>) => {
            state.cashbackWallet = action.payload;
        });

        builder.addCase(getCashbackWallet.rejected, (action: any) => {
            console.error(`getCashbackWallet error: ${action.error ? action.error : action}`);
        });

        builder.addCase(
            getCashbackTransactions.fulfilled,
            (state, action: PayloadAction<PaginatedData<CashbackTransaction[]>>) => {
                state.cashbackTransactions = action.payload;
            },
        );

        builder.addCase(
            getCashbackBankTransfer.fulfilled,
            (state, action: PayloadAction<CashbackBankTransferResponse>) => {
                state.cashbackBankTransfer = action.payload;
            },
        );

        builder.addCase(getCashbackBankTransfer.rejected, (action: any) => {
            console.error(`getCashbackBankTransfer error: ${action.error ? action.error : action}`);
        });
    },
    initialState: cashbackInitialState,
    name: 'cashback',
    reducers: {},
});

const selectCashbackState = ({ cashback }: RootState): CashbackState => cashback;

export const selectCashbackWallet = createSelector(selectCashbackState, ({ cashbackWallet }) => cashbackWallet);

export const selectCashbackPendingTransactionsList = createSelector(selectCashbackState, ({ cashbackTransactions }) => {
    return cashbackTransactions.data.filter((transaction) => {
        return transaction.transactionMetadata.status === CashbackTransactionStatusTypes.PENDING;
    });
});

export const selectCashbackTransactions = createSelector(selectCashbackState, ({ cashbackTransactions }) => {
    return cashbackTransactions.data.filter((transaction) => {
        return transaction.transactionMetadata.status !== CashbackTransactionStatusTypes.PENDING;
    });
});

export const selectCashbackBanner = createSelector(
    selectCashbackState,
    ({ cashbackWallet }) => cashbackWallet.cashbackBanner,
);

export const selectCashbackLifetimeEarnings = createSelector(
    selectCashbackState,
    ({ cashbackWallet }) => cashbackWallet.lifetimeCashbackEarnings,
);

export const selectIsTransferToBankEnabled = createSelector(
    selectCashbackState,
    ({ cashbackWallet }) => cashbackWallet.isTransferToBankEnabled,
);

export const selectMinBankTransferLimitCents = createSelector(
    selectCashbackState,
    ({ cashbackWallet }) => cashbackWallet.minBankTransferLimitCents,
);

export const selectCashbackBankTransfer = createSelector(
    selectCashbackState,
    selectCurrentBankAccount,
    ({ cashbackBankTransfer }, currentBankAccount) => {
        if (currentBankAccount && cashbackBankTransfer) {
            return cashbackBankTransfer;
        }
    },
);

export const cashbackReducer = cashbackSlice.reducer;

export default cashbackSlice;
