import React from 'react';
import Classnames from 'classnames';
import { RoundImageSizes } from '../../../slices/common-slice';
import { convertCentsToDollars } from '../../../utils/helpers';
import { CurrencyFormatter, RoundImage, TextLabel } from '../ui-widgets';

import './confirmation-detail-card.scss';

interface ConfirmationDetailCardProps {
    brandName?: string;
    currency?: string;
    hideBorder?: boolean;
    imageUrl?: string;
    value?: number;
}

export const ConfirmationDetailCard: React.FC<ConfirmationDetailCardProps> = ({
    brandName,
    children,
    currency,
    hideBorder,
    imageUrl,
    value,
}): React.ReactElement => {
    const classes = Classnames('confirmation-detail-card', { 'confirmation-detail-card--no-border': hideBorder });

    return (
        <article className={classes}>
            {imageUrl && (
                <RoundImage imageUrl={imageUrl} size={RoundImageSizes.SIX} testId="confirmation-detail-card-image" />
            )}

            {brandName && <TextLabel text={brandName} />}

            {value && (
                <p className="confirmation-detail-card__value">
                    <strong>
                        <CurrencyFormatter
                            currencyOverride={currency}
                            inline={true}
                            value={parseFloat(convertCentsToDollars(value))}
                            decimals={true}
                        />
                    </strong>
                </p>
            )}
            {children}
        </article>
    );
};
