import React from 'react';
import { CurrentOfferDisplay } from '../offer-gift-card';
import { RoundImageOfferDisplay } from './logomark-offer-display';
import { RoundImageSizes } from '../../../../../../slices/common-slice';

import './vertical-list-offer-display.scss';

interface OfferDisplayProps {
    data: CurrentOfferDisplay;
}

export const VerticalListOfferDisplay: React.FC<OfferDisplayProps> = ({ data }): React.ReactElement => {
    return <RoundImageOfferDisplay data={data} size={RoundImageSizes.SEVEN} hasBonusPill={true} />;
};
