import { useDispatch } from 'react-redux';
import { setWalletView, WalletView } from '../slices/wallet-slice';

import { AppDispatch } from '../store';

export function useSetWalletView() {
    const dispatch = useDispatch<AppDispatch>();

    const setView = (view: WalletView) => {
        dispatch(setWalletView(view));
    };

    return [setView];
}

export default useSetWalletView;
