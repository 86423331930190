import React from 'react';
import Classnames from 'classnames';
import { CobrandedImage } from './cobranded-image';
import { RoundImageSizes } from '../../../slices/common-slice';
import { useBrandingOverride } from '../../../hooks';

import './loader-image.scss';

interface LoaderImageProps {
    iconClasses?: string; // Use Font Awesome instead of image
    isRotate?: boolean;
    isPartner?: boolean;
    isPositionAbsolute?: boolean;
    isPulse?: boolean;
}

export const LoaderImage: React.FC<LoaderImageProps> = ({
    iconClasses,
    isRotate,
    isPartner,
    isPositionAbsolute = true,
    isPulse,
}): React.ReactElement => {
    const classes: string = Classnames(`loader-image`, {
        'loader-image--pulse': isPulse,
        'loader-image--rotate': isRotate,
        'loader-image--icon': iconClasses,
        'loader-image--position-absolute': isPositionAbsolute,
    });
    const [brandOverride, hasBrandOverride] = useBrandingOverride();

    const shouldShowCobrandedImage = !hasBrandOverride && !iconClasses;
    const shouldShowIcon = !hasBrandOverride && iconClasses;
    const shouldShowBrandOverrideImage = hasBrandOverride && !iconClasses;

    return (
        <div data-testid="loader-image" className={classes}>
            {shouldShowCobrandedImage && <CobrandedImage isPartner={isPartner} size={RoundImageSizes.THREE} />}
            {shouldShowIcon && <i className={iconClasses}></i>}
            {shouldShowBrandOverrideImage && (
                <img alt="Loading prizeout" data-testid="loader-brand-override" src={brandOverride} />
            )}
        </div>
    );
};
