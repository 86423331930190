import React from 'react';
import { PrizeoutOffer } from '../../../slices/offers-slice';
import { useNumberSequence } from '../../../hooks';

import './brand-availability-tags.scss';

interface BrandAvailabilityTagsProps {
    offer: PrizeoutOffer;
}

export const BrandAvailabilityTags: React.FC<BrandAvailabilityTagsProps> = ({ offer }): React.ReactElement => {
    const sequence = useNumberSequence();

    return (
        <>
            {!!offer.redemption_methods?.length && (
                <div className="brand-availability-tags">
                    {offer.redemption_methods.map((data) => (
                        <div data-testid="brand-availability-tag" key={sequence()}>
                            <i className={data.icon}></i> <span>{data.title}</span>
                        </div>
                    ))}
                </div>
            )}
        </>
    );
};
