import React from 'react';
import {
    HeaderTextBulletsLayout,
    InstructionsTemplateData,
    NumberedTitledBulletsLayout,
    PartnerReward,
    PartnerRewardInformationLayoutTypes,
    TextWithHeaderLayout,
} from '../../../../slices/rewards';
import { HowItWorksListItem, HowItWorksListItemTypes } from '../../how-it-works/how-it-works-list-item';
import { useNumberSequence } from '../../../../hooks';

import './instructions.scss';

interface InstructionsProps {
    data: PartnerReward;
}

type InstructionsTemplateMap = {
    [key in PartnerRewardInformationLayoutTypes]?: (data: InstructionsTemplateData) => JSX.Element;
};

export const Instructions: React.FC<InstructionsProps> = ({ data }): React.ReactElement => {
    const layout = data.layout;

    const listKey = useNumberSequence();

    const printHeaderTextBulletsLayout = (data: InstructionsTemplateData) => {
        const { bullets, header, text } = data as unknown as HeaderTextBulletsLayout;

        return (
            <>
                <h2 data-testid="instructions-header-text-bullets-header">{header}</h2>
                <p data-testid="instructions-header-text-bullets-text">{text}</p>
                <ul data-testid="instructions-header-text-bullets-bullets">
                    {bullets.map((item) => (
                        <li key={listKey()}>{item}</li>
                    ))}
                </ul>
            </>
        );
    };

    const printNumberedTitledBulletsLayout = (data: InstructionsTemplateData) => {
        const { titledNumberBullets } = data as unknown as NumberedTitledBulletsLayout;

        return (
            <ol data-testid="instructions-numbered-titled-bullets-list">
                {titledNumberBullets.map((item, index) => (
                    <HowItWorksListItem
                        description={item.text}
                        displayType={HowItWorksListItemTypes.SOLID}
                        headline={item.title}
                        key={listKey()}
                        number={index + 1}
                    />
                ))}
            </ol>
        );
    };

    const printTextWithHeaderLayout = (data: InstructionsTemplateData) => {
        const { text, header } = data as unknown as TextWithHeaderLayout;

        return (
            <>
                <h2 data-testid="instructions-text-with-header-header">{header}</h2>
                <p data-testid="instructions-text-with-header-text">{text}</p>
            </>
        );
    };

    const templateMap: InstructionsTemplateMap = {
        [PartnerRewardInformationLayoutTypes.HEADER_TEXT_BULLETS]: printHeaderTextBulletsLayout,
        [PartnerRewardInformationLayoutTypes.NUMBERED_TITLED_BULLETS]: printNumberedTitledBulletsLayout,
        [PartnerRewardInformationLayoutTypes.TEXT_WITH_HEADER]: printTextWithHeaderLayout,
    };

    const templateRenderer = templateMap[layout];

    if (!templateRenderer) {
        return null;
    }

    return <div className="instructions">{templateMap[layout](data.instructions)}</div>;
};
