import React from 'react';

export const charIsDigit = (char: string): boolean => {
    return !!char.match(/[0123456789]/);
};

export const charIsDigitOrPeriod = (char: string): boolean => {
    return !!char.match(/[0123456789.]/);
};

export const keyboardEventHasModifier = (evt: React.KeyboardEvent<HTMLInputElement>): boolean => {
    return evt.altKey || evt.ctrlKey || evt.metaKey || evt.shiftKey;
};

export const setTargetSelection = (target: HTMLInputElement, selectionStart: number, selectionEnd?: number) => {
    if (selectionStart <= 0) {
        return;
    }

    target.selectionStart = selectionStart;

    selectionEnd = selectionEnd ?? selectionStart;

    if (selectionEnd <= 0) {
        return;
    }

    target.selectionEnd = selectionEnd;
};

export const getNumericValue = (value: string | number | readonly string[]) => {
    if (typeof value == 'string') {
        value = scrubDecimals(value);
    }

    return value ? Number(value.toString().replace(/\D/g, '')) : 0;
};

export const scrubDecimals = (value: string): string => {
    let newValue = '';
    let numDecimalsFound = 0;
    let numCharsAfterFirstDecimal = 0;
    for (let i = 0; i < value.length; i++) {
        if (numCharsAfterFirstDecimal >= 2) {
            break;
        }
        if (numDecimalsFound > 0 && value[i] != '.') {
            numCharsAfterFirstDecimal++;
        }
        if (value[i] == '.') {
            numDecimalsFound++;
            if (numDecimalsFound > 1) {
                continue;
            }
        }

        newValue += value[i];
    }

    if (numCharsAfterFirstDecimal == 0) {
        newValue += '00';
    } else if (numCharsAfterFirstDecimal == 1) {
        newValue += '0';
    }

    return newValue;
};
