import useGoToRoute from './use-goto-route';
import { CRMCTA, CTAType } from '../slices/crm/crm-types';
import { routes } from '../utils/helpers/routing-helpers';

export const useFindWidgetRoutes = (cta_list: CRMCTA[], url?: routes) => {
    const { goToRoute } = useGoToRoute();
    if (url) {
        goToRoute(url);
    }

    const findCTAByType = (type: CTAType) => {
        return cta_list?.find((cta) => cta.cta_type === type);
    };

    const primaryCTA = findCTAByType(CTAType.PRIMARY);
    const secondaryCTA = findCTAByType(CTAType.SECONDARY);
    const clickableArea = findCTAByType(CTAType.CLICKABLE_AREA);

    return {
        primaryCTA,
        secondaryCTA,
        clickableArea,
    };
};
