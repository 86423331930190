import React from 'react';
import { useAppSelector } from '../../../hooks';
import { selectIsCobranded, selectIsMiniWidgetLoader, selectLoading } from '../../../slices/common-slice';
import { LoaderImage } from './loader-image';
import { CRMMiniWidgetSkeletonLoader } from '../skeleton-loaders/crm-mini-widget-skeleton-loader';

import './loader.scss';

export const Loader: React.FC = (): React.ReactElement => {
    const isLoading = useAppSelector(selectLoading);
    const isCobranded = useAppSelector(selectIsCobranded);
    const isMiniWidgetLoader = useAppSelector(selectIsMiniWidgetLoader);

    return (
        <>
            {isLoading && (
                <div data-testid="loader" className="loader z-index-loader">
                    {!isMiniWidgetLoader && (
                        <div className="loader__wrapper">
                            <>
                                {isCobranded && <LoaderImage isPartner isRotate={isCobranded} />}

                                <LoaderImage
                                    isRotate={isCobranded}
                                    isPulse={!isCobranded}
                                    iconClasses="fa-kit fa-prizeout-bonus-tag-percentage"
                                />
                            </>
                        </div>
                    )}

                    {isMiniWidgetLoader && <CRMMiniWidgetSkeletonLoader />}
                </div>
            )}
        </>
    );
};
