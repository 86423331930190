import React from 'react';
import Classnames from 'classnames';
import { GiftingStatusTypes } from '../../../slices/gifting-slice';
import { titleCaseText } from '../../../utils/helpers';

import './status-token.scss';

interface StatusTokenProps {
    type: GiftingStatusTypes;
}

export const StatusToken: React.FC<StatusTokenProps> = ({ type }): React.ReactElement => {
    const classes: string = Classnames(`status-token`, `status-token--${type}`);

    let icon: string;

    switch (type) {
        case GiftingStatusTypes.FAIL:
            icon = 'times';
            break;

        case GiftingStatusTypes.SENT:
            icon = 'paper-plane';
            break;

        case GiftingStatusTypes.PENDING:
            icon = 'clock';
            break;
    }

    return (
        <article data-testid="status-token" className={classes}>
            <div className="status-token__icon">
                <i className={`far fa-${icon}`}></i>
            </div>
            <p className="status-token__text">{titleCaseText(type)}</p>
        </article>
    );
};
