import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../utils/services/api';
import type { RootState } from '../store';

export enum OTPModalView {
    SELECT_VERIFICATION_METHOD = 'selectVerificationMethod',
    ENTER_VERIFICATION_CODE = 'enterVerificationCode',
}

export enum OTPVerificationOptionType {
    EMAIL = 'email',
    PHONE = 'phone',
}

// Define a type for the slice state
export interface OTPState {
    activeDeliveryPreference?: OTPOption;
    deliveryOptions: OTPOption[];
    view: OTPModalView;
    activeOtpRequestId?: string;
    otpCompleted?: boolean;
}

// Define the initial state using that type
export const otpInitialState: OTPState = {
    deliveryOptions: [],
    view: OTPModalView.SELECT_VERIFICATION_METHOD,
    otpCompleted: false,
};

interface GetOTPOptionsResponse {
    otpRequestId: string;
    expirationDate: string;
    minRetryDateUTC: string;
    status: string;
    deliveryOptions: OTPOption[];
}

export interface OTPOption {
    deliveryPreferenceId: string;
    type: OTPVerificationOptionType;
    value: string;
}

interface GetOTPOptions {
    otpRequestId: string;
}

type RenewOTPFlow = GetOTPOptions;

interface RequestOTP {
    deliveryPreferenceId: string;
    otpRequestId: string;
}

interface ProcessOTP {
    otpRequestId: string;
    code: string;
    rememberDevice: boolean;
}

export interface OTPRequestResponse {
    otpRequestId: string;
}

export const getOTPOptions = createAsyncThunk(
    'otp/getOptions',
    async ({ otpRequestId }: GetOTPOptions, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/otp/partnerAccount/${otpRequestId}`,
                method: 'GET',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const renewOTPRequestID = createAsyncThunk(
    'otp/renewOTPRequestID',
    async ({ otpRequestId }: RenewOTPFlow, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    otpRequestId,
                },
                endpoint: `/otp/partnerAccount/resend`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const requestOTP = createAsyncThunk(
    'otp/requestOTP',
    async ({ deliveryPreferenceId, otpRequestId }: RequestOTP, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    deliveryPreferenceId,
                    otpRequestId,
                },
                endpoint: `/otp/partnerAccount/request`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const processOTP = createAsyncThunk(
    'otp/processOTP',
    async ({ code, otpRequestId, rememberDevice }: ProcessOTP, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    code,
                    otpRequestId,
                    rememberDevice,
                },
                endpoint: `/otp/partnerAccount/attempt`,
                method: 'POST',
                signal: signal,
            });
            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const otpSlice = createSlice({
    initialState: otpInitialState,
    name: 'otp',
    extraReducers: (builder) => {
        builder.addCase(getOTPOptions.fulfilled, (state, action: PayloadAction<GetOTPOptionsResponse>) => {
            state.deliveryOptions = action.payload.deliveryOptions;
        });

        builder.addCase(renewOTPRequestID.fulfilled, (state, action: PayloadAction<OTPRequestResponse>) => {
            state.activeOtpRequestId = action.payload.otpRequestId;
        });
    },
    reducers: {
        setActiveDeliveryPreference(state, action: PayloadAction<OTPOption>) {
            state.activeDeliveryPreference = action.payload;
        },
        setCurrentOTPFlowView(state, action: PayloadAction<OTPModalView>) {
            state.view = action.payload;
        },
        setActiveOtpRequestId(state, action: PayloadAction<string>) {
            state.activeOtpRequestId = action.payload;
        },
        setOtpCompleted(state, action: PayloadAction<boolean>) {
            state.otpCompleted = action.payload;
        },
    },
});

export const { setActiveDeliveryPreference, setCurrentOTPFlowView, setActiveOtpRequestId, setOtpCompleted } =
    otpSlice.actions;

const selectOTPState = ({ otp }: RootState): OTPState => otp;

export const selectOTPModalView = createSelector(selectOTPState, ({ view }) => view);

export const selectOTPDeliveryOptions = createSelector(selectOTPState, ({ deliveryOptions }) => deliveryOptions);

export const selectActiveDeliveryPreference = createSelector(
    selectOTPState,
    ({ activeDeliveryPreference }) => activeDeliveryPreference,
);

export const selectActiveOtpRequestId = createSelector(selectOTPState, ({ activeOtpRequestId }) => activeOtpRequestId);

export const selectOtpCompleted = createSelector(selectOTPState, ({ otpCompleted }) => otpCompleted);

export default otpSlice.reducer;
