import React from 'react';
import { useAppSelector } from '../../../hooks';
import { selectBalanceCurrencyCopy, selectUserBalance } from '../../../slices/partner-config-slice';
import { convertCentsToDollars } from '../../../utils/helpers';
import CurrencyFormatter from './currency-formatter';

import './user-balance.scss';

export const UserBalance: React.FC = (): React.ReactElement => {
    const userBalance = useAppSelector(selectUserBalance);
    const convertedBalance = parseFloat(convertCentsToDollars(userBalance));
    const balanceCurrencyCopy = useAppSelector(selectBalanceCurrencyCopy);

    return (
        <>
            {(userBalance || userBalance === 0) && (
                <div data-testid="user-balance" className="user-balance">
                    <p>
                        <CurrencyFormatter
                            value={convertedBalance}
                            decimals={true}
                            inline={true}
                            currencySymbolOverride={balanceCurrencyCopy}
                        />
                    </p>
                </div>
            )}
        </>
    );
};
