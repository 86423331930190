export enum RewardsEventGroups {
    REWARDS = 'Rewards',
}

export enum RewardTemplateIconTypes {
    BELL_NOTIFICATIONS = 'bell-notifications',
    BILL_ENVELOPE = 'bill-envelope',
    CAKE_CANDLES = 'cake-candles',
    CHECK_PEN = 'check-pen',
    CREDIT_CARD = 'credit-card',
    DEPOSIT_FUNDS = 'deposit-funds',
    DEPOSIT_MONEY = 'deposit-money',
    GIFT_CARD = 'gift-card',
    MESSAGES_DOLLAR = 'messages-dollar',
    MULTIPLE_CARDS_PLUS = 'multiple-cards-plus',
    PAPERLESS_CHECK = 'paperless-check',
    SHOPPING_BAGS = 'shopping-bags',
    SPARKLES = 'sparkles',
    SUBSCRIPTION_STACK = 'subscription-stack',
    USER_GROUP = 'user-group',
}

export enum RewardClaimProccessingStatuses {
    PROCESSING = 'processing',
    SUCCESS = 'success',
}

export enum LimitsDisplayTypes {
    ACTION_COUNTER = 'action_counter',
}

export enum StatDisplayTypes {
    ACTION_COUNTER = 'action_counter',
    MULTI_STEP_ACTION_COUNTER = 'multi_step_action_counter',
    SUMMARY_COUNTER = 'summary_counter',
}

export type GetRewardsResponse = {
    active?: ConsumerReward[];
    available?: ConsumerReward[];
    completed?: ConsumerReward[];
};

export type ExplicitTags = {
    valueTag?: string;
    limitTag?: string;
    timeTag?: string;
    urgencyTag?: string;
};

export const explicitTagLabels = {
    valueTag: 'Reward Amount',
    limitTag: 'Limit',
    timeTag: 'End Date',
    urgencyTag: 'Urgency',
};
Object.freeze(explicitTagLabels);

export type PostRewardsClaimResponse = {
    claimProcessingStatus: RewardClaimProccessingStatuses;
    userReward: UserReward;
};

export type PostUserRewardRequest = {
    userRewardId: string;
};

export interface ActionCounterMap {
    currency?: string;
    currentProgressNumber: number;
    isCompleted?: boolean;
    isMonetary?: boolean;
    statsLabel: string;
    title: string;
    totalProgressNumber: number;
}

export interface ConsumerReward {
    partnerReward: PartnerReward;
    userReward: UserReward;
}

export interface PartnerRewardMeta {
    audienceId?: number;
    cta?: string;
    ctaLink?: string;
    instructions: InstructionsTemplateData;
    isActive: boolean;
    isRecurring: boolean;
    layout: PartnerRewardInformationLayoutTypes;
    partnerId: string;
    partnerRewardId: string;
    partnerRewardName: string;
    partnerRewardTitle: string;
    rewardTemplateIcon: RewardTemplateIconTypes;
    rewardTemplateId: string;
    shortDescription: string;
}

export interface PartnerRewardPayoutTier {
    amount: number;
    createDate?: string;
    isActive: boolean;
    maxAmountTier?: number;
    minAmountTier?: number;
    partnerRewardPayoutId: string;
    partnerRewardPayoutTierId: string;
    updateDate?: string;
}

export interface PartnerRewardPayoutStat {
    partnerRewardPayoutStatsId: string;
    partnerRewardId: string;
    statActionType: string;
    statSummaryActionType: string;
    transactionStatSummaryActionType: string;
    transactionGroupAccounts: boolean;
    useAnyAccount: boolean;
    useSum: boolean;
    useCount: boolean;
    sumOrCountEquality: string;
    sumOrCountEqualityComparison: number;
    isActive: boolean;
    createDate: string;
    updateDate: string;
}

export interface PartnerRewardPayoutStatInteraction {
    partnerRewardPayoutStatInteractionsId: string;
    partnerRewardId: string;
    interactionExpression: string;
    createDate: string;
    updateDate: string;
}

interface BaseRewardInstructionsTemplateData<T> {
    instructions: T;
    layout: PartnerRewardInformationLayoutTypes;
    partnerRewardId: string;
    partnerRewardTitle: string;
    shortDescription: string;
    summaryTags?: string[];
}

export interface UserRewardActionStat {
    actionType: string;
    completedDate: string;
    createDate: string;
    updateDate: string;
    userRewardActionStatId: string;
    userRewardId: string;
}

export interface PreviousRecurring {
    payoutAmountInCents: number;
    previousUserRewardId: string;
    recurringTimeString: string;
    userRewardId: string;
}

export interface ProgressLimits {
    isMonetary: boolean;
    limitsDisplayActionName: string;
    limitsDisplayTitle: string;
    limitsDisplayType: LimitsDisplayTypes;
    maxActionCounter: number;
    statNumber: number;
    userRewardId: string;
}

export interface RewardsProgress {
    estimatedPayoutAmount: number;
    isCompleted: boolean;
    isMonetary: boolean;
    maxActionCounter: number;
    statDisplayActionName: string;
    statDisplayOrder: number;
    statDisplayTitle: string;
    statDisplayType: StatDisplayTypes;
    statLastUpdatedDate: string;
    statNumber: number;
    userRewardId: string;
    userRewardPayoutStatsId: string;
}

export interface UserRewardProgress {
    payoutInProgress: boolean;
    previousRecurring: PreviousRecurring[];
    progressLimits: ProgressLimits[];
    rewardsProgress: RewardsProgress[];
}

export interface PartnerReward {
    audienceId: number;
    cta: string;
    ctaLink: string;
    instructions: InstructionsTemplateData;
    isRecurring: boolean;
    layout: PartnerRewardInformationLayoutTypes;
    limitEndDate: string;
    limitMaxNum: number;
    limitMaxPayoutAmounts: number;
    limitStartDate: string;
    limitTimeframeId: string;
    name: string;
    partnerId: string;
    partnerRewardId: string;
    partnerRewardInformationId: string;
    partnerRewardName: string;
    partnerRewardPayoutId: string;
    partnerRewardPayoutStats: PartnerRewardPayoutStat[];
    partnerRewardPayoutStatInteraction: PartnerRewardPayoutStatInteraction[];
    partnerRewardPayoutTiers: PartnerRewardPayoutTier[];
    partnerRewardTitle: string;
    rewardEndDate: string;
    rewardPayoutTypeId: string;
    rewardPayoutTypeName: PartnerRewardPayoutTypes;
    rewardStartDate: string;
    rewardTemplateIcon: RewardTemplateIconTypes;
    rewardTemplateId: string;
    rewardTimeframeId: string;
    shortDescription: string;
    summaryTags?: string[];
    explicitTags?: ExplicitTags;
}

export interface UserReward {
    activatedDate?: string;
    approvedDate?: string;
    claimedDate?: string;
    completedDate?: string;
    createDate?: string;
    internalStatus?: InternalStatusStates;
    partnerId: string;
    partnerRewardId: string;
    partnerRewardTimeframeId: string;
    partnerUserId: string;
    payoutAmountCurrencyCode?: string;
    payoutAmountInCents?: number;
    progress: UserRewardProgress;
    statLastUpdateDate: string;
    status: UserRewardStates;
    updateDate?: string;
    userRewardActionStat?: UserRewardActionStat[];
    userRewardActionStatSummary?: [];
    userRewardEndDate: string;
    userRewardId: string;
    userRewardStartDate: string;
    userRewardTransactionStatSummary?: [];
}

export type InstructionsTemplateData =
    | HeaderTextBulletsTemplate
    | NumberedTitledBulletsTemplate
    | TextWithHeaderTemplate;

export enum PartnerRewardInformationLayoutTypes {
    HEADER_TEXT_BULLETS = 'header_text_bullets',
    NUMBERED_TITLED_BULLETS = 'numbered_titled_bullets',
    TEXT_WITH_HEADER = 'text_with_header',
}

export enum PartnerRewardPayoutTypes {
    DOLLARS_PER_ACTION = 'DOLLARS_PER_ACTION',
    DOLLARS_PER_N_ACTIONS = 'DOLLARS_PER_N_ACTIONS',
    DOLLARS_PER_N_TRANSACTIONS = 'DOLLARS_PER_N_TRANSACTIONS',
    DOLLARS_PER_TRANSACTION = 'DOLLARS_PER_TRANSACTION',
    PERCENTAGE_ON_AMOUNT = 'PERCENTAGE_ON_AMOUNT',
    PERCENTAGE_PER_DOLLARS = 'PERCENTAGE_PER_DOLLARS',
    RAW_AMOUNT = 'RAW_AMOUNT',
}

export enum UserRewardStates {
    ACTIVE = 'active',
    AVAILABLE = 'available',
    CLAIMED = 'claimed',
    COMPLETED = 'completed',
}

export enum InternalStatusStates {
    ACTIVE = 'active',
    APPROVED = 'approved',
    AVAILABLE = 'available',
    CLAIMED = 'claimed',
    COMPLETED = 'completed',
}

export interface HeaderTextBulletsLayout {
    bullets: string[];
    header: string;
    text: string;
}

export type TitledNumberedBullet = {
    text: string;
    title: string;
};

export interface NumberedTitledBulletsLayout {
    titledNumberBullets: TitledNumberedBullet[];
}

export interface TextWithHeaderLayout {
    header: string;
    text: string;
}

export type HeaderTextBulletsTemplate = BaseRewardInstructionsTemplateData<HeaderTextBulletsLayout>;

export type NumberedTitledBulletsTemplate = BaseRewardInstructionsTemplateData<NumberedTitledBulletsLayout>;

export type TextWithHeaderTemplate = BaseRewardInstructionsTemplateData<TextWithHeaderLayout>;
