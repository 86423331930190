import React from 'react';
import { BalanceSkeletonLoader } from '../../crm/common/balance-skeleton-loader';
import { CRMGenericSkeletonLoaderBar } from '../../crm/common/crm-generic-skeleton-loader-bar';
import './crm-mini-widget-skeleton-loader.scss';

export const CRMMiniWidgetSkeletonLoader = () => {
    return (
        <div data-testid="crm-mini-widget-skeleton-loader" className="crm-mini-widget-skeleton-loader">
            <BalanceSkeletonLoader isDisabled={true} />
            <CRMGenericSkeletonLoaderBar isDisabled={true} height={129} />
            <CRMGenericSkeletonLoaderBar isDisabled={true} height={52} />
        </div>
    );
};
