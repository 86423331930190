import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { sharedAPI } from '../utils/services/api';

export interface CategoryListSlice {
    categoryList: CategoryListItem[];
    activeCategoryListItem?: CategoryListItem;
    activeCategoryListId?: number;
}

export interface CategoryListItem {
    categoryId: number;
    categoryName: string;
    categoryImageUrl?: string;
    categoryDescription?: string;
    icon?: string;
}

export type CategoryList = CategoryListItem[];

export const categoryListInitialState: CategoryListSlice = {
    categoryList: [],
    activeCategoryListItem: null,
};

export type CategoryListRequest = {
    location: string;
    prizeoutSessionId: string;
};

export const getCategoryList = createAsyncThunk(
    'offers/getCategoryList',
    async ({ location, prizeoutSessionId }: CategoryListRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    countryCode: location,
                    prizeout_session_id: prizeoutSessionId,
                },
                method: 'POST',
                endpoint: '/categoryCarousel',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.error);
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const categoryListSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getCategoryList.fulfilled, (state, action: PayloadAction<CategoryList>) => {
            const arr = [];

            for (const i in action.payload) {
                arr.push(action.payload[i]);
            }

            state.categoryList = arr;
        });
    },
    initialState: categoryListInitialState,
    name: 'categoryList',
    reducers: {
        setActiveCategoryListItem(state, action: PayloadAction<CategoryListItem>) {
            state.activeCategoryListItem = action.payload;
            state.activeCategoryListId = action.payload?.categoryId;
        },
        setActiveCategoryListId(state, action: PayloadAction<number>) {
            state.activeCategoryListId = action.payload;
            state.activeCategoryListItem = state.categoryList.find(
                (category) => category.categoryId === action.payload,
            );
        },
        // manual setter - used for testing rewards
        // can be removed later once we no longer need
        // to manually set mock data.
        setCategoryList(state, action: PayloadAction<CategoryList>) {
            state.categoryList = action.payload;
        },
    },
});

export const { setCategoryList, setActiveCategoryListId, setActiveCategoryListItem } = categoryListSlice.actions;

const selectCategoryListState = ({ categoryList }: RootState): CategoryListSlice => categoryList;

export const selectCategoryList = createSelector(selectCategoryListState, ({ categoryList }) => categoryList);

export const selectFirstCategoryInList = createSelector(selectCategoryListState, ({ categoryList }) => categoryList[0]);

export const selectActiveCategoryId = createSelector(
    selectCategoryListState,
    ({ activeCategoryListId }) => activeCategoryListId,
);

export const selectActiveCategoryListById = createSelector(
    selectActiveCategoryId,
    selectCategoryList,
    (activeCategory: number, categoryList: CategoryList) => {
        return categoryList.find((category) => category.categoryId === activeCategory);
    },
);

export const selectActiveCategoryListItem = createSelector(
    selectCategoryListState,
    ({ activeCategoryListItem }) => activeCategoryListItem,
);

export default categoryListSlice.reducer;
