import React, { useEffect, useState } from 'react';
import { trimText } from '../../../utils/helpers';
import { Button } from '../../common';
import { useAppSelector } from '../../../hooks';

import { selectIsCheckoutPanelOpen } from '../../../slices/checkout-slice';

import './collapsible-text.scss';

interface CollapsibleTextProps {
    aboutText?: string;
    limit: number;
    text: string;
}

export const CollapsibleText: React.FC<CollapsibleTextProps> = ({ text, limit, aboutText }): React.ReactElement => {
    const [isExpanded, setIsExpanded] = useState(false);
    const content = isExpanded ? text : trimText(text, limit);
    const showButton = text.length > limit;
    const buttonTextAbout = aboutText ? `about ${aboutText}` : '';
    const buttonText = isExpanded ? `Read less ${buttonTextAbout}` : `Read more ${buttonTextAbout}`;
    const buttonIcon = isExpanded ? 'fas fa-caret-up' : 'fas fa-caret-down';

    const isCheckoutPanelOpen = useAppSelector(selectIsCheckoutPanelOpen);

    useEffect(() => {
        setIsExpanded(false);
    }, [text, aboutText, isCheckoutPanelOpen]);

    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="collapsible-text">
            <p data-testid="collapsible-text">{content}</p>
            {showButton && (
                <Button
                    isLink
                    ariaLabel={buttonText}
                    text={buttonText}
                    onClick={() => toggleExpanded()}
                    icon={buttonIcon}
                ></Button>
            )}
        </div>
    );
};
