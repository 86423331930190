import { FetchBaseQueryError, fetchBaseQuery } from '@reduxjs/toolkit/query';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { RootState } from '../../store';
import { SerializedError } from '@reduxjs/toolkit';
import Cookies from 'js-cookie';

// Typeguards.
//   Also called "type predicates": https://www.typescriptlang.org/docs/handbook/2/narrowing.html#using-type-predicates
export const isFetchBaseQueryError = (error: unknown): error is FetchBaseQueryError => {
    return typeof error === 'object' && error !== null && 'status' in error;
};

export const isSerializedError = (error: unknown): error is SerializedError => {
    return typeof error === 'object' && error !== null && 'name' in error;
};

export const baseQuery = fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
    prepareHeaders: async (headers, { getState }) => {
        const fingerprint = (await (await FingerprintJS.load()).get()).visitorId;

        headers.set('X-Fingerprint', fingerprint);

        const state = getState() as RootState;
        const token = state.verification.authTokens.accessTokenGlobal;

        if (token) {
            headers.set('X-User-Access-Token', token);
        }

        return headers;
    },
});

export const baseEmulatorQuery = fetchBaseQuery({
    baseUrl: process.env.BASE_URL,
});

export const basePortalQuery = fetchBaseQuery({
    baseUrl: process.env.ADMIN_SERVICES,
    credentials: 'include',
    prepareHeaders: async (headers) => {
        const fingerprint = (await (await FingerprintJS.load()).get()).visitorId;

        const auth0Cookie = Cookies.get('Auth0');
        if (auth0Cookie) {
            headers.set('Authorization', `Bearer ${auth0Cookie}`);
        }
        headers.set('X-Fingerprint', fingerprint);
        return headers;
    },
});
