import React, { useLayoutEffect } from 'react';
import { AppDispatch } from '../../../store';
import { useDispatch } from 'react-redux';
import {
    ApplicationModule,
    selectIsMobileLandscape,
    selectIsMobilePortrait,
    selectIsSmallIframeView,
    selectIsTabletPortrait,
    setAppModule,
    setIsMobileLandscape,
    setIsMobilePortrait,
    setIsSmallIframeView,
    setIsTabletPortrait,
} from '../../../slices/common-slice';
import { useAppSelector } from '../../../hooks';
import SDKMessenger from '../../../utils/services/sdk';
import { TrackingService } from '../../../utils/services/tracking';
import { useLocation } from 'react-router-dom';
import { getApplicationModule, windowResizeHandler } from '../../../utils';
import { resetSDKParams } from '../../../slices/sdk-slice';

export const ConsumerWindowListeners: React.FC = (): React.ReactElement => {
    const application = getApplicationModule();
    const dispatch = useDispatch<AppDispatch>();
    const isTabletPortraitView = useAppSelector(selectIsTabletPortrait);
    const isMobileLandscape = useAppSelector(selectIsMobileLandscape);
    const isMobilePortraitView = useAppSelector(selectIsMobilePortrait);
    const isSmallIframeView = useAppSelector(selectIsSmallIframeView);
    const sdkMessenger = SDKMessenger.getSDKMessengerService();
    const trackingService = TrackingService.getTrackingService();
    const location = useLocation();

    const windowResize = () => {
        windowResizeHandler(dispatch, isMobileLandscape, isMobilePortraitView, isSmallIframeView, isTabletPortraitView);
    };

    const determineHistoryOnBack = async () => {
        if (location.key) {
            await trackingService.trackBrowserBackClick(`${location.pathname}`, false);
            return true;
        } else {
            dispatch(resetSDKParams());
            await trackingService.trackBrowserBackClick(`${location.pathname}`, true);
            sdkMessenger.closePrizeout();
        }
    };

    window.addEventListener('resize', windowResize);

    window.addEventListener('touchmove', (e) => {
        e.preventDefault();
    });

    window.addEventListener('popstate', determineHistoryOnBack);

    useLayoutEffect(() => {
        const isTabletPortraitBreakpoint = window.innerWidth <= 834;
        const isMobileLandscapeBreakpoint = window.innerWidth <= 640;
        const isMobilePortrait = window.innerWidth <= 480;
        const isSmallIframeBreakpoint = window.innerWidth <= 330;

        dispatch(setIsTabletPortrait(isTabletPortraitBreakpoint));
        dispatch(setIsMobileLandscape(isMobileLandscapeBreakpoint));
        dispatch(setIsMobilePortrait(isMobilePortrait));
        dispatch(setIsSmallIframeView(isSmallIframeBreakpoint));
        dispatch(setAppModule(application as ApplicationModule));

        return () => {
            window.removeEventListener('resize', windowResize);
            window.removeEventListener('popstate', determineHistoryOnBack);
        };
    }, []);

    return <></>;
};
