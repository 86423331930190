import React from 'react';
import './truncated-cashback-balance-pill.scss';
import { truncatedCurrencyFormatter } from '../../../utils/helpers/truncated-currency-helper';
import { selectCashbackBalance, useGetCashbackWalletSummaryQuery } from '../../../slices/cashback';
import { useLocation } from 'react-router-dom';
import Classnames from 'classnames';
import { useAppSelector } from '../../../hooks';
import Skeleton from 'react-loading-skeleton';

export const TruncatedCashbackBalancePill: React.FC = (): React.ReactElement => {
    const { isLoading } = useGetCashbackWalletSummaryQuery();
    const balance = useAppSelector(selectCashbackBalance);
    const currentLocation = useLocation();
    const isSelected = currentLocation.pathname === '/cashback';

    const classes = Classnames('truncated-cashback-balance-pill', {
        'truncated-cashback-balance-pill--selected': isSelected,
    });

    if (isLoading) {
        return <Skeleton width={47} height={24} />;
    }

    return (
        <div className={classes}>
            <span className="truncated-cashback-balance-pill__amount">
                {truncatedCurrencyFormatter(balance, 'USD')}
            </span>
        </div>
    );
};
