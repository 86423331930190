import React from 'react';
import Classnames from 'classnames';
import { selectSweepstake } from '../../../slices/sweepstakes-slice';
import { useAppSelector } from '../../../hooks';
import { ExternalLink, ExternalLinkButtonSizes, ExternalLinkDisplayTypes } from '../../common';
import CurrencyFormatter from '../../common/ui-widgets/currency-formatter';
import { convertCentsToDollars, formatDate } from '../../../utils';
import './announcement-card.scss';

interface AnnouncementCardProps {
    className?: string;
    testId?: string;
    sweepstakesId: string;
}

export const AnnouncementCard: React.FC<AnnouncementCardProps> = ({
    className,
    testId = 'announcement-card',
    sweepstakesId,
}): React.ReactElement => {
    const sweepstake = useAppSelector(selectSweepstake(sweepstakesId));

    const classes = Classnames('announcement-card', className);

    return (
        <div className={classes} data-testid={testId}>
            <div className="announcement-card__inner">
                <div>
                    <img
                        src="https://assets.prizeout.com/widget/sweepstakes/sweepstakes-dots.png"
                        alt="Prizeout Sweepstakes"
                        className="announcement-card__dots"
                    />
                    <h1 className="announcement-card__title">
                        Prizeout
                        <br />
                        Sweepstakes
                    </h1>
                    <p className="announcement-card__rules">
                        {`${formatDate(sweepstake.startDateUTC)} to ${formatDate(sweepstake.endDateUTC)}`}
                    </p>
                    <p className="announcement-card__rules">
                        *Subject to{' '}
                        <ExternalLink
                            displayType={ExternalLinkDisplayTypes.SECONDARY_LINK}
                            ariaLabel="Sweepstakes rules"
                            label="rules"
                            url={`${sweepstake.termsUrl}`}
                        />
                        .
                    </p>
                </div>

                <div>
                    <div className="announcement-card__prize">
                        <p className="announcement-card__prize-text">Win up to</p>
                        <p className="announcement-card__prize-value">
                            <CurrencyFormatter
                                inline={true}
                                decimals={false}
                                value={parseFloat(convertCentsToDollars(parseInt(sweepstake.payout.value)))}
                            />
                        </p>
                        <p className="announcement-card__prize-text">in gift cards</p>
                    </div>
                    <ExternalLink
                        className="mt-s"
                        buttonSize={ExternalLinkButtonSizes.MINI}
                        displayType={ExternalLinkDisplayTypes.SECONDARY_BUTTON}
                        ariaLabel="Learn more"
                        label="Learn more"
                        testId="announcement-learn-more-button"
                        url={sweepstake.landingPageUrl}
                    />
                </div>
            </div>
        </div>
    );
};
