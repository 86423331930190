import { PayloadAction, createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from '../../store';
import { ConsumerReward } from './rewards-types';

export interface RewardsCommonState {
    currentConsumerReward: ConsumerReward;
    isLoading: boolean;
}

export const rewardsCommonInitialState: RewardsCommonState = {
    currentConsumerReward: {} as ConsumerReward,
    isLoading: false,
};

export const rewardsCommonSlice = createSlice({
    initialState: rewardsCommonInitialState,
    name: 'rewardsCommon',
    reducers: {
        setCurrentConsumerReward(state, action: PayloadAction<ConsumerReward>) {
            state.currentConsumerReward = action.payload;
        },
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload;
        },
    },
});

const selectRewardsCommonState = ({ rewardsCommon }: RootState): RewardsCommonState => rewardsCommon;

export const selectIsLoading = createSelector(selectRewardsCommonState, (rewardsCommon) => rewardsCommon.isLoading);

export const { setCurrentConsumerReward, setIsLoading } = rewardsCommonSlice.actions;

export default rewardsCommonSlice.reducer;
