import React, { useState, useEffect, useRef } from 'react';
import Classnames from 'classnames';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { selectUser, setSignUpEmail } from '../../../slices/user-slice';
import { SignUpResponse, signUpUser } from '../../../slices/verification-slice';
import { AppDispatch } from '../../../store';
import ReCAPTCHA from 'react-google-recaptcha-enterprise';
import isEmail from 'validator/lib/isEmail';
import { Checkbox, FormHeader } from '../form-components';
import { Button, ButtonColor, ButtonSize, ButtonType } from '../buttons';
import { AlertCallout, AlertCalloutMessageTypes } from '../ui-widgets';
import { useAppSelector, useGoToRoute } from '../../../hooks';
import { ButtonTrackingTypes } from '../../../utils/services/tracking';
import { routes } from '../../../utils/helpers/routing-helpers';
import { requireWithMessage, validateForEmptyString } from '../../../utils/helpers';
import { useRefAssigner } from '../../../hooks/use-ref-assigner';
import { EmailInput } from '../ui-inputs/email-input';

import './sign-up-form.scss';

interface SignUpFormProps {
    logInOverride?: () => void;
    onSuccess: (response: SignUpResponse) => void;
}
export interface SignUpRequest {
    email: string;
    firstName: string;
    lastName: string;
    optIn: boolean;
    password: string;
    recaptchaToken: string;
}

export const SignUpForm: React.FC<SignUpFormProps> = ({ logInOverride, onSuccess }): React.ReactElement => {
    const dispatch = useDispatch<AppDispatch>();
    const { goToRoute } = useGoToRoute();

    const [isErrMsg, setIsErrMsg] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [recaptchaToken, setRecaptchaToken] = useState('');

    const userData = useAppSelector(selectUser);

    const recaptchaResponse = (token: string) => {
        setRecaptchaToken(token);
        //Handle case where captcha error would not disappear after setting token
        errors['recaptchaToken'] && trigger();
    };

    const routeToLogin = () => {
        if (logInOverride) {
            logInOverride();
            return;
        }

        goToRoute(routes.DTC_HOME);
    };

    const {
        register,
        setError,
        trigger,
        handleSubmit,
        formState: { errors },
        reset,
        // watch,
    } = useForm<SignUpRequest>({ mode: 'onChange' });

    const recaptchaRef = useRef(null);
    const recaptchaRegister = register('recaptchaToken', {
        validate: {
            required: () => {
                return recaptchaToken?.length > 0 || 'Captcha required';
            },
        },
    });

    const refAssigner = useRefAssigner(recaptchaRegister.ref, recaptchaRef);

    const onSubmit: SubmitHandler<SignUpRequest> = async (data) => {
        setMessage('');
        const { email, firstName, lastName, optIn, password } = data;

        try {
            setIsLoading(true);
            const response = (await dispatch(
                signUpUser({
                    email: !!userData?.email ? userData.email : email,
                    firstName,
                    lastName,
                    optIn,
                    password,
                    recaptchaToken,
                }),
            ).unwrap()) as SignUpResponse;

            setIsErrMsg(false);
            dispatch(setSignUpEmail(email));
            setMessage('');
            await onSuccess(response);
        } catch (e) {
            recaptchaRef.current.reset();
            setIsErrMsg(true);
            setMessage(e.message);
            setIsLoading(false);
        }
    };

    const determineClasses = (inputName: string) => {
        const inputErrorExists = errors[inputName as keyof typeof errors];

        return Classnames(`form__input-wrapper`, { 'form__input-wrapper--error': inputErrorExists });
    };

    // const showEmailMessage = () => {
    //     // It's possible for userData.email to exist but be null or undefined.  Don't
    //     // show the warning in that case.
    //     return Object.keys(userData || {}).length != 0 && userData?.email != emailValue;
    // };

    // const emailValue = watch('email');
    const errorsArray = Object.keys(errors);

    useEffect(() => {
        const errorsExists = errorsArray.length > 0 || message.length > 0;
        setIsErrMsg(errorsExists);
    }, [errorsArray]);

    useEffect(() => {
        reset();
    }, []);

    const handleInvalidEmail = (evt: React.InvalidEvent<HTMLInputElement>) => {
        if (evt.target.validationMessage) {
            setError('email', { message: 'Email address required' });
        }
    };

    return (
        <>
            <FormHeader
                formName="signup-modal"
                headline="Create a Prizeout account"
                paragraph="You'll get even more for the money you withdraw on our partner platforms."
            />

            {isErrMsg && message.length > 0 && (
                <AlertCallout message={message} type={AlertCalloutMessageTypes.ERROR} className="mb-m" />
            )}

            {/*

            NOTE: Temporarily disabling this message until we determine when we allow users to actually change their email
            right now we are still forcing them to use w/e we get from the partner if we get something.

            {showEmailMessage() && (
                <AlertCallout
                    className="mb-m"
                    testId="email-diff-warning"
                    message="For account security, using a different email will require additional verification steps."
                    type={AlertCalloutMessageTypes.WARNING}
                />
            )} */}

            <form className="form" onSubmit={handleSubmit(onSubmit)} noValidate>
                <div className={determineClasses('firstName')}>
                    <input
                        {...register('firstName', requireWithMessage(validateForEmptyString, 'First name required'))}
                        aria-required="true"
                        type="text"
                        name="firstName"
                        id="firstName"
                        placeholder="First name"
                        className="form__input"
                        defaultValue={userData?.firstName}
                        autoComplete="given-name"
                    />
                    <label className="form__label" htmlFor="firstName">
                        First name
                    </label>
                </div>
                {errors['firstName'] && (
                    <AlertCallout message={errors['firstName'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <div className={determineClasses('lastName')}>
                    <input
                        {...register('lastName', requireWithMessage(validateForEmptyString, 'Last name required'))}
                        aria-required="true"
                        type="text"
                        name="lastName"
                        id="lastName"
                        placeholder="Last name"
                        className="form__input"
                        defaultValue={userData?.lastName}
                        autoComplete="family-name"
                    />
                    <label className="form__label" htmlFor="lastName">
                        Last name
                    </label>
                </div>
                {errors['lastName'] && (
                    <AlertCallout message={errors['lastName'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <div className={determineClasses('email')}>
                    <EmailInput
                        {...register('email', {
                            required: {
                                message: 'Email address required',
                                value: true,
                            },
                            validate: {
                                empty: (email) => !!email.trim() || 'Email address required',
                                format: (email) => isEmail(email) || 'Invalid email format',
                            },
                        })}
                        id="email-sign-up"
                        name="email"
                        placeholder="Email address"
                        className="form__input"
                        required={true}
                        autoComplete="off"
                        readOnly={!!userData?.email}
                        defaultValue={userData?.email}
                        onInvalid={handleInvalidEmail}
                        aria-required="true"
                    />
                    <label className="form__label" htmlFor="email-sign-up">
                        Email address
                    </label>
                </div>
                {errors['email'] && (
                    <AlertCallout message={errors['email'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <div className={determineClasses('password')}>
                    <input
                        {...register('password', {
                            minLength: {
                                message: 'Password must be 8 characters long',
                                value: 8,
                            },
                            required: {
                                message: 'Password required',
                                value: true,
                            },
                            validate: validateForEmptyString,
                        })}
                        aria-required="true"
                        autoComplete="new-password"
                        type="password"
                        name="password"
                        id="password"
                        placeholder="Create a new password"
                        className="form__input"
                    />
                    <label className="form__label" htmlFor="password">
                        Create a new password
                    </label>
                </div>
                {errors['password'] && (
                    <AlertCallout message={errors['password'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <small className="mb-xs">
                    Already have an account?{' '}
                    <Button ariaLabel="Log in" isLink text="Log in" onClick={() => routeToLogin()} />
                </small>
                <ReCAPTCHA
                    {...recaptchaRegister}
                    ref={refAssigner}
                    name="recaptcha"
                    sitekey={process.env.RECAPTCHA_SITE_KEY || '6LfauLQcAAAAAJUZT_N9b7jJEFdjcBBlvjZ5ylse'}
                    onChange={recaptchaResponse}
                    className="mb-xs"
                />
                {errors['recaptchaToken'] && (
                    <AlertCallout message={errors['recaptchaToken'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <Checkbox
                    register={{
                        ...register('optIn', {
                            required: {
                                message: 'Opt-in required',
                                value: true,
                            },
                        }),
                    }}
                    hasLegalTerms={true}
                    id="opt-in"
                    isRequired
                    title="Opt-In"
                />
                {errors['optIn'] && (
                    <AlertCallout message={errors['optIn'].message} type={AlertCalloutMessageTypes.ERROR} />
                )}
                <div className="form__button-wrapper">
                    <Button
                        ariaLabel="Create account"
                        color={ButtonColor.PRIMARY}
                        size={ButtonSize.MEDIUM}
                        text="Create account"
                        type={ButtonType.SUBMIT}
                        isLoading={isLoading}
                        trackingType={ButtonTrackingTypes.BUTTON_CLICK_SIGN_UP}
                    ></Button>
                </div>
            </form>
        </>
    );
};
