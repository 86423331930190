import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { sharedAPI } from '../utils/services/api';

export interface GroupFilterListSlice {
    groupFilterList: GroupFilterListItem[];
    activeGroupFilterListItem?: GroupFilterListItem;
    activeGroupFilterListId?: number;
}

export interface GroupFilterListItem {
    groupId: number;
    groupName: string;
    groupImageUrl?: string;
    groupDescription?: string;
}

export type GroupFilterList = GroupFilterListItem[];

export const groupFilterListInitialState: GroupFilterListSlice = {
    groupFilterList: [],
    activeGroupFilterListItem: null,
};

export type GroupFilterListRequest = {
    location: string;
    prizeoutSessionId: string;
};

export const getGroupFilterList = createAsyncThunk(
    'offers/getGroupFilterList',
    async ({ location, prizeoutSessionId }: GroupFilterListRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    countryCode: location,
                    prizeout_session_id: prizeoutSessionId,
                },
                method: 'POST',
                endpoint: '/groupCarousel',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.error);
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const groupFilterListSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getGroupFilterList.fulfilled, (state, action: PayloadAction<GroupFilterList>) => {
            const arr = [];

            for (const i in action.payload) {
                arr.push(action.payload[i]);
            }

            state.groupFilterList = arr;
        });
    },
    initialState: groupFilterListInitialState,
    name: 'groupFilterList',
    reducers: {
        setActiveGroupFilterListItem(state, action: PayloadAction<GroupFilterListItem>) {
            state.activeGroupFilterListItem = action.payload;
            state.activeGroupFilterListId = action.payload?.groupId;
        },
        setActiveGroupFilterListId(state, action: PayloadAction<number>) {
            state.activeGroupFilterListId = action.payload;
            state.activeGroupFilterListItem = state.groupFilterList.find((group) => group.groupId === action.payload);
        },
        setGroupFilterList(state, action: PayloadAction<GroupFilterList>) {
            state.groupFilterList = action.payload;
        },
    },
});

export const { setGroupFilterList, setActiveGroupFilterListId, setActiveGroupFilterListItem } =
    groupFilterListSlice.actions;

const selectGroupFilterListState = ({ groupFilterList }: RootState): GroupFilterListSlice => groupFilterList;

export const selectGroupFilterList = createSelector(
    selectGroupFilterListState,
    ({ groupFilterList }) => groupFilterList,
);

export const selectActiveGroupId = createSelector(
    selectGroupFilterListState,
    ({ activeGroupFilterListId }) => activeGroupFilterListId,
);

export const selectActiveGroupFilterListById = createSelector(
    selectActiveGroupId,
    selectGroupFilterList,
    (activeGroup: number, groupFilterList: GroupFilterList) => {
        return groupFilterList.find((group) => group.groupId === activeGroup);
    },
);

export const selectActiveGroupFilterListItem = createSelector(
    selectGroupFilterListState,
    ({ activeGroupFilterListItem }) => activeGroupFilterListItem,
);

export default groupFilterListSlice.reducer;
