import React from 'react';
import { useAppSelector } from '../../../hooks';
import { GiftingImageWithHeading } from '../../../modules/widget/submodules/gifting/components/common/gifting-image-with-heading';
import { selectPartnerProgramName } from '../../../slices/partner-config-slice';
import { PoweredByPO } from './powered-by-po';

import './powered-by-po-header.scss';

export const PoweredByPOHeader: React.FC = (): React.ReactElement => {
    const partnerProgramName = useAppSelector(selectPartnerProgramName);
    const headerText = partnerProgramName ? partnerProgramName : '';

    return (
        <article data-testid="powered-by-po-header" className="powered-by-po-header">
            <GiftingImageWithHeading text={headerText} />
            <PoweredByPO />
        </article>
    );
};
