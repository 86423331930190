import React from 'react';
import { useAppSelector } from '../../../hooks';
import { selectPartnerId } from '../../../slices/sdk-slice';
import { constants } from '../../../utils/constants';
import { RoundImage } from './round-image';
import { RoundImageSizes } from '../../../slices/common-slice';
import { selectCobrandedPartnerLogomark, selectPartnerName } from '../../../slices/partner-config-slice';
import { isProduction } from '../../../utils/helpers/environment';

interface CobrandedImageProps {
    isPartner?: boolean;
    size: RoundImageSizes;
    testId?: string;
}

export const CobrandedImage: React.FC<CobrandedImageProps> = React.memo(
    ({ isPartner, size, testId = 'cobranded-image' }): React.ReactElement => {
        const cobrandedPartnerLogomark = useAppSelector(selectCobrandedPartnerLogomark);
        const partnerName = useAppSelector(selectPartnerName);

        let partnerId = '';

        const urlParams = new URLSearchParams(window.location.search);

        partnerId = urlParams.get('partnerId');

        if (!partnerId) {
            partnerId = useAppSelector(selectPartnerId);
        }

        let envString = '';

        if (!isProduction()) {
            envString = `${process.env.ENV}.`;
        }

        if (process.env.PARTNER_SDK_ZONE) {
            envString = envString + `${process.env.PARTNER_SDK_ZONE}`;
        }

        const partnerImageUrl = cobrandedPartnerLogomark
            ? cobrandedPartnerLogomark
            : `https://${envString}static.prizeout.com/partner-creative/active-logomark/${partnerId}/logomark.png`;

        const imageUrl = isPartner ? partnerImageUrl : constants.prizeoutRoundLogomarkUrl;

        const altText = isPartner ? `${partnerName} logo` : 'Prizeout logo';

        return (
            <RoundImage
                imageUrl={imageUrl || constants.defaultRoundImageUrl}
                imageAltText={altText}
                testId={testId}
                size={size}
            />
        );
    },
);
