import React from 'react';
import { SidebarArrow } from '../../common';

import './sidebar-header.scss';

export interface SidebarHeaderProps {
    onClick: () => void;
    title: string;
    testId?: string;
}

export const SidebarHeader: React.FC<SidebarHeaderProps> = ({
    onClick,
    title,
    testId = 'sidebar-header-heading',
}): React.ReactElement => {
    return (
        <>
            <header className="sidebar-header">
                <SidebarArrow onClick={onClick} />
                <h2 data-testid={testId}>{title}</h2>
            </header>
        </>
    );
};
