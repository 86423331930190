import { useDispatch } from 'react-redux';

import { AppDispatch } from '../store';
import { asyncRetry } from '../utils/helpers';
import { getPartnerConfig, PartnerConfigState } from '../slices/partner-config-slice';
import { ApplicationModule } from '../slices/common-slice';
import { SDKParams } from '../slices/sdk-slice';
import { AuctionParams } from '../slices/store-front-slice';

export function useLoadPartnerConfig() {
    const dispatch = useDispatch<AppDispatch>();

    const loadPartnerConfig = async (
        sdkSettings: SDKParams | AuctionParams,
        applicationModule: ApplicationModule = ApplicationModule.WIDGET,
    ) => {
        try {
            const results = (await asyncRetry(() =>
                dispatch(
                    getPartnerConfig({
                        sdk_settings: sdkSettings,
                        applicationModule,
                    }),
                ).unwrap(),
            )) as PartnerConfigState;

            return results;
        } catch {
            throw 'Sorry, we failed to load';
        }
    };

    return [loadPartnerConfig];
}

export default useLoadPartnerConfig;
