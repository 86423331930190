import { PromiseControl, getPromiseWithResolvers } from '../../../../../../utils/helpers/promise-helpers';

type DomHelperEventHandler = (evt: Event) => void | boolean;
type EventClass = new (
    arg0: string,
    arg1: { bubbles: boolean; cancelable: boolean; view: Window & typeof globalThis },
) => Event;
export interface DOMListenerResult {
    element: Element | Document;
    timeout: boolean;
    evtName?: string;
}

// Some sites listen on change, some on input.  It wouldn't be a surprise if some listened on both.
// Or neither. I doubt that the order matters here, but it feels better to do change first - MRiehle
const INPUT_EVENT_NAME_LIST = ['change', 'input'];

const EVENT_OPTIONS = {
    bubbles: true,
    cancelable: true,
    view: window,
};

const MAX_WAIT_RETRIES = 10;
const ELEMENT_MUTATION_TIMEOUT = 5000; //250; // 250 milliseconds
const WAIT_FOR_CHANGE_TIMEOUT = 2 * 1000; // two seconds
const WAIT_FOR_ELEMENT_TIMEOUT = 250; // 250 milliseconds

const sendEvent = (element: HTMLElement | Document, eventClass: EventClass, eventName: string): void => {
    const evt = new eventClass(eventName, EVENT_OPTIONS);
    element.dispatchEvent(evt);
};

export const listenOnFirstChangeEvent = (
    input: HTMLInputElement | HTMLTextAreaElement,
    changeHandler?: DomHelperEventHandler,
    waitTimeout = WAIT_FOR_CHANGE_TIMEOUT,
): Promise<DOMListenerResult> => {
    const response: PromiseControl = getPromiseWithResolvers();

    const handler = (evt: Event) => {
        response.resolve(evt);
    };

    INPUT_EVENT_NAME_LIST.forEach((evtName) => {
        input.addEventListener(evtName, handler);
    });

    const timeoutHandle = setTimeout(() => {
        response.reject('Timed out');
    }, waitTimeout);

    return response.promise
        .then((evt) => {
            clearTimeout(timeoutHandle);
            changeHandler?.call(null, evt);
            return Promise.resolve({
                element: input,
                timeout: false,
                evtName: evt.type,
            });
        })
        .catch((e) => {
            const message = e.toString();

            if (message === 'Timed out') {
                return Promise.resolve({
                    element: input,
                    timeout: true,
                });
            }

            return Promise.reject(e);
        })
        .finally(() => {
            INPUT_EVENT_NAME_LIST.forEach((evtName) => {
                input.removeEventListener(evtName, handler);
            });
        });
};

export const listenForMutation = (
    element: Element | Document,
    waitTimeout = ELEMENT_MUTATION_TIMEOUT,
): Promise<DOMListenerResult> => {
    if (element === document) {
        return Promise.resolve({
            element,
            timeout: false,
        });
    }

    const response: PromiseControl = getPromiseWithResolvers();

    const handler = () => {
        response.resolve('Mutated');
    };

    const observer = new MutationObserver(handler);
    observer.observe(element, {
        subtree: true,
        attributes: true,
        childList: true,
        characterData: true,
    });

    const timeoutHandle = setTimeout(() => {
        response.reject('Timed out');
    }, waitTimeout);

    return response.promise
        .then(() => {
            clearTimeout(timeoutHandle);
            return Promise.resolve({
                element,
                timeout: false,
            });
        })
        .catch(() => {
            return Promise.resolve({
                element,
                timeout: true,
            });
        });
};

export const clickElement = (element: HTMLElement | Document): Promise<Event[]> => {
    const promises = ['click', 'focus'].map((evtName) => {
        return new Promise<Event>((resolve): void => {
            element.addEventListener(
                evtName,
                (evt: Event) => {
                    resolve(evt);
                },
                { once: true }, // Don't keep it around.
            );
        });
    });

    sendEvent(element, MouseEvent, 'click');
    sendEvent(element, FocusEvent, 'focus');

    return Promise.all(promises);
};

export const changeInput = (
    input: HTMLInputElement | HTMLTextAreaElement,
    value: string,
    changeHandler?: DomHelperEventHandler,
): Promise<void> => {
    sendEvent(input, FocusEvent, 'focus');

    const promises = INPUT_EVENT_NAME_LIST.map((evtName) => {
        return new Promise<Event>((resolve, reject): void => {
            input.addEventListener(
                evtName,
                (evt: Event) => {
                    if (input.value === value) {
                        resolve(evt);
                    } else {
                        reject(`${evtName}: Input not updated`);
                    }
                },
                { once: true }, // Don't keep it around.
            );
        });
    });

    INPUT_EVENT_NAME_LIST.forEach((evtName: string) => {
        input.value = value;
        sendEvent(input, InputEvent, evtName);
    });

    // For some reason, Typescript thinks that `Promise.all()` returns an array of promises.
    // Using a `then` works around that problem.
    return Promise.all(promises).then((evts: Event[]) => {
        evts.forEach((evt) => {
            if (evt.type === 'change') {
                changeHandler?.call(null, evt);
            }
        });

        sendEvent(input, FocusEvent, 'focusout');
        sendEvent(input, FocusEvent, 'blur');
    });
};

export const elementIsVisible = (element: HTMLElement): boolean => {
    if (!element) {
        return false;
    }

    // This most certainly does exist, but the Typescript we build with doesn't like it.
    // if (element.checkVisibility) {
    //     return element.checkVisibility();
    // }

    if (element.style.visibility === 'hidden' || element.style.display === 'none') {
        return false;
    }

    return !!(element.offsetWidth || element.offsetHeight || element.getClientRects().length);
};

// The only place this is used is in DOM Helpers.  If that changes, we'll probably
// want to move this.
export const delayedPromise = (delayMilliseconds: number): Promise<void> => {
    return new Promise<void>((resolve) => {
        setTimeout(() => {
            resolve();
        }, delayMilliseconds);
    });
};

export const waitForElement = async (
    finder: () => HTMLElement,
    waitTimeout = WAIT_FOR_ELEMENT_TIMEOUT,
    waitRetries = MAX_WAIT_RETRIES,
): Promise<HTMLElement> => {
    let result: HTMLElement;
    let retries = 0;

    do {
        result = finder();

        if (result && elementIsVisible(result)) {
            break;
        }

        retries++;

        if (retries <= waitRetries) {
            await delayedPromise(waitTimeout);
        }

        result = null;
    } while (retries <= waitRetries);

    return result;
};

export const isElementValid = (targetElement: HTMLInputElement | HTMLTextAreaElement, targetValue: string): boolean => {
    if (targetElement.checkValidity()) {
        if (targetElement.value === targetValue) {
            return true;
        } else {
            const strippedValue = targetElement.value.replace(/[-_\s\.]*/g, '');

            return strippedValue === targetValue;
        }
    }

    return false;
};

export const watchElementForValidity = async (
    targetElement: HTMLInputElement | HTMLTextAreaElement,
    targetValue: string,
): Promise<boolean> => {
    if (!isElementValid(targetElement, targetValue)) {
        return false;
    }

    await listenOnFirstChangeEvent(targetElement);
    return isElementValid(targetElement, targetValue);
};
