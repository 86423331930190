import React from 'react';
import Classnames from 'classnames';
import { useAppSelector } from '../../../hooks';
import SDKMessenger from '../../../utils/services/sdk';
import { selectIsExternalLinkingEnabled } from '../../../slices/sdk-slice';

import './external-link.scss';

export enum ExternalLinkDisplayTypes {
    PRIMARY_BUTTON = 'primary',
    SECONDARY_BUTTON = 'secondary',
    TERTIARY_BUTTON = 'tertiary',
    CUSTOM = 'custom',
    LINK = 'link',
    SECONDARY_LINK = 'secondary-link',
}

export enum ExternalLinkButtonSizes {
    MINI = 'mini',
    SMALL = 'small',
    MEDIUM = 'medium',
}

interface ExternalLinkProps {
    ariaLabel?: string; // This will replace the text label in screen readers
    buttonSize?: ExternalLinkButtonSizes;
    className?: string;
    displayType: ExternalLinkDisplayTypes;
    isButtonInline?: boolean;
    label?: string | React.ReactNode;
    onClick?: () => void;
    testId?: string;
    url: string;
}

export const appendPrefix = (url: string) => {
    if (url == null || !url?.trim()) {
        return null;
    }

    const hasMatch = url.match(/^\w+:\/\//);
    return hasMatch === null ? `http://${url}` : url;
};

export const ExternalLink: React.FC<ExternalLinkProps> = ({
    ariaLabel,
    buttonSize = ExternalLinkButtonSizes.MEDIUM,
    children,
    className,
    displayType,
    isButtonInline,
    label,
    onClick,
    testId = 'external-link',
    url,
}): React.ReactElement => {
    const isExternalLinkingEnabled = useAppSelector(selectIsExternalLinkingEnabled);
    const sdkMessenger = SDKMessenger.getSDKMessengerService();
    const shouldShowButton =
        displayType === ExternalLinkDisplayTypes.PRIMARY_BUTTON ||
        displayType === ExternalLinkDisplayTypes.SECONDARY_BUTTON ||
        displayType === ExternalLinkDisplayTypes.TERTIARY_BUTTON;

    const showCustomDisplay = displayType === ExternalLinkDisplayTypes.CUSTOM;

    const classes = Classnames(
        'external-link',
        {
            'external-link--button button': shouldShowButton,
            'button--inline': isButtonInline,
            [`button--${displayType}`]: shouldShowButton && displayType,
            [`button--${buttonSize}`]: shouldShowButton && buttonSize,
            [`${displayType}`]: displayType === ExternalLinkDisplayTypes.SECONDARY_LINK,
        },
        className,
    );

    const onClickHandler = () => {
        if (isExternalLinkingEnabled) {
            sdkMessenger.dispatchExternalURL(appendPrefix(url));
        }

        if (!!onClick) {
            onClick();
        }
    };

    return (
        <a
            aria-label={`${ariaLabel} (opens in a new tab)`}
            className={classes}
            data-testid={testId}
            href={appendPrefix(url)}
            onClick={onClickHandler}
            rel="noreferrer"
            target="_blank"
        >
            {!showCustomDisplay && (
                <>
                    {shouldShowButton && <i className="fa-kit fa-prizeout-external-link"></i>}
                    {label}
                </>
            )}

            {showCustomDisplay && <>{children}</>}
        </a>
    );
};
