import { createApi } from '@reduxjs/toolkit/query/react';
import { WalletSummaryResponse } from '../partner-config-slice';
import { baseQuery } from '../common';
import { CashbackWalletSummaryResponse } from './cashback-slice';
import { CashbackEventGroups } from './cashback-types';

export const cashbackApiSlice = createApi({
    reducerPath: 'cashbackApi',
    baseQuery,
    tagTypes: [CashbackEventGroups.CASHBACK_SUMMARY_DATA],
    endpoints: (builder) => ({
        getCashbackWalletSummary: builder.query<CashbackWalletSummaryResponse, void>({
            query: () => '/cashback/wallet/balance',
            providesTags: [CashbackEventGroups.CASHBACK_SUMMARY_DATA],
        }),
        getWalletSummary: builder.query<WalletSummaryResponse, void>({
            query: () => '/wallet/summary',
            providesTags: [CashbackEventGroups.CASHBACK_SUMMARY_DATA],
        }),
    }),
});

export const {
    useLazyGetWalletSummaryQuery,
    useLazyGetCashbackWalletSummaryQuery,
    useGetCashbackWalletSummaryQuery,
    useGetWalletSummaryQuery,
} = cashbackApiSlice;
