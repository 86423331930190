import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAppSelector, useGoToRoute } from '../../../hooks';
import {
    getOffers,
    selectOffersCurrentLocation,
    setOfferLocation,
    clearActiveOfferFilters,
    getCategoryOffers,
} from '../../../slices/offers-slice';
import {
    selectCountryFilter,
    selectIsCategorySelectionEnabled,
    selectPrizeoutUserSession,
} from '../../../slices/partner-config-slice';
import { setCheckoutPanelClosed } from '../../../slices/checkout-slice';
import {
    SidebarViewEnum,
    clearGiftcardListCache,
    selectSidebarIsOpen,
    setSidebarView,
    toggleIsSidebarOpen,
} from '../../../slices/sidebar-slice';
import { AppDispatch } from '../../../store';
import { constants } from '../../../utils/constants';
import {
    selectIsLocationSelectorOpen,
    selectIsMobilePortrait,
    toggleIsLoading,
    toggleIsLocationSelectorOpen,
} from '../../../slices/common-slice';
import { GenericModal } from '../modals';
import { PillsListLayout } from '../ui-regions/common/';
import { Button } from '../buttons';
import { Pill } from '../search';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { asyncRetry } from '../../../utils/helpers';
import { getCategoryList } from '../../../slices/category-list-slice';
import { routes } from '../../../utils/helpers/routing-helpers';
import { TrackingService } from '../../../utils/services/tracking';
import './location-selector.scss';

export const LocationSelector: React.FC = (): React.ReactElement => {
    const availableLocations = useAppSelector(selectCountryFilter);
    const isMobilePortraitView = useAppSelector(selectIsMobilePortrait);
    const offerCurrentLocation = useAppSelector(selectOffersCurrentLocation);
    const prizeoutSessionId = useAppSelector(selectPrizeoutUserSession);
    const isLocationSelectorOpen = useAppSelector(selectIsLocationSelectorOpen);
    const [selectedLocation, setSelectedLocation] = useState(offerCurrentLocation);
    const isSidebarOpen = useAppSelector(selectSidebarIsOpen);
    const currentLocation = useLocation();
    const trackingService = TrackingService.getTrackingService();
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const isCategorySelectionEnabled = useAppSelector(selectIsCategorySelectionEnabled);
    const { activeCategoryId }: any = useParams();
    const { goToRoute } = useGoToRoute();

    useEffect(() => {
        if (selectedLocation != offerCurrentLocation) {
            setSelectedLocation(offerCurrentLocation);
        }
    }, [offerCurrentLocation]);

    const resetPanels = () => {
        if (isSidebarOpen) {
            dispatch(toggleIsSidebarOpen());
            dispatch(setSidebarView(SidebarViewEnum.DEFAULT));
        }

        dispatch(setCheckoutPanelClosed());
    };

    const triggerDataLoading = async () => {
        resetPanels();
        dispatch(toggleIsLoading());
        dispatch(clearGiftcardListCache());
        dispatch(setOfferLocation(selectedLocation));
        dispatch(toggleIsLocationSelectorOpen());

        const combinedPromises = [];

        combinedPromises.push(
            asyncRetry(() =>
                dispatch(
                    getOffers({
                        prizeoutSessionId: prizeoutSessionId,
                        location: selectedLocation,
                    }),
                ).unwrap(),
            ),
        );

        if (isCategorySelectionEnabled) {
            combinedPromises.push(
                asyncRetry(() =>
                    dispatch(
                        getCategoryList({
                            prizeoutSessionId: prizeoutSessionId,
                            location: selectedLocation,
                        }),
                    ).unwrap(),
                ),
            );

            if (activeCategoryId) {
                navigate(`/dashboard/category/${activeCategoryId}/${selectedLocation}`);

                combinedPromises.push(
                    asyncRetry(() =>
                        dispatch(
                            getCategoryOffers({
                                categoryId: Number(activeCategoryId),
                                prizeoutSessionId: prizeoutSessionId,
                                location: selectedLocation,
                            }),
                        ).unwrap(),
                    ),
                );
            }
        }

        await Promise.all(combinedPromises)
            .then(() => {
                if (currentLocation.pathname !== routes.HOME) {
                    goToRoute(routes.HOME);
                }
            })
            .catch(() => {
                trackingService.trackSelectedEmptyOffers(selectedLocation);
                goToRoute(routes.EMPTY_OFFERS);
            });

        dispatch(clearActiveOfferFilters());
    };

    const setLocationDataAndRefreshOffers = async () => {
        try {
            await triggerDataLoading();
        } catch (e) {
            // note: this is an actual failure, but throwing the error
            // state for a preload failure will result in PO closing
            // for mulitple locations, on a failure, we can at least
            // note there are no offers and they can pick a new location
            // note: async retry in place to retry offer loading 5x
            goToRoute(routes.EMPTY_OFFERS);
        } finally {
            dispatch(toggleIsLoading());
        }
    };

    const openLocationSelector = () => {
        dispatch(toggleIsLocationSelectorOpen());
    };

    const displayCurrentLocationName = () => {
        if (isMobilePortraitView) {
            return offerCurrentLocation;
        } else {
            return constants.countries.LocationsKeyedByCountryCode[offerCurrentLocation];
        }
    };

    const closeAndResetLocationSelectorModal = () => {
        setSelectedLocation(offerCurrentLocation);
        dispatch(toggleIsLocationSelectorOpen());
    };

    if (availableLocations.length <= 1) {
        return <></>;
    }

    return (
        <>
            <div className="location-selector">
                <Button
                    ariaLabel="Location selector"
                    isElement
                    onClick={() => openLocationSelector()}
                    testId="location-selector-button"
                >
                    <span>{constants.countries.FlagsKeyedByCountryCode[offerCurrentLocation]}</span>
                    <strong>{displayCurrentLocationName()}</strong>
                    <i className="fas fa-angle-down"></i>
                </Button>
            </div>

            <GenericModal
                ariaLabelledById="offer-filter"
                ariaLabel="Filter offers from location"
                hasExitButton={true}
                hasIcon={true}
                iconClasses="fal fa-globe"
                isOpen={isLocationSelectorOpen}
                onClose={() => closeAndResetLocationSelectorModal()}
                testId="location-selector-modal"
            >
                <PillsListLayout
                    ariaLabelledById="offer-filter"
                    headline="Select location"
                    primaryOnClick={() => setLocationDataAndRefreshOffers()}
                    primaryTestId="location-selector-done"
                >
                    {selectedLocation &&
                        availableLocations.map((location: string) => (
                            <Pill
                                onClick={() => setSelectedLocation(location)}
                                isActive={location == selectedLocation}
                                key={location}
                                name={constants.countries.LocationsKeyedByCountryCode[location]}
                                testId={`${location}-location-pill`}
                                unicode={constants.countries.FlagsKeyedByCountryCode[location]}
                            />
                        ))}
                </PillsListLayout>
            </GenericModal>
        </>
    );
};
