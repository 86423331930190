import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { sharedAPI } from '../utils/services/api';

export interface SweepstakesSlice {
    sweepstakes: Sweepstake[];
    activeSweepstake: string;
    entryModalHasBeenDisplayed: boolean;
}

export interface Sweepstake {
    sweepstakesId: string;
    termsUrl: string;
    landingPageUrl: string;
    startDateUTC: string;
    endDateUTC: string;
    payout: {
        value: string;
    };
    eligibility: {
        eligibilityType: string;
        minDenomCents: number;
        maxDenomCents: number;
        maxEntriesPerDay: number;
    };
    entries: {
        totalUserEntries: number;
        totalActiveUserEntries: number;
        totalPendingUserEntries: number;
    };
}

export const sweepstakesInitialState: SweepstakesSlice = {
    sweepstakes: [],
    activeSweepstake: null,
    entryModalHasBeenDisplayed: false,
};

export type SweepstakesRequest = {
    id: string[];
};

export const getSweepstakes = createAsyncThunk(
    '/sweepstakes',
    async ({ id }: SweepstakesRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    id,
                },
                endpoint: '/sweepstakes',
                method: 'GET',
                signal: signal,
            });

            if (results?.data.error) {
                return rejectWithValue(results.data.error);
            }

            return {
                ...results?.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const sweepstakesSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getSweepstakes.fulfilled, (state, action: PayloadAction<Sweepstake[]>) => {
            for (const i in action.payload) {
                if (
                    state.sweepstakes.filter((listItem) => listItem.sweepstakesId === action.payload[i].sweepstakesId)
                        .length == 0
                ) {
                    state.sweepstakes.push(action.payload[i]);
                    // Set as the active sweepstake if it has started and not yet ended
                    // Commented out for the future, for now, select the 1st element
                    /*
                    if (
                        new Date(action.payload[i].startDateUTC) <= new Date() &&
                        new Date(action.payload[i].endDateUTC) > new Date()
                    ) {
                        state.activeSweepstake = action.payload[i].sweepstakesId;
                    }*/
                } else {
                    state.sweepstakes = state.sweepstakes.map((listItem) => {
                        if (listItem.sweepstakesId === action.payload[i].sweepstakesId) {
                            listItem = action.payload[i];
                        }
                        return listItem;
                    });
                }
            }
            // Per Sagar's request: Select the 1st item as the active sweepstake for now
            if (state.sweepstakes.length > 0) {
                state.activeSweepstake = state.sweepstakes[0].sweepstakesId;
            }
        });
    },
    initialState: sweepstakesInitialState,
    name: 'sweepstakes',
    reducers: {
        setActiveSweepstake(state, action: PayloadAction<Sweepstake>) {
            state.activeSweepstake = action.payload.sweepstakesId;
        },
        setEntryModalHasBeenDisplayed(state, action: PayloadAction<boolean>) {
            state.entryModalHasBeenDisplayed = action.payload;
        },
    },
});

export const { setActiveSweepstake, setEntryModalHasBeenDisplayed } = sweepstakesSlice.actions;

const selectSweepstakesState = ({ sweepstakes }: RootState): SweepstakesSlice => sweepstakes;

export const selectSweepstakes = createSelector(selectSweepstakesState, ({ sweepstakes }) => sweepstakes);

export const selectEntryModalHasBeenDisplayed = createSelector(
    selectSweepstakesState,
    ({ entryModalHasBeenDisplayed }) => entryModalHasBeenDisplayed,
);

export const selectActiveSweepstakeId = createSelector(
    selectSweepstakesState,
    ({ activeSweepstake }) => activeSweepstake,
);

export const selectActiveSweepstake = createSelector(selectSweepstakesState, ({ sweepstakes, activeSweepstake }) => {
    const sweepstakesItem = sweepstakes.find((sweepstake) => sweepstake.sweepstakesId === activeSweepstake);
    return sweepstakesItem;
});

// TODO refactor to not take in a param
export const selectSweepstake = (sweepstakesId: string) => {
    return ({ sweepstakes: { sweepstakes } }: RootState): Sweepstake => {
        const sweepstakesItem = sweepstakes.find((sweepstake) => sweepstake.sweepstakesId === sweepstakesId);
        return sweepstakesItem;
    };
};

export default sweepstakesSlice.reducer;
