import React from 'react';
import { uuid_v4 } from '../../../../../utils';
import { PrizeoutOffer } from '../../../../../slices/offers-slice';
import { OfferGiftCard } from '../offer-gift-card/offer-gift-card';
import './vertical-offers-display.scss';

interface VerticalOffersDisplayProps {
    offers: PrizeoutOffer[];
    onClick: (offer?: PrizeoutOffer) => void;
}

export const VerticalOffersDisplay: React.FC<VerticalOffersDisplayProps> = ({
    offers,
    onClick,
}): React.ReactElement => {
    const returnDisplay = () => {
        return offers.map((offer) => (
            <li key={uuid_v4()}>
                <OfferGiftCard offer={offer} onSelectOffer={() => onClick(offer)} />
            </li>
        ));
    };

    return <ul className="vertical-offers-display">{returnDisplay()}</ul>;
};
