import React, { useState } from 'react';
import Classnames from 'classnames';
import useIsMounted from 'react-is-mounted-hook';
import { ClickableCard } from '../cards';

import './copy-content.scss';

interface CopyContentProps {
    content: string;
    title: string;
}

export const CopyContent: React.FC<CopyContentProps> = ({ content, title }): React.ReactElement => {
    const [contentCopied, setContentCopied] = useState(false),
        classes: string = Classnames('copy-container', { 'copy-success': contentCopied });

    let copiedTimer: ReturnType<typeof setTimeout>;

    const copyContent = () => {
        if (copiedTimer) {
            clearTimeout(copiedTimer);
        }
        const elem = document.createElement('textarea');
        elem.value = content;
        document.body.appendChild(elem);
        elem.select();
        document.execCommand('copy');
        document.body.removeChild(elem);
        setContentCopied(true);
        copiedTimer = setTimeout(() => {
            if (isMounted()) {
                setContentCopied(false);
            }
        }, 2500);
    };

    const isMounted = useIsMounted();

    return (
        <div className={classes} data-testid="copy-container">
            <div className="copy-label-container">
                <p className="copy-label">
                    <b>{title}</b>
                </p>
                <p className="copied" data-testid="copied-text">
                    Copied
                </p>
            </div>
            <ClickableCard className="copy-value-container" onClick={copyContent} cardName={'Copy content'}>
                <p className="copy-value">{content}</p>
                <span>
                    <i className="fa-kit fa-prizeout-copy" title="copy" data-testid="copy-icon"></i>
                </span>
            </ClickableCard>
        </div>
    );
};
