import React from 'react';
import { useAppSelector } from '../../../../hooks';
import {
    clearActiveOfferFilters,
    PrizeoutCategory,
    selectOfferCategories,
    toggleActiveOfferFilter,
    selectActiveOfferFilters,
} from '../../../../slices/offers-slice';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../../store';
import { GenericModal } from '../../modals';
import { PillsListLayout } from '../../ui-regions/common';
import { FilterPill } from './filter-pill';
import { TrackingService, SearchTrackingTypes } from '../../../../utils/services/tracking';

interface FilterModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const FilterModal: React.FC<FilterModalProps> = ({ isOpen, onClose }): React.ReactElement => {
    const availableCategories = useAppSelector(selectOfferCategories);
    const activeFilterCategories = useAppSelector(selectActiveOfferFilters);
    const dispatch = useDispatch<AppDispatch>();
    const trackingService = TrackingService.getTrackingService();

    const toggleFilter = (category: PrizeoutCategory) => {
        trackingService.trackSearchEvent(SearchTrackingTypes.SEARCH_FILTER_CATEGORY_CLICK, {
            type: category.category,
            toggle: activeFilterCategories.filter((cat) => cat.category === category.category).length == 0,
        });
        dispatch(toggleActiveOfferFilter(category));
    };

    const onClick = (category: PrizeoutCategory) => {
        toggleFilter(category);
    };

    const renderCategoryList = (availableCategories: PrizeoutCategory[]) => {
        return availableCategories.map(({ category, unicode }) => {
            return (
                <FilterPill
                    clickHandler={() =>
                        onClick({
                            category,
                            unicode,
                        })
                    }
                    key={category}
                    name={category}
                    unicode={unicode}
                />
            );
        });
    };

    return (
        <GenericModal
            ariaLabelledById="filter-modal-headline"
            ariaLabel="Filter display options modal"
            customClassName="filter-modal"
            hasExitButton={true}
            hasIcon={true}
            iconClasses="fak fa-prizeout-filter"
            isOpen={isOpen}
            onClose={onClose}
            testId="filter-modal"
        >
            <PillsListLayout
                ariaLabelledById="filter-modal-headline"
                headline="Filter by"
                primaryOnClick={() => onClose()}
                primaryTestId="filter-done-btn"
                secondaryOnClick={() => dispatch(clearActiveOfferFilters())}
                secondaryTestId="filter-clear-btn"
                subheadline="Categories"
            >
                {renderCategoryList(availableCategories)}
            </PillsListLayout>
        </GenericModal>
    );
};
