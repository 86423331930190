import { useDispatch } from 'react-redux';
import { resetSDKParams } from '../slices/sdk-slice';

import { AppDispatch } from '../store';
import { routes } from '../utils/helpers/routing-helpers';
import SDKMessenger from '../utils/services/sdk';
import { TrackingService } from '../utils/services/tracking';

export function usePrizeoutSDK() {
    const dispatch = useDispatch<AppDispatch>();
    const trackingService = TrackingService.getTrackingService();

    const sdkMessenger = SDKMessenger.getSDKMessengerService();

    const closePrizeout = async (location: routes) => {
        dispatch(resetSDKParams());
        await trackingService.trackHomeClickExitPrizeout(location);
        sdkMessenger.closePrizeout();
    };

    return [closePrizeout];
}

export default usePrizeoutSDK;
