import React, { useState } from 'react';
import { Button, OfferSortSelectorModal } from '../../../../../components/common';
import { useAppSelector } from '../../../../../hooks';
import { selectIsCategorySortEnabled } from '../../../../../slices/partner-config-slice';

import './offer-sort-toggle.scss';

export const OfferSortToggle: React.FC = () => {
    const [isSortModalShowing, setIsSortModalShowing] = useState(false);
    const isCategorySortEnabled = useAppSelector(selectIsCategorySortEnabled);

    return (
        <>
            {isCategorySortEnabled && (
                <section className="offer-sort-toggle" data-testid="offer-sort-toggle">
                    <div className="offer-sort-toggle__wrapper">
                        <Button
                            ariaLabel={'Sort Offer Menu'}
                            className="offer-sort-toggle__button"
                            icon="fa-kit fa-prizeout-categories-filter"
                            onClick={() => setIsSortModalShowing(true)}
                            testId="offer-sort-toggle__button"
                        />
                    </div>
                    <OfferSortSelectorModal isOpen={isSortModalShowing} onClose={() => setIsSortModalShowing(false)} />
                </section>
            )}
        </>
    );
};
