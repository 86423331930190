import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import currencyFormatter from 'currency-formatter';

import type { CPRootState } from '../store';
import { sharedAPI } from '../utils/services/api';
import { RedemptionInstructionsV2 } from './wallet-slice';

interface giftCardSender {
    first_name?: string;
    last_name?: string;
}

export interface ClaimDetails {
    provider_name: string;
    giftcard_name: string;
    image_url: string;
    value_in_cents: number;
    currency_code: string;
    store_url?: string;
    custom_message?: string;
    sender?: giftCardSender;
    expiration_date?: string;
}

// Define a type for the slice state
export interface GiftCardState {
    barcodeUrl?: string;
    barcodeValue?: string;
    code: string;
    currencyCode: string;
    currencySymbol: string;
    expirationDate?: string;
    giftCardName: string;
    giftCardTerms?: string;
    imageUrl: string;
    logomarkUrl: string;
    pinCode: string;
    providerName: string;
    qrCodeValue?: string;
    redemptionInstructions?: string;
    redemptionInstructionsV2?: RedemptionInstructionsV2;
    redirectUrl?: string;
    requestId: string;
    showLargeBarcode: boolean;
    showLargeQrCode: boolean;
    showQrCode: boolean;
    storeUrl?: string;
    unlockToken?: string;
    valueInCents: string;
    details?: ClaimDetails;
}

interface UnlockGiftCardRequest {
    giftCardId: string;
    pinCode: string;
    requestId: string;
}

interface UnlockGiftCardResponse {
    archived: boolean;
    barcodeUrl: string;
    barcodeValue: string;
    code: string;
    currencyCode: string;
    expirationDate: string;
    giftCardName: string;
    giftCardRequestId: string;
    giftCardTerms: string;
    imageUrl: string;
    logomarkUrl: string;
    partnerName: string;
    pin: string;
    qrCode: string;
    redemption_instructions?: {
        show?: boolean;
        value?: string;
    };
    redemptionInstructions?: string;
    redemptionInstructionsV2?: RedemptionInstructionsV2;
    redirect_url?: string;
    redirectUrl?: string;
    reviewStatus: string;
    status: string;
    storeUrl: string;
    unlockToken: string;
    valueInCents: number;
}

interface ClaimDetailsRequest {
    requestId: string;
    giftcardId: string;
}

// Define the initial state using that type
export const giftCardInitialState: GiftCardState = {
    code: '',
    currencyCode: '',
    currencySymbol: '$',
    expirationDate: '',
    giftCardName: '',
    imageUrl: '',
    logomarkUrl: '',
    pinCode: '',
    providerName: '',
    requestId: '',
    showLargeBarcode: false,
    showLargeQrCode: false,
    showQrCode: false,
    unlockToken: '',
    valueInCents: '',
};

export const unlockGiftCard = createAsyncThunk(
    'giftCard/unlockGiftCard',
    async ({ giftCardId, pinCode, requestId }: UnlockGiftCardRequest, { rejectWithValue, signal }) => {
        try {
            // unlock gift card api call goes here.
            const results = await sharedAPI.request({
                data: {
                    giftCardId,
                    requestId,
                    token: pinCode, // c5e93
                },
                endpoint: '/wallet/claim/unlock',
                method: 'GET',
                signal: signal,
            });

            return {
                ...results.data,
                unlockToken: pinCode,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const getClaimDetails = createAsyncThunk(
    'claim/details',
    async ({ requestId, giftcardId }: ClaimDetailsRequest, { rejectWithValue, signal }) => {
        try {
            // unlock gift card api call goes here.
            const results = await sharedAPI.request({
                data: {
                    giftcardId,
                    requestId,
                },
                endpoint: '/wallet/claim/details',
                method: 'GET',
                signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const giftCardSlice = createSlice({
    extraReducers: (builder) => {
        // Add reducers for additional action types here, and handle loading state as needed
        builder.addCase(unlockGiftCard.fulfilled, (state, action: PayloadAction<UnlockGiftCardResponse>) => {
            if (action.payload.qrCode) {
                state.showQrCode = !!action.payload.qrCode;
                state.qrCodeValue = action.payload.qrCode;
            }

            state.barcodeUrl = action.payload.barcodeUrl;
            state.code = action.payload.code;
            state.currencyCode = action.payload.currencyCode;

            switch (state.currencyCode) {
                case 'EUR':
                    state.currencySymbol = '€';
                    break;
                case 'GBP':
                    state.currencySymbol = '£';
                    break;
                case 'CAD':
                    state.currencySymbol = 'CA$';
                    break;
                case 'BHD':
                    state.currencySymbol = 'BHD';
                    break;
                case 'USD':
                default:
                    state.currencySymbol = '$';
                    break;
            }

            state.expirationDate = action.payload.expirationDate;
            state.giftCardName = action.payload.giftCardName;
            state.giftCardTerms = action.payload.giftCardTerms;
            state.imageUrl = action.payload.imageUrl;
            state.logomarkUrl = action.payload.logomarkUrl;
            state.pinCode = action.payload.pin;
            state.redemptionInstructions = action.payload.redemptionInstructions;
            state.redemptionInstructionsV2 = action.payload.redemptionInstructionsV2;

            const splitProviderName = action.payload.giftCardName.split(' '),
                sanitizedProviderName = [];
            for (let i = 0; i < splitProviderName.length; i++) {
                const current = splitProviderName[i];
                sanitizedProviderName.push(current.charAt(0).toUpperCase() + current.slice(1));
            }
            const formattedProviderName = sanitizedProviderName.join(' ');
            state.providerName = formattedProviderName;

            if (action.payload?.redemption_instructions?.show) {
                state.redemptionInstructions = action.payload?.redemption_instructions?.value;
            }

            if (action.payload.storeUrl) {
                let s = action.payload.storeUrl;
                const prefix = 'http://';
                const securePrefix = 'https://';

                if (s.substr(0, prefix.length) !== prefix && s.substr(0, securePrefix.length) !== securePrefix) {
                    s = prefix + s;
                }

                state.storeUrl = s;
            }

            state.redirectUrl = action.payload.redirect_url || action.payload.redirectUrl || null;
            state.unlockToken = action.payload.unlockToken;

            const { decimalDigits } = currencyFormatter.findCurrency(state.currencyCode);

            const options = {
                currency: state.currencyCode,
                maximumFractionDigits: decimalDigits,
                minimumFractionDigits: decimalDigits,
                style: 'currency',
            };

            const numberFormat = new Intl.NumberFormat('en-US', options);

            state.valueInCents = numberFormat.format(action.payload.valueInCents / 100);
        });

        builder.addCase(getClaimDetails.fulfilled, (state, { payload }) => {
            state.details = payload;
        });
    },
    initialState: giftCardInitialState,
    name: 'giftCard',
    reducers: {
        setRequestId(state, action: PayloadAction<string>) {
            state.requestId = action.payload;
        },
        toggleLargeBarcode(state) {
            state.showLargeBarcode = !state.showLargeBarcode;
        },
        toggleLargeQrCode(state) {
            state.showLargeQrCode = !state.showLargeQrCode;
        },
    },
});

export const { toggleLargeBarcode, toggleLargeQrCode, setRequestId } = giftCardSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectShowBarcodeImage = ({ giftCard: { showLargeBarcode } }: CPRootState): boolean => showLargeBarcode;

export const selectShowLargeQrCode = ({ giftCard: { showLargeQrCode } }: CPRootState): boolean => showLargeQrCode;

export const selectCode = ({ giftCard: { code } }: CPRootState): string => code;

export const selectStoreUrl = ({ giftCard: { storeUrl } }: CPRootState): string => storeUrl;

export const selectRedirect = ({ giftCard: { redirectUrl } }: CPRootState): string => redirectUrl;

export const selectGiftCardName = ({ giftCard: { giftCardName } }: CPRootState): string => giftCardName;

export const selectProviderName = ({ giftCard: { providerName } }: CPRootState): string => providerName;

export const selectCustomTerms = ({ giftCard: { giftCardTerms } }: CPRootState): string => giftCardTerms;

export const selectQrCodeValue = ({ giftCard: { qrCodeValue } }: CPRootState): string => qrCodeValue;

export const selectShowQrCode = ({ giftCard: { showQrCode } }: CPRootState): boolean => showQrCode;

export const selectBarcodeImage = ({ giftCard: { barcodeUrl } }: CPRootState): string => barcodeUrl;

export const selectGiftCardDetails = ({ giftCard }: CPRootState): GiftCardState => giftCard;

export const selectClaimDetails = ({ giftCard: { details } }: CPRootState): ClaimDetails => details;

export const selectRequestId = ({ giftCard: { requestId } }: CPRootState): string => requestId;

export default giftCardSlice.reducer;
