import { useDispatch } from 'react-redux';
import { useAppSelector } from './use-app-selector';
import { Brand, selectWalletBrandSort, setWalletBrandSort, WalletBrandSortType } from '../slices/wallet-slice';
import { AppDispatch } from '../store';

export interface SortOption {
    key: string;
    heuristic: (list: Brand[]) => Brand[];
    icon: string;
    type: WalletBrandSortType;
}

export function useBrandSort(): {
    activeSortType: string;
    sortList: (list: Brand[]) => Brand[];
    sortOptions: SortOption[];
    setActiveSortType: (sortType: WalletBrandSortType) => void;
} {
    const dispatch = useDispatch<AppDispatch>();

    const activeSortType = useAppSelector(selectWalletBrandSort);

    const sortFn = (heuristic: (a: Brand, b: Brand) => number, list: Brand[]): Brand[] => {
        const result = [...list];
        result.sort(heuristic);
        return result;
    };

    const sortOptions: SortOption[] = [
        {
            key: 'Newest',
            heuristic: sortFn.bind(null, (a: Brand, b: Brand) => {
                const keyA = new Date(a.lastCreateDate),
                    keyB = new Date(b.lastCreateDate);
                if (keyA > keyB) {
                    return -1;
                }
                if (keyA < keyB) {
                    return 1;
                }
                return 0;
            }),
            icon: 'far fa-clock',
            type: WalletBrandSortType.NEWEST,
        },
        {
            key: 'Oldest',
            heuristic: sortFn.bind(null, (a: Brand, b: Brand) => {
                const keyA = new Date(a.lastCreateDate),
                    keyB = new Date(b.lastCreateDate);
                if (keyA < keyB) {
                    return -1;
                }
                if (keyA > keyB) {
                    return 1;
                }
                return 0;
            }),
            icon: 'far fa-clock',
            type: WalletBrandSortType.OLDEST,
        },
        {
            key: 'Amount high',
            heuristic: sortFn.bind(null, (a: Brand, b: Brand) =>
                a.activeTotalValueCents < b.activeTotalValueCents ? 1 : -1,
            ),
            icon: 'far fa-dollar-sign',
            type: WalletBrandSortType.AMOUNT_HIGH,
        },
        {
            key: 'A-Z',
            heuristic: sortFn.bind(null, (a: Brand, b: Brand) => a.brandName.localeCompare(b.brandName)),
            icon: '',
            type: WalletBrandSortType.ALPHA_ASC,
        },
    ];

    const sortList = (list: Brand[]) => {
        const sortTypeEntry = sortOptions.find((opt) => opt.type === activeSortType);
        return sortTypeEntry.heuristic(list);
    };

    const setActiveSortType = (newType: WalletBrandSortType) => {
        dispatch(setWalletBrandSort(newType));
    };

    return {
        activeSortType,
        setActiveSortType,
        sortList,
        sortOptions,
    };
}
