import React from 'react';
import { ImageWithHeading } from '../../../../../../components/common';
import { constants } from '../../../../../../utils/constants';
import { useAppSelector } from '../../../../../../hooks';
import { selectPartnerName } from '../../../../../../slices/partner-config-slice';

interface GiftingImageWithHeadingProps {
    text?: string;
}

export const GiftingImageWithHeading: React.FC<GiftingImageWithHeadingProps> = ({ text }): React.ReactElement => {
    const partnerName = useAppSelector(selectPartnerName);
    const defaultText = `${partnerName} Gifting`;

    return (
        <ImageWithHeading
            imageUrl={constants.giftingImageUrl}
            testId="gifting-image-with-heading"
            text={text || defaultText}
        />
    );
};
