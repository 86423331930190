export interface PromiseControl {
    promise: Promise<any>;
    resolve: (value?: unknown) => void;
    reject: (value?: unknown) => void;
}

export const getPromiseWithResolvers = (): PromiseControl => {
    // withResolvers is valid and exists in the browser, but neither typescript nor jest
    // have a clue about this.
    //
    // @ts-expect-error Typescript and Jest are stupid.
    if (Promise.withResolvers) {
        // @ts-expect-error Typescript and Jest...yes, again.
        return Promise.withResolvers();
    } else {
        // This should only happend during tests?  But there's a tiny chance we could encounter
        // a browser that needs this.  If we ever get Typescript and Jest worked out for good,
        // we should rip this out.
        let resolve, reject;
        const promise = new Promise((pres, prej) => {
            resolve = pres;
            reject = prej;
        });

        return {
            promise,
            resolve,
            reject,
        };
    }
};
