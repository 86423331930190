import React from 'react';
import { currencyDisplay } from '../../../utils/helpers';
import { useAppSelector } from '../../../hooks';
import { selectPartnerCurrency } from '../../../slices/partner-config-slice';
interface CurrencyFormatterProps {
    currencyOverride?: string;
    currencySymbolOverride?: string;
    decimals?: boolean;
    inline?: boolean;
    value: number;
}

const CurrencyFormatterComponent: React.FC<CurrencyFormatterProps> = ({
    currencyOverride,
    currencySymbolOverride,
    decimals = false,
    inline = false,
    value,
}): React.ReactElement => {
    const currency = currencyOverride || useAppSelector(selectPartnerCurrency);
    const output = currencyDisplay(currency, value, decimals, currencySymbolOverride);
    if (inline) {
        return <>{output}</>;
    } else {
        return <small>{output}</small>;
    }
};

export const CurrencyFormatter = React.memo(CurrencyFormatterComponent);

export default React.memo(CurrencyFormatterComponent);
