import React from 'react';
import classNames from 'classnames';
import { Button } from '../../buttons';
import { TrackingService } from '../../../../utils/services/tracking';

import './pill.scss';
import { useLocation } from 'react-router-dom';

interface PillProps {
    isActive?: boolean | number;
    isDisplayedOnDashboard?: boolean;
    name: string;
    onClick?: () => void;
    onClose?: () => void;
    testId?: string;
    unicode?: string;
    icon?: string;
    rawPill?: boolean;
    className?: string;
}

export const Pill: React.FC<PillProps> = ({
    isActive,
    isDisplayedOnDashboard,
    rawPill = false,
    name,
    onClick,
    onClose,
    testId,
    icon,
    unicode,
    className,
}): React.ReactElement => {
    const classes = classNames('pill', {
        'pill--active': isActive,
        'pill--dashboard': isDisplayedOnDashboard,
    });
    const iconClass = classNames({
        pill__unicode: unicode,
        pill__icon: icon,
    });
    const pillTestId = testId || `${name}-testId`;
    const dashboardPillTestId = `${name}-dashboardTestId`;
    const trackingService = TrackingService.getTrackingService();
    const location = useLocation();
    const closeHandler = (...args: any[]) => {
        trackingService.trackPillClick(name, true, location.pathname);
        onClose?.call(undefined, ...args);
    };

    const clickHandler = (...args: any[]) => {
        trackingService.trackPillClick(name, false, location.pathname);
        onClick?.call(undefined, ...args);
    };

    const renderIcon = () => {
        if (!isDisplayedOnDashboard && isActive) {
            return (
                <span data-testid="pill-icon" className="pill__icon">
                    <i className="far fa-check"></i>
                </span>
            );
        } else {
            return (
                <span data-testid="pill-unicode" className={iconClass}>
                    {icon ? <i className={icon}></i> : unicode}
                </span>
            );
        }
    };

    const renderPill = () => {
        if (isDisplayedOnDashboard) {
            return (
                <div data-testid={dashboardPillTestId} className={classes}>
                    {renderIcon()}
                    <span>{name}</span>
                    <Button
                        ariaLabel="Close pill selection"
                        className="pill__close"
                        icon="fal fa-times"
                        isElement
                        onClick={closeHandler}
                        testId={pillTestId}
                    />
                </div>
            );
        } else {
            return (
                <Button
                    ariaLabel="Pill selector"
                    className={classes}
                    isElement
                    onClick={clickHandler}
                    testId={pillTestId}
                >
                    {renderIcon()}
                    <span>{name}</span>
                </Button>
            );
        }
    };

    if (rawPill) {
        return <>{renderPill()}</>;
    } else {
        return <li className={className}>{renderPill()}</li>;
    }
};
