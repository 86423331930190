import React, { useEffect, useState } from 'react';
import { useAppSelector } from '../../../hooks';
import { selectCurrentBankAccount } from '../../../slices/partner-config-slice';
import { DividerRowWithContent } from '../ui-widgets';
import { TextLabel } from '../ui-widgets/text-label';
import { selectIsGiftingMode } from '../../../slices/sdk-slice';
import { BankAccountSelectorModal } from '../modals/bank-account-selector-modal';

import './bank-account-selector.scss';

export const BankAccountSelector: React.FC = (): React.ReactElement => {
    const currentBankAccount = useAppSelector(selectCurrentBankAccount);
    const [accountTextLabel, setAccountTextLabel] = useState('');
    const isGiftingMode = useAppSelector(selectIsGiftingMode);

    const [isBankAccountSelectorModalOpen, setIsBankAccountSelectorModalOpen] = useState(false);

    const openBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(true);
    };

    const closeBankAccountSelectorModal = () => {
        setIsBankAccountSelectorModalOpen(false);
    };

    const renderContent = () => {
        if (isGiftingMode) {
            return (
                <DividerRowWithContent
                    name="select-bank-account"
                    shouldExtendEdges={true}
                    onClick={() => openBankAccountSelectorModal()}
                    disableTracking={true}
                >
                    <div className="grid grid--y-center grid--stretch-left">
                        <TextLabel isBold={!currentBankAccount} text={accountTextLabel} />
                        <i className="fak fa-prizeout-edit text-highlight"></i>
                    </div>
                </DividerRowWithContent>
            );
        }

        return <small className="bank-account-selector__name">{accountTextLabel}</small>;
    };

    useEffect(() => {
        if (currentBankAccount) {
            setAccountTextLabel(`${currentBankAccount.name} **${currentBankAccount.last4}`);
            return;
        }

        setAccountTextLabel('Select a bank account');
    }, [currentBankAccount]);

    return (
        <>
            <div data-testid="bank-account-selector" className="bank-account-selector">
                {renderContent()}
            </div>

            <BankAccountSelectorModal isOpen={isBankAccountSelectorModalOpen} onClose={closeBankAccountSelectorModal} />
        </>
    );
};
