import React from 'react';
import { TextLabel } from './text-label';
import { RoundImage } from './round-image';
import { RoundImageSizes } from '../../../slices/common-slice';

import './image-with-text-label.scss';

interface ImageWithTextLabelProps {
    imageAltText?: string;
    imageUrl: string;
    isRoundImage?: boolean;
    roundImageSize?: RoundImageSizes;
    text: string;
}

export const ImageWithTextLabel: React.FC<ImageWithTextLabelProps> = ({
    imageAltText = '',
    imageUrl,
    isRoundImage,
    roundImageSize = RoundImageSizes.TWO,
    text,
}): React.ReactElement => {
    const renderImage = () => {
        if (isRoundImage) {
            return (
                <RoundImage
                    testId="round-image-with-text-label"
                    imageUrl={imageUrl}
                    imageAltText={imageAltText}
                    size={roundImageSize}
                />
            );
        } else {
            return <img data-testid="image-with-text-label" src={imageUrl} alt={imageAltText} />;
        }
    };

    return (
        <div className="image-with-text-label">
            {renderImage()}
            <TextLabel text={text} isBold />
        </div>
    );
};
