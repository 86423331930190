import React, { useRef, useEffect, useReducer } from 'react';
import Classnames from 'classnames';
import { PrizeoutOffer, selectSearchResultsDisplay, toggleIsSearchResultsFullScreen } from '../../../slices/offers-slice';
import { selectIsMobilePortrait } from '../../../slices/common-slice';
import { useAppSelector } from '../../../hooks';
import { uuid_v4 } from '../../../utils';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../../store';
import { Button } from '../buttons';
import { ClickableCard } from '../cards';
import GeneralInput, { GeneralInputControls } from '../ui-widgets/general-input';
import { SearchState, SearchStateAction, SearchStateActionNames, initializeSearchState, searchStateReducer } from './search-state-manager';
import { TrackingService, SearchTrackingTypes } from '../../../utils/services/tracking';
import { SearchConfig } from './config';

import './search-widget.scss';

export interface SearchWidgetProps<GenericObject> {
    isFullWidth?: boolean;
    searchItems: GenericObject[];
    searchConfig: SearchConfig<GenericObject>;
    onClick: (arg: GenericObject) => void;
    useIconOnMobile?: boolean;
    name?: string;
    testId?: string;
}

export function SearchWidget<GenericObject>({
    isFullWidth = false,
    searchItems,
    searchConfig,
    onClick,
    useIconOnMobile,
    name,
    testId = 'search-widget',
}: SearchWidgetProps<GenericObject>): React.ReactElement {
    const { matcher, Presenter, listTransform } = searchConfig;
    const searchWidgetRef = useRef<HTMLDivElement>();
    const formRef = useRef<HTMLFormElement>();
    const trackingService = TrackingService.getTrackingService();

    const dispatch = useDispatch<AppDispatch>();
    const isMobilePortrait = useAppSelector(selectIsMobilePortrait);
    const isSearchResultsFullScreen = useAppSelector(selectSearchResultsDisplay);
    const [searchState, searchStateDispatcher] = useReducer<React.Reducer<SearchState<GenericObject>, SearchStateAction<GenericObject>>>(
        // eslint-disable-next-line prettier/prettier
        searchStateReducer<GenericObject>, // lint thinks this should be a function call, but it can't be.
        initializeSearchState<GenericObject>(),
    );

    const parentClasses = Classnames(
        'search-widget no-scrollbars',
        { 'z-index-search-widget': !isSearchResultsFullScreen },
        { 'search-widget--full-screen': isSearchResultsFullScreen && isMobilePortrait },
        { 'search-widget--full-width': isFullWidth },
    );

    const inputClasses = Classnames('search-widget__input', {
        'search-widget__input--has-results': searchState.canHaveResults,
    });

    // Clear the user query
    const clearQuery = (inputControls?: GeneralInputControls) => {
        formRef.current?.reset();
        searchStateDispatcher({ type: SearchStateActionNames.UNSET_HAS_QUERY });
        trackingService.trackSearchEvent(SearchTrackingTypes.SEARCH_CLEAR_QUERY_CLICK);
        if (inputControls) {
            inputControls.focus(); // Keep focus when clearing input
        }
    };

    // Search the items, call is debounced
    const search = (query: string): void => {
        const resultSet = new Set<GenericObject>();
        if (query.trim() != '') {
            searchStateDispatcher({ type: SearchStateActionNames.SET_HAS_QUERY });
            listTransform(searchItems, {pattern: query}).forEach((item: GenericObject) => {
                if (matcher(item, query) && !resultSet.has(item)) {
                    resultSet.add(item);
                }
            });
        } else {
            searchStateDispatcher({ type: SearchStateActionNames.UNSET_HAS_QUERY });
        }

        const list = Array.from(resultSet);
        searchStateDispatcher({
            type: SearchStateActionNames.SET_MATCHES,
            payload: {
                matchingItems: list,
            },
        });
    };

    /// Remove the focus from whatever element has it. Forces the search widget to close.
    const loseFocus = () => {
        (document.activeElement as HTMLElement).blur();
    };

    // Click on the cancel button (mobile view)
    const cancel = () => {
        clearQuery();
        loseFocus();
        dispatch(toggleIsSearchResultsFullScreen(false));
        searchStateDispatcher({ type: SearchStateActionNames.CLOSE_LIST });
    };

    // Handles click on a search result
    const selectItem = (item: GenericObject) => {
        onClick?.call(null, item);
        cancel();
    };

    // Cancel out if user hits escape
    const onSearchKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key == 'Escape') {
            cancel();
        }
    };

    // Open full screen view on mobile only
    const onSearchClicked = () => {
        window.scrollTo(0, 0);
        dispatch(toggleIsSearchResultsFullScreen(true));
    };

    const onInputFocus = () => {
        searchStateDispatcher({ type: SearchStateActionNames.OPEN_LIST });
        if (!useIconOnMobile && isMobilePortrait) {
            onSearchClicked();
        }
    };

    // Print the search results
    const displayMatchingResults = () => {
        const itemName = name ? name + "search-widget-list-item" : "search-widget-list-item";
        return (
            <ul className="search-widget__list z-index-search-widget">
                {searchState.matchingItems.map((item) => {
                    const offer = item as unknown as PrizeoutOffer;
                    const value = offer.display_monetary_bonus ? offer.display_monetary_bonus : offer.display_bonus;
                    const classes = Classnames("search-widget__list-item", {
                        "search-widget__list-item--with-bonus": value > 0
                    });
                    return (
                        <li className="search-widget__list-item-wrapper" key={uuid_v4()}>
                            <ClickableCard
                                cardName={itemName}
                                className={classes}
                                onClick={() => selectItem(item)}
                            >
                                <Presenter item={item} />
                            </ClickableCard>
                        </li>
                    );
                })}
            </ul>
        );
    };

    // Print the No Results message
    const displayNoResults = () => {
        return (
            <ul className="search-widget__list z-index-search-widget">
                <li key={uuid_v4()} className="search-widget__list-message">
                    <p>No results found</p>
                </li>
            </ul>
        );
    };

    // Close the list of results when clicking outside the search widget
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (searchWidgetRef.current && !searchWidgetRef.current.contains(event.target as Node)) {
                searchStateDispatcher({ type: SearchStateActionNames.CLOSE_LIST });
            }
        };
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    useEffect(() => {
        if (isSearchResultsFullScreen) {
            const inputField: HTMLInputElement = formRef.current?.querySelector('[name="search-input"]');

            if (inputField) {
                inputField.focus();
            }
        }
    }, [isSearchResultsFullScreen, isMobilePortrait]);

    const showSearchInput = !useIconOnMobile || (!isMobilePortrait || (isMobilePortrait && isSearchResultsFullScreen));

    const renderResults = () => {
        if (!(searchState.isListOpen && searchState.canHaveResults)) {
            return null;
        }

        if (searchState.hasResults) {
            return displayMatchingResults();
        }

        if (searchState.canHaveResults) {
            return displayNoResults();
        }
    };

    return (
        <div className={parentClasses} onKeyDown={onSearchKeyDown} ref={searchWidgetRef} data-testid={testId}>
            <form className="search-widget__form" id="item-search-form" ref={formRef}>
                {!showSearchInput && (
                    <Button
                        ariaLabel='Click to enter search widget'
                        isElement={true}
                        onClick={onSearchClicked}
                        icon="fa-kit fa-prizeout-search"
                        className="search-widget__search-icon"
                    />
                )}

                {showSearchInput && (
                    <>
                        <div className="search-widget__row">
                            <div className={inputClasses}>
                                <GeneralInput
                                    name="search-input"
                                    placeholderText="Search by brand name"
                                    onChange={search}
                                    onFocus={onInputFocus}
                                    clearLabel="Clear query"
                                    onClear={clearQuery}
                                    icon="fa-kit fa-prizeout-search"
                                />
                            </div>

                            {isMobilePortrait && isSearchResultsFullScreen && (
                                <Button ariaLabel="Cancel" isLink onClick={() => cancel()} text="Cancel" />
                            )}
                        </div>
                        <div className="search-widget__list-container">{renderResults()}</div>
                    </>
                )}
            </form>
        </div>
    );
}

export default SearchWidget;
