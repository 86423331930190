import React from 'react';
import { CashbackOnboarding } from '../onboarding/cashback-onboarding';
import { GenericModal } from './generic-modal';
import { BaseCRMModalData } from '../../../slices/crm';

import './onboarding-modal.scss';

interface OnboardingModalProps {
    data?: BaseCRMModalData;
    isOpen: boolean;
    testId?: string;
    onClose: () => void;
}

export const OnboardingModal: React.FC<OnboardingModalProps> = ({ isOpen, onClose, testId, data }) => {
    return (
        <GenericModal
            testId={testId}
            ariaLabel="Onboarding Modal"
            customClassName="onboarding-modal"
            isOpen={isOpen}
            onClose={onClose}
        >
            <CashbackOnboarding onClose={onClose} data={data} />
        </GenericModal>
    );
};
