import React from 'react';
import { CRMTileClickableCard } from './crm-tile-clickable-card';
import { routes } from '../../../utils';
import {
    SkeletonLoaderBorderRadius,
    SkeletonLoaderElementGeneric,
    SkeletonLoaderWidth,
} from '../../common/skeleton-loaders';

interface CRMGenericSkeletonLoaderBarProps {
    height: number;
    isDisabled?: boolean;
}

export const CRMGenericSkeletonLoaderBar: React.FC<CRMGenericSkeletonLoaderBarProps> = ({
    height,
    isDisabled = false,
}) => {
    const route = routes.HOME;
    return (
        <CRMTileClickableCard isDisabled={isDisabled} route={route} isPadded={false}>
            <SkeletonLoaderElementGeneric
                width={SkeletonLoaderWidth.FULL}
                borderRadius={SkeletonLoaderBorderRadius.ZERO}
                height={height}
            />
        </CRMTileClickableCard>
    );
};
