import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../common';
import {
    GetRewardsResponse,
    PostRewardsClaimResponse,
    PostUserRewardRequest,
    RewardsEventGroups,
    UserReward,
} from './rewards-types';

export const rewardsApiSlice = createApi({
    reducerPath: 'rewardsApi',
    baseQuery: baseQuery,
    tagTypes: [RewardsEventGroups.REWARDS],
    endpoints: (builder) => ({
        getRewards: builder.query<GetRewardsResponse, void>({
            query: () => '/rewards',
            providesTags: [RewardsEventGroups.REWARDS],
        }),
        activateReward: builder.mutation<UserReward, PostUserRewardRequest>({
            query: (userRewardId) => ({
                url: `/rewards/activate`,
                method: 'POST',
                body: userRewardId,
            }),
            invalidatesTags: [RewardsEventGroups.REWARDS],
        }),
        claimReward: builder.mutation<PostRewardsClaimResponse, PostUserRewardRequest>({
            query: (userRewardId) => ({
                url: `/rewards/claim`,
                method: 'POST',
                body: userRewardId,
            }),
            invalidatesTags: [RewardsEventGroups.REWARDS],
        }),
    }),
});

export const { useGetRewardsQuery, useActivateRewardMutation, useClaimRewardMutation } = rewardsApiSlice;
