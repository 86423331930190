import React from 'react';
import Classnames from 'classnames';
import { routes } from '../../../utils';
import { useRouteToWidget } from '../../../hooks';
import { ClickableCard } from '../../common';

import './crm-tile-clickable-card.scss';

interface CRMTileClickableCardProps {
    isDisabled?: boolean;
    testId?: string;
    className?: string;
    isPadded?: boolean;
    route: routes;
    contrastBackground?: boolean;
}

export const CRMTileClickableCard: React.FC<CRMTileClickableCardProps> = ({
    children,
    isDisabled,
    className,
    testId = 'crm-tile-clickable-card',
    isPadded = true,
    route,
    contrastBackground = false,
}): React.ReactElement => {
    const classes = Classnames('crm-tile-clickable-card', className, {
        'crm-tile-clickable-card--padded': isPadded,
        'crm-tile-clickable-card--contrast': contrastBackground,
    });

    const { openCustomRoute } = useRouteToWidget();
    const handleCardOnClick = (route: routes) => openCustomRoute(route);

    return (
        <ClickableCard
            onClick={() => handleCardOnClick(route)}
            isDisabled={isDisabled}
            className={classes}
            testId={testId}
            disableTracking={true}
        >
            {children}
        </ClickableCard>
    );
};
