import { ApplicationModule } from '../../slices/common-slice';

export const getApplicationModule = () => {
    const location = window.location;

    if (process.env.APPLICATION) {
        return process.env.APPLICATION;
    }

    if (process.env.application) {
        return process.env.application;
    }

    if (location.hostname === 'localhost') {
        // Firefox does not support `ancestorOrigins` on `location`, so we need
        // to be tolerant of it not being there.
        if (location.port === '8079' && location.ancestorOrigins?.length === 0) {
            return ApplicationModule.ACCOUNTS;
        }

        // If you are running the application modules in the background, you do not need
        // to modify this. Just navigate to localhost at the appropriate port as listed
        // below.  If you cannot run the application modules in parallel, you must modify
        // the entry for port 8079 locally. Be sure not to commit that change to the
        // repository.
        const applicationPortMap: { [K: string]: ApplicationModule } = {
            '8079': ApplicationModule.WIDGET,
            '8078': ApplicationModule.WIDGET, // Run the emulator
            '8077': ApplicationModule.ACCOUNTS,
            '8076': ApplicationModule.STORE_FRONT,
            '8075': ApplicationModule.ADMIN,
            '8074': ApplicationModule.CLAIM,
            '8073': ApplicationModule.PARTNER_PORTAL,
        };

        return applicationPortMap[location.port] || ApplicationModule.WIDGET;
    }

    return ApplicationModule.WIDGET;
};

export const isConsumerApplication = () => {
    const application = getApplicationModule();
    return (
        application !== ApplicationModule.STORE_FRONT &&
        application !== ApplicationModule.ADMIN &&
        application !== ApplicationModule.PARTNER_PORTAL &&
        application !== ApplicationModule.CLAIM
    );
};
