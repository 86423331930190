import { createAsyncThunk, createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { sharedAPI } from '../utils/services/api';
import { RootState } from '../store';

export interface MiniWidgetSlice {
    miniWidgetBillboardId: string;
}

export const miniWidgetInitialState: MiniWidgetSlice = {
    miniWidgetBillboardId: '',
};

export type BillboardRequest = {
    billboardId: string;
};

export const getBillboardAssets = createAsyncThunk(
    'miniWidget/getBillboardAssets/',
    async ({ billboardId }: BillboardRequest, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    mini_widget_billboard_id: billboardId,
                },
                endpoint: `/miniWidgetBillboard/${billboardId}`,
                method: 'GET',
                signal: signal,
            });

            return {
                ...results.data,
            };
        } catch (e) {
            return rejectWithValue(e);
        }
    },
);

export const MiniWidgetSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(getBillboardAssets.fulfilled, (state, action: PayloadAction<MiniWidgetSlice>) => {
            state.miniWidgetBillboardId = action.payload.miniWidgetBillboardId;
        });
    },
    initialState: miniWidgetInitialState,
    name: 'miniWidget',
    reducers: {},
});

const selectMiniWidgetState = ({ miniWidget }: RootState): MiniWidgetSlice => miniWidget;

export const selectBillboardId = createSelector(
    selectMiniWidgetState,
    ({ miniWidgetBillboardId }) => miniWidgetBillboardId,
);

export default MiniWidgetSlice.reducer;
