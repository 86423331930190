import { PayloadAction, createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';
import { PrizeoutOffer, PrizeoutOfferValueOptions } from '../offers-slice';
import { sharedAPI } from '../../utils/services/api';
import { CBCERootState } from '../../store/cashback';
import { encodeDomain } from '../../utils';

export interface CBCEOfferState {
    activeCard?: PrizeoutOfferValueOptions;
    activeBrandId?: string;
    currentDomain?: string;
    hasActiveCards?: boolean;
    hasAvailableOffer?: boolean;
    isMerchantRequested?: boolean;
    offerMap?: Record<string, ExtensionPrizeoutOffer>;
}

export const cbceOfferInitialState: CBCEOfferState = {
    hasAvailableOffer: false,
    isMerchantRequested: false,
    offerMap: {},
};

export interface SetDomainOfferData {
    domain: string;
    offer: ExtensionPrizeoutOffer;
}

export interface ExtensionPrizeoutOffer extends PrizeoutOffer {
    numActiveCards: number;
}

export interface SetDomainData {
    domain: string;
}

interface RequestOfferParams {
    domain: string;
}

interface MerchantRequestParams {
    pageUrl: string; // Does not need to be url encoded
}

interface MerchantRequestStatusParams {
    encodedPageUrl: string;
}

export interface MerchantRequestStatus {
    requested: boolean;
}

interface MerchantMetadataParams {
    pageUrl: string;
}

export interface MerchantMetadataResponse {
    url: string;
    merchantId: string;
}

type MerchantRequestStatusResponse = MerchantRequestStatus;

type CardSummaryByMerchantResponse = {
    brandId: string;
    brandName: string;
    numArchivedCards: number;
    numActiveCards: number;
    numPendingCards: number;
    totalValueCents: number;
    activeTotalValueCents: number;
    logomarkUrl: string;
    hasPendingCard: boolean;
    lastArchiveDate: string;
    lastCashoutDate: string;
    lastCreateDate: string;
};

export const requestOfferByDomain = createAsyncThunk(
    'browserExtension/getOffer',
    async ({ domain }: RequestOfferParams, { rejectWithValue, signal }) => {
        try {
            const encodedDomain = encodeDomain(domain);
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/offers/${encodedDomain}`,
                method: 'GET',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to get offer');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const postMerchantRequest = createAsyncThunk(
    'browserExtension/postMerchantRequest',
    async ({ pageUrl }: MerchantRequestParams, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {
                    pageUrl: pageUrl,
                },
                endpoint: '/browserExtension/merchantRequest',
                method: 'POST',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to request merchant');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const getMerchantRequestStatus = createAsyncThunk(
    'browserExtension/getMerchantRequestStatus',
    async ({ encodedPageUrl }: MerchantRequestStatusParams, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/merchantRequest/${encodedPageUrl}`,
                method: 'GET',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to get request merchant status');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const getMerchantMetadata = createAsyncThunk(
    'browserExtension/getMerchantMetadata',
    async ({ pageUrl }: MerchantMetadataParams, { rejectWithValue, signal }) => {
        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/merchantMetadata/${encodeURIComponent(pageUrl)}`,
                method: 'GET',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to get merchant metadata');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response.data);
        }
    },
);

export const getCardSummaryByMerchant = createAsyncThunk(
    'browserExtension/wallet',
    async (encodedDomain: string, { rejectWithValue, signal }) => {
        if (!sharedAPI?.browserExtensionTokens?.userToken) {
            return rejectWithValue('No user token found');
        }

        try {
            const results = await sharedAPI.request({
                data: {},
                endpoint: `/browserExtension/wallet/${encodedDomain}`,
                method: 'GET',
                signal: signal,
            });

            if (results?.status !== 200) {
                throw new Error('Failed to get wallet');
            }

            return results.data;
        } catch (e) {
            return rejectWithValue(e.response?.data || e.message);
        }
    },
);

export const cbceOfferSlice = createSlice({
    extraReducers: (builder) => {
        builder.addCase(postMerchantRequest.fulfilled, (state) => {
            state.isMerchantRequested = true;
        });

        builder.addCase(
            getMerchantRequestStatus.fulfilled,
            (state, action: PayloadAction<MerchantRequestStatusResponse>) => {
                state.isMerchantRequested = action.payload.requested;
            },
        );

        builder.addCase(getMerchantMetadata.fulfilled, (state, action: PayloadAction<MerchantMetadataResponse>) => {
            state.activeBrandId = action.payload.merchantId;
        });

        builder.addCase(
            getCardSummaryByMerchant.fulfilled,
            (state, action: PayloadAction<CardSummaryByMerchantResponse>) => {
                state.hasActiveCards = action.payload.numActiveCards > 0;
            },
        );

        // TODO update offer map hash
        // builder.addCase(requestOfferByDomain.fulfilled, (state, action: PayloadAction<any>) => {

        // });
    },
    initialState: cbceOfferInitialState,
    name: 'cbceOffer',
    reducers: {
        setOffer(state, action: PayloadAction<SetDomainOfferData>) {
            state.offerMap[action.payload.domain] = action.payload.offer;
            state.activeCard = action.payload.offer?.giftcard_list[0];
            state.hasAvailableOffer = true;
            state.currentDomain = action.payload.domain;

            // To prevent unintended override, only set hasActiveCards if numActiveCards is a real value
            // Mobile safari leaves numActiveCards undefined here and hydrates it with getCardSummaryByMerchant
            if (action.payload.offer?.numActiveCards !== undefined) {
                state.hasActiveCards = action.payload.offer.numActiveCards > 0;
            }
        },
        setDomain(state, action: PayloadAction<SetDomainData>) {
            state.currentDomain = action.payload.domain;
        },
    },
});

export const { setOffer, setDomain } = cbceOfferSlice.actions;

const selectCBCEOfferState = ({ cbceOffer }: CBCERootState): CBCEOfferState => cbceOffer;

export const selectCurrentDomain = createSelector(selectCBCEOfferState, ({ currentDomain }) => currentDomain);

export const selectAvailableOffer = createSelector(
    selectCBCEOfferState,
    selectCurrentDomain,
    ({ offerMap, currentDomain }) => offerMap[currentDomain],
);

export const selectActiveCard = createSelector(selectCBCEOfferState, ({ activeCard }) => activeCard);

export const selectIsMerchantRequested = createSelector(
    selectCBCEOfferState,
    ({ isMerchantRequested }) => isMerchantRequested,
);

export const selectHasAvailableOffer = createSelector(
    selectCBCEOfferState,
    ({ hasAvailableOffer }) => hasAvailableOffer,
);

export const selectHasActiveCards = createSelector(selectCBCEOfferState, ({ hasActiveCards }) => hasActiveCards);

export default cbceOfferSlice.reducer;
