import { combineReducers, configureStore, ThunkDispatch } from '@reduxjs/toolkit';
import { AnyAction } from 'redux';

import { cashbackApiSlice, cashbackReducer, cashbackInitialState } from './slices/cashback';
import categoryListReducer, { categoryListInitialState } from './slices/category-list-slice';
import checkoutReducer, { checkoutInitialState } from './slices/checkout-slice';
import commonReducer, { commonInitialState } from './slices/common-slice';
import emailCaptureReducer, { emailCaptureInitialState } from './slices/email-capture-slice';
import giftCardReducer, { giftCardInitialState } from './slices/gift-card-slice';
import giftingReducer, { giftingInitialState } from './slices/gifting-slice';
import messagesReducer, { messagesInitialState } from './slices/messages-slice';
import miniWidgetReducer, { miniWidgetInitialState } from './slices/mini-widget-slice';
import offerConfigReducer, { offersInitialState } from './slices/offers-slice';
import onboardingReducer, { onboardingInitialState } from './slices/onboarding-slice';
import otpSliceReducer, { otpInitialState } from './slices/otp-slice';
import partnerConfigReducer, { partnerInitialState } from './slices/partner-config-slice';
import preferencesReducer, { preferencesInitialState } from './slices/preferences-slice';
import RewardActionsReducer, { rewardActionsInitialState } from './slices/reward-actions-slice';
import sdkReducer, { sdkInitialState } from './slices/sdk-slice';
import sidebarReducer, { sidebarInitialState } from './slices/sidebar-slice';
import storeFrontReducer, { storeFrontInitialState } from './slices/store-front-slice';
import SweepstakesReducer, { sweepstakesInitialState } from './slices/sweepstakes-slice';
import userReducer, { userInitialState } from './slices/user-slice';
import verificationReducer, { verificationInitialState } from './slices/verification-slice';
import walletReducer, { walletInitialState } from './slices/wallet-slice';
import { crmApiSlice } from './slices/crm';
import groupFilterListReducer, { groupFilterListInitialState } from './slices/grouping-list-slice';
import { PrizeoutEnv } from './utils/constants/prizeout-website';
import { rewardsApiSlice } from './slices/rewards';
import { onboardingApiSlice } from './slices/onboarding/onboarding-api-slice';
import { earningsApiSlice } from './slices/earnings/earnings-api-slice';
import rewardsCommonReducer, { rewardsCommonInitialState } from './slices/rewards/rewards-common-slice';

export const commonSlices = {
    common: commonReducer,
    sdk: sdkReducer,
    partnerConfig: partnerConfigReducer,
};

export const claimPageSlices = {
    giftCard: giftCardReducer,
};

export const storeFrontSlices = {
    storeFront: storeFrontReducer,
};

export const adminPortalSlices = {};

export const widgetSlices = {
    cashback: cashbackReducer,
    categoryList: categoryListReducer,
    groupFilterList: groupFilterListReducer,
    checkout: checkoutReducer,
    emailCapture: emailCaptureReducer,
    gifting: giftingReducer,
    messages: messagesReducer,
    miniWidget: miniWidgetReducer,
    offers: offerConfigReducer,
    onboarding: onboardingReducer,
    otp: otpSliceReducer,
    preferences: preferencesReducer,
    rewardActions: RewardActionsReducer,
    rewardsCommon: rewardsCommonReducer,
    sidebar: sidebarReducer,
    sweepstakes: SweepstakesReducer,
    user: userReducer,
    verification: verificationReducer,
    wallet: walletReducer,
};

const adminPortalReducer = combineReducers({
    ...commonSlices,
    ...adminPortalSlices,
});

const claimReducer = combineReducers({
    ...commonSlices,
    ...claimPageSlices,
});

const SFrontReducer = combineReducers({
    ...commonSlices,
    ...storeFrontSlices,
});

const rootReducer = combineReducers({
    ...commonSlices,
    ...widgetSlices,
});

export const widgetAPIQuerySlices = {
    [cashbackApiSlice.reducerPath]: cashbackApiSlice.reducer,
    [crmApiSlice.reducerPath]: crmApiSlice.reducer,
    [rewardsApiSlice.reducerPath]: rewardsApiSlice.reducer,
    [onboardingApiSlice.reducerPath]: onboardingApiSlice.reducer,
    [earningsApiSlice.reducerPath]: earningsApiSlice.reducer,
};

const widgetReducer = combineReducers({
    ...commonSlices,
    ...widgetSlices,
    ...widgetAPIQuerySlices,
});

export const store = configureStore({
    reducer: widgetReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware()
            .concat(cashbackApiSlice.middleware)
            .concat(crmApiSlice.middleware)
            .concat(rewardsApiSlice.middleware)
            .concat(onboardingApiSlice.middleware)
            .concat(earningsApiSlice.middleware),
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export const adminPortalStore = configureStore({
    reducer: adminPortalReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export const cpStore = configureStore({
    reducer: claimReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

export const sfStore = configureStore({
    reducer: SFrontReducer,
    devTools: process.env.ENV !== PrizeoutEnv.PRODUCTION,
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type CPRootState = ReturnType<typeof claimReducer>;
export type SFRootState = ReturnType<typeof SFrontReducer>;
export type AdminPortalRootState = ReturnType<typeof adminPortalReducer>;

export type AppDispatch = typeof store.dispatch &
    ThunkDispatch<RootState | CPRootState | AdminPortalRootState, null, AnyAction>;

const commonInitState = {
    common: commonInitialState,
    sdk: sdkInitialState,
    partnerConfig: partnerInitialState,
};

export const CPInitialState: CPRootState = {
    ...commonInitState,
    giftCard: giftCardInitialState,
};

export const SFInitialState: SFRootState = {
    ...commonInitState,
    storeFront: storeFrontInitialState,
};

export const RootInitialState: RootState = {
    ...commonInitState,
    cashback: cashbackInitialState,
    categoryList: categoryListInitialState,
    groupFilterList: groupFilterListInitialState,
    checkout: checkoutInitialState,
    emailCapture: emailCaptureInitialState,
    gifting: giftingInitialState,
    messages: messagesInitialState,
    miniWidget: miniWidgetInitialState,
    offers: offersInitialState,
    onboarding: onboardingInitialState,
    otp: otpInitialState,
    preferences: preferencesInitialState,
    rewardActions: rewardActionsInitialState,
    rewardsCommon: rewardsCommonInitialState,
    sidebar: sidebarInitialState,
    sweepstakes: sweepstakesInitialState,
    user: userInitialState,
    verification: verificationInitialState,
    wallet: walletInitialState,
};

export const AdminPortalInitialState: AdminPortalRootState = {
    ...commonInitState,
};

export default store;
