import { CashoutHistory } from '../../slices/checkout-slice';
import { routes } from '../helpers';

export interface ClosePayload {
    page_url: string;
    cashout_history: CashoutHistory;
}

export default class SDKMessenger {
    private SDKMessageTypes = {
        CASH_OUT_SUCCEESS: 'cashoutSuccess',
        CASH_OUT_FAIL: 'cashoutFail',
        CLOSE: 'close',
        CLOSE_MESSAGE_ONLY: 'closeMessageOnly',
        EXTERNAL_URL: 'externalUrl',
        INIT: 'hello',
        SEE_MORE: 'widgetSeeMore',
        TOGGLE_OPEN_MY_GIFT_CARDS: 'toggleOpenMyGiftCards',
        OPEN_TO_ROUTE: 'openToRoute',
        CLOSE_MY_GIFT_CARDS: 'closeMyGiftCards',
        SET_DEFAULT_SELECTED_OFFER: 'setDefaultSelectedOffer',
    };

    private static sdkMessengerInstance: SDKMessenger = null;

    public static getSDKMessengerService() {
        if (!SDKMessenger.sdkMessengerInstance) {
            SDKMessenger.sdkMessengerInstance = new SDKMessenger();
        }

        return SDKMessenger.sdkMessengerInstance;
    }

    private buildSDKMessage({ payload, type }: { payload?: JSON | ClosePayload | routes; type: string }): string {
        const message = {
            payload,
            type,
        };

        return JSON.stringify(message);
    }

    private sendSDKMessage(message: string): void {
        window.parent.postMessage(message, '*');
    }

    public closePrizeout(payload?: ClosePayload): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.CLOSE,
        });
        this.sendSDKMessage(message);
    }

    public cashoutSuccess(payload: JSON): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.CASH_OUT_SUCCEESS,
        });
        this.sendSDKMessage(message);
    }

    public cashoutFail(payload: JSON): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.CASH_OUT_FAIL,
        });
        this.sendSDKMessage(message);
    }

    public closeMessageOnly(payload: ClosePayload): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.CLOSE_MESSAGE_ONLY,
        });
        this.sendSDKMessage(message);
    }

    public hello(): void {
        const message = this.buildSDKMessage({
            type: this.SDKMessageTypes.INIT,
        });
        this.sendSDKMessage(message);
    }

    public seeMore(payload: any): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.SEE_MORE,
        });
        this.sendSDKMessage(message);
    }

    public toggleOpenMyGiftCards(): void {
        const message = this.buildSDKMessage({
            type: this.SDKMessageTypes.TOGGLE_OPEN_MY_GIFT_CARDS,
        });
        this.sendSDKMessage(message);
    }

    public openToRoute(payload: routes): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.OPEN_TO_ROUTE,
        });
        this.sendSDKMessage(message);
    }

    public closeMyGiftCards(): void {
        const message = this.buildSDKMessage({
            type: this.SDKMessageTypes.CLOSE_MY_GIFT_CARDS,
        });
        this.sendSDKMessage(message);
    }

    public setDefaultSelectedOffer(payload: any): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.SET_DEFAULT_SELECTED_OFFER,
        });
        this.sendSDKMessage(message);
    }

    public dispatchExternalURL(payload: any): void {
        const message = this.buildSDKMessage({
            payload,
            type: this.SDKMessageTypes.EXTERNAL_URL,
        });
        this.sendSDKMessage(message);
    }
}
