import React, { useState } from 'react';
import { GenericModal } from '../';
import { useAppSelector, useNumberSequence, useOfferSort } from '../../../../hooks';
import { OfferSortType } from '../../../../slices/offers-slice';
import { RadioButtonListLayout, RadioButtonListItem } from '../../radio-button';
import { TrackingService } from '../../../../utils/services/tracking';
import { selectActiveCategoryListById } from '../../../../slices/category-list-slice';
import { selectActiveFilter } from '../../../../slices/common-slice';

interface OfferSortSelectorModalProps {
    isOpen: boolean;
    onClose: () => void;
}

export const OfferSortSelectorModal: React.FC<OfferSortSelectorModalProps> = ({
    isOpen,
    onClose,
}): React.ReactElement => {
    const sortKeys = useNumberSequence();
    const { activeSortType, setActiveSortType, sortOptions } = useOfferSort();
    const [internalActiveSortType, setInternalActiveSortType] = useState(activeSortType);
    const trackingService = TrackingService.getTrackingService();
    const categoryList = useAppSelector(selectActiveCategoryListById);
    const activeFilter = useAppSelector(selectActiveFilter);

    const renderRadioContent = (label: string) => {
        return (
            <div className="offer-sort-selector-modal__radio-wrapper">
                <p className="offer-sort-selector-modal__label">{label}</p>
            </div>
        );
    };

    const onApplySortFilter = () => {
        setActiveSortType(internalActiveSortType);
        onClose();
        trackingService.sortListClick(
            categoryList?.categoryId,
            categoryList?.categoryName,
            internalActiveSortType,
            activeFilter,
        );
    };

    return (
        <>
            <GenericModal
                ariaLabelledById="offer-sort-selector-headline"
                ariaLabel="Sort by"
                customClassName="offer-sort-selector-modal"
                iconClasses="fa-kit fa-prizeout-filter"
                hasExitButton={true}
                hasIcon
                headline="Sort by"
                isOpen={isOpen}
                onClose={onClose}
                primaryButtonText="Apply"
                primaryOnClick={onApplySortFilter}
                primaryTestId="offer-sort-selector-apply"
                testId="offer-sort-selector-modal"
            >
                <div className="offer-sort-selector-modal__content">
                    <RadioButtonListLayout>
                        {sortOptions?.map(({ label, type }) => (
                            <li key={sortKeys()}>
                                <RadioButtonListItem
                                    isChecked={internalActiveSortType === type}
                                    onChange={(event) =>
                                        setInternalActiveSortType(event.target.value as unknown as OfferSortType)
                                    }
                                    name="offer-sort-selector"
                                    value={type}
                                    ichildren={renderRadioContent(label)}
                                    title={label}
                                    id={`testid-${type}`}
                                />
                            </li>
                        ))}
                    </RadioButtonListLayout>
                </div>
            </GenericModal>
        </>
    );
};
